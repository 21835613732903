import React, { useEffect, useState } from "react";
import { Button, Grid, Icon, Header, List, Loader, Dimmer, Confirm, Image } from "semantic-ui-react";
import { addItinerary, saveItinerary, uploadBookItinFiles, deleteBookItinFile, removeBookingItinImage, getGroupList, getItinFiles, getItinFilesOld } from "../leadActions";
import Dropzone from "react-dropzone";
import { Formik } from "formik";
import MyDateInput from "../../../app/common/form/MyDateInput";
import MyTextInput from "../../../app/common/form/MyTextInput";
import MyTinyMCE from "../../../app/common/form/MyTinyMCE";
import addSeconds from "date-fns/addSeconds";
import { format } from "date-fns";
import MySelectInput from "../../../app/common/form/MySelectInput";
import { closeModal } from "../../../app/common/modals/modalReducer";
import { useDispatch } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import firebase from "../../../app/config/firebase";
const firestore = firebase.firestore();

export default function LeadBookingItinForm(props) {
    const dispatch = useDispatch();
    let client_booking = props.client_booking;
    let itin = props.itin;
    let type = props.type;
    let bookingType = props.bookingType;
    let leadId = props.leadId;
    const [lead_group_users, setLead_group_users] = useState([]);
    const [lead_itin_files, setLead_itin_files] = useState([]);
    const [lead_itin_files_old, setLead_itin_files_old] = useState([]);
    const [open, setOpen] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [file, setFile] = useState([]);
    const [groupUser, setGroupUser] = useState("");
    const [dte, setDte] = useState("");
    let select = "";

    if (props.itin !== undefined && dte === "" && props.itin.date !== undefined && props.itin.date !== "") {
        select = addSeconds(new Date(0), props.itin.date.seconds);
    } else {
        select = "";
    }

    function openThis(fl) {
        setOpen(true);
        setFile(fl);
    }
    function closeThis() {
        setOpen(false);
    }

    useEffect(() => {
        if (props.itin !== undefined && dte === "" && props.itin.date !== undefined && props.itin.date !== "") {
            setDte(addSeconds(new Date(0), props.itin.date.seconds));
        }
        const unsubscribe2 = firestore
            .collection("bookings")
            .doc(client_booking.id)
            .collection("users")
            .onSnapshot((snapshot) => {
                let list = [];
                snapshot.docs.forEach((result) => {
                    if (result.exists) {
                        let obj = { ...result.data(), id: result.id };
                        list.push(obj);
                    }
                });
                setLead_group_users(list);
            });
        // setDte(select)
        if (itin !== undefined) {
            firestore
                .collection("bookings")
                .doc(client_booking.id)
                .collection("appFiles")
                .where("rowId", "==", itin.id)
                .where("fileType", "==", "itinerary")
                .onSnapshot((snapshot) => {
                    let list = [];
                    snapshot.docs.forEach((result) => {
                        if (result.exists) {
                            let obj = { ...result.data(), id: result.id };
                            list.push(obj);
                        }
                    });
                    setLead_itin_files(list);
                });
            firestore
                .collection("bookings")
                .doc(client_booking.id)
                .collection("itinerary")
                .doc(itin.id)
                .collection("files")
                .onSnapshot((snapshot) => {
                    let list = [];
                    snapshot.docs.forEach((result) => {
                        if (result.exists) {
                            let obj = { ...result.data(), id: result.id };
                            list.push(obj);
                        }
                    });
                    setLead_itin_files_old(list);
                });
        }

        return () => {
            unsubscribe2();
        };
    }, []);

    async function deleteFile(file) {
        setOpen(false);
        setUploading(true);

        await deleteBookItinFile(file, itin, client_booking, bookingType);

        setUploading(false);
    }

    const selectGroupUsers = [
        {
            key: "",
            text: "All Users",
            value: "",
        },
    ];

    if (lead_group_users.length > 0) {
        Object.keys(lead_group_users).forEach(function (key) {
            selectGroupUsers.push({
                key: lead_group_users[key].id,
                text: lead_group_users[key].client_name,
                value: lead_group_users[key].id + "|" + lead_group_users[key].client_name,
            });
        });
    }

    async function onDrop(files, groupUser) {
        setUploading(true);

        Object.keys(files).forEach(async function (key) {
            await uploadBookItinFiles(files[key], itin, client_booking, bookingType, groupUser);
        });

        setUploading(false);
    }

    function dateChange(value) {
        console.log(value);
        select = value;
        setDte(value);
    }

    return (
        <>
            <Formik
                initialValues={itin !== undefined ? itin : { date: "", item: "", booking_ref: "", description: "", lead_group_users: "" }}
                validate={(values) => {
                    const error = {};
                    if (!values.booking_ref) {
                        error.booking_ref = "Booking Reference is required";
                    }
                    return error;
                }}
                onSubmit={async (values, { setSubmitting }) => {
                    if (type === "Add") {
                        await addItinerary(values, client_booking, leadId, bookingType);
                    } else {
                        await saveItinerary(values, bookingType);
                    }

                    setSubmitting(false);
                    dispatch(closeModal());
                }}
            >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                    <form onSubmit={handleSubmit} className="ui form">
                        <Grid style={{ padding: "20px 0" }}>
                            <Grid.Row columns={3} verticalAlign="middle">
                                <Grid.Column>
                                    <label>
                                        <strong>Date / Time</strong>
                                    </label>
                                    <br />
                                    <DatePicker
                                        name="date"
                                        selected={dte}
                                        dateFormat="yyyy-MM-dd HH:mm"
                                        showYearDropdown={true}
                                        showMonthDropdown={true}
                                        showTimeSelect={true}
                                        dropdownMode="select"
                                        onChange={(value) => {
                                            dateChange(value);
                                            setFieldValue("date", value);
                                        }}
                                        autcomplete="false"
                                    />
                                </Grid.Column>

                                <Grid.Column>
                                    <MyTextInput labelTop="Item" name="item" type="text" />
                                </Grid.Column>

                                <Grid.Column textAlign="right">
                                    <Button disabled={isSubmitting} loading={isSubmitting} size="large" color="orange" content={type} />
                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Row>
                                <Grid.Column>
                                    <MyTextInput labelTop="Booking Reference" name="booking_ref" type="text" />
                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Row>
                                <Grid.Column>
                                    <MyTinyMCE label="Detailed Description" name="description" type="text" onEditorChange={(value) => setFieldValue("description", value)} />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>

                        {type === "Save" && bookingType === "itinerary" && (
                            <Grid.Row columns={2}>
                                <Grid.Column width={6}>
                                    <MySelectInput label="Group User" name="lead_group_users" options={selectGroupUsers} placeholder="Select your Group User to link to" />

                                    <Dropzone onDrop={(acceptedFiles) => onDrop(acceptedFiles, values.lead_group_users)}>
                                        {({ getRootProps, getInputProps }) => (
                                            <div style={{ paddingTop: "30px", textAlign: "center" }} {...getRootProps()}>
                                                <Icon name="upload" size="big" />
                                                <Header as="h4" content="Drop file here or click to add" />
                                                <input {...getInputProps()} />

                                                <Dimmer active={uploading}>
                                                    <Loader>Please wait...</Loader>
                                                </Dimmer>
                                            </div>
                                        )}
                                    </Dropzone>
                                </Grid.Column>

                                <Grid.Column width={5}>
                                    <h2>Files</h2>

                                    {lead_itin_files && lead_itin_files.length > 0 ? (
                                        <List>
                                            {lead_itin_files.map((fl) => (
                                                <List.Item key={fl.id}>
                                                    <List.Content floated="right">
                                                        <Icon onClick={() => openThis(fl)} name="delete" />
                                                    </List.Content>

                                                    <List.Content>
                                                        <a href={fl.downloadURL} target="_blank">
                                                            {fl.groupUserName && fl.groupUserName.length > 0 ? fl.fileName + " - " + fl.groupUserName : fl.fileName + " - All"}
                                                        </a>
                                                    </List.Content>
                                                </List.Item>
                                            ))}
                                        </List>
                                    ) : (
                                        <p>No files have been uploaded.</p>
                                    )}

                                    {lead_itin_files_old && lead_itin_files_old.length > 0 && (
                                        <List>
                                            {lead_itin_files_old.map((fl) => (
                                                <List.Item key={fl.id}>
                                                    <List.Content floated="right">
                                                        <Icon onClick={() => openThis(fl)} name="delete" />
                                                    </List.Content>

                                                    <List.Content>
                                                        <a href={fl.downloadURL} target="_blank">
                                                            {fl.groupUserName && fl.groupUserName.length > 0 ? fl.fileName + " - " + fl.groupUserName + " - OLD" : fl.fileName + " - All - OLD"}
                                                        </a>
                                                    </List.Content>
                                                </List.Item>
                                            ))}
                                        </List>
                                    )}
                                </Grid.Column>

                                <Grid.Column width={5}>Vouchers: feature coming</Grid.Column>
                            </Grid.Row>
                        )}
                    </form>
                )}
            </Formik>
            <Confirm
                header="You are about to delete this file"
                content="This cannot be undone, are you sure?"
                size="tiny"
                open={open}
                onCancel={closeThis}
                onConfirm={() => deleteFile(file)}
                confirmButton="Yes"
            />
        </>
    );
}
