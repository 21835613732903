import React, { useState, useCallback } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import MyTextInput from "../../app/common/form/MyTextInput";
import { Button, Label, Grid, Segment } from "semantic-ui-react";
import { signInWithEmail } from "../../app/firestore/firebaseService";
import { resetPassword } from "./authActions"
import { Link } from "react-router-dom";
import firebase from "../../app/config/firebase";
import { parse, format, addDays, subDays } from "date-fns";
const firestore = firebase.firestore();

export default function LoginForm({ history }) {

    const [forgot, setForgot] = useState(false)

    function changeType(){
        setForgot(true)
    }
    function changeTypeFalse(){
        setForgot(false)
    }

    return (
        <div className="login_bg">
            <Grid textAlign="center">
                <Grid.Row>
                    <Grid.Column style={{ maxWidth: 450 }}>
                        <Formik
                            hidden={forgot === true ? true : false}
                            initialValues={{ email: "", password: "" }}
                            validate={values => {
                                const errors = {};
                                if (!values.email) {
                                  errors.email = 'Required';
                                }
                                if(forgot == false){

                                    if (!values.password) {
                                        errors.password = 'Required';
                                      }
                                }
                                return errors;
                              }}
                            onSubmit={async (values, { setSubmitting, setErrors }) => {
                                try {

                                    if(forgot === true){
                                        await resetPassword(values.email);
                                        changeTypeFalse();
                                        setSubmitting(false);

                                    }else{
                                        await signInWithEmail(values);

                                        let userInfo = await firestore.collection('users').where('email', '==', values.email).get();
                                        let resetDate = userInfo.docs[0].data().passwordResetDate ? userInfo.docs[0].data().passwordResetDate.toDate() : "";

                                        if(!resetDate || resetDate && new Date() > new Date(format(addDays(resetDate, 90), "yyyy-MM-dd"))){
                                            history.push("/password/reset");
                                        }else if (userInfo.docs[0].data().isExternal){
                                            history.push("/leads/landing")
                                        } else {
                                            history.push("/dashboard/landing");
                                        }
                                        setSubmitting(false);
                                    }
                                } catch (error) {
                                    console.log(error);
                                    setErrors({ auth: "Problem with username or password" });
                                    setSubmitting(false);
                                }
                            }}
                        >
                            {({ isSubmitting, isValid, dirty, errors, values }) => (
                                <Form className="ui form">
                                    <Segment>
                                        <img src="/assets/logo-login2.png" alt="IGO Travel" style={{ marginTop: -182, marginBottom: 15 }} />
                                        <MyTextInput name="email" placeholder="Email Address" type="text"/>
                                        <MyTextInput name="password" placeholder="Password" type="password" hidden={forgot === true ? true : false}/>

                                        <Button
                                            loading={isSubmitting}
                                            disabled={!isValid || !dirty || isSubmitting}
                                            type="submit"
                                            fluid
                                            size="large"
                                            color="orange"
                                            content={forgot === true ? "Send Reset Link" : "Login"}
                                            style={{ marginTop: "20px", marginBottom: "10px" }}
                                        />

                                        {errors.auth && <Label basic color="red" style={{ marginBottom: 10 }} content={errors.auth} />}

                                    </Segment>
                                </Form>
                            )}
                        </Formik>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    <Grid.Column>
                    {forgot === false && 
                        <Button style={{ marginTop: 20 }} onClick={changeType}>
                            Forgot password?
                        </Button>
                    }
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    );
}
