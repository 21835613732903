import React, { useState, useCallback, useEffect } from "react";
import { Container, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import SmartDataTable from "react-smart-data-table";
import { useSelector } from "react-redux";

import firebase from "../../../app/config/firebase";
const firestore = firebase.firestore();

export default function UsersPage(props) {
    const [filterValue, setFilterValue] = useState("");
    const [userlist, setUserlist] = useState([]);

    const { currentUserProfile } = useSelector((state) => state.profile);

    useEffect(() => {
        const unsubscribe = firestore
            .collection("users")
            .where("isConsultant", "==", true)
            .onSnapshot((snapshot) => {
                let list = [];

                snapshot.docs.forEach((doc) => {
                    if (doc.exists) {
                        let appObj = { ...doc.data(), id: doc.id };
                        list.push(appObj);
                    }
                });
                const externalConsultants = firestore
                    .collection("users")
                    .where("isExternal", "==", true)
                    .onSnapshot((snapshot) => {
                        snapshot.docs.forEach((doc) => {
                            if (doc.exists) {
                                let appObj = { ...doc.data(), id: doc.id };
                                list.push(appObj);
                            }
                        });

                        setUserlist(list);
                    });
            });

        return () => unsubscribe();
    }, []);

    if (currentUserProfile.isLoaded === true && currentUserProfile.isEmpty === true) {
        props.history.push("/");
    }

    if (currentUserProfile.isSuper === false) {
        props.history.push("/");
    }

    const updateFilter = useCallback((value) => {
        setFilterValue(value);
    }, []);

    function handleOnChange({ target: { name, value } }) {
        updateFilter(value);
    }

    function onRowClick(event, { rowData, rowIndex, tableData }) {
        const { fullName, name, id } = rowData;
        let value = fullName || name || id;

        if (!value) {
            const [key] = Object.keys(rowData);
            value = `${key}: ${rowData[key]}`;
        }

        props.history.push("/admin/user/" + value);
    }

    const headers = {
        userUid: {
            invisible: true,
        },
    };

    const orderedHeaders = ["displayName", "email", "userType", "isConsultant", "isSuper", "isExternal", "division"];

    return (
        <Container>
            <div style={{ marginBottom: 20 }}>
                <Button as={Link} to={`/admin/user/0`}>
                    Add User
                </Button>
                <Button as={Link} to={`/admin/landing`}>
                    Back to Admin
                </Button>

                <div className="ui icon input">
                    <input type="text" name="filterValue" value={filterValue} placeholder="Filter results..." onChange={handleOnChange} />
                    <i className="search icon" />
                </div>
            </div>

            <SmartDataTable
                data={userlist}
                name="users-table"
                className="ui compact selectable table celled"
                sortable
                onRowClick={onRowClick}
                headers={headers}
                orderedHeaders={orderedHeaders}
                hideUnordered={true}
                filterValue={filterValue}
            />
        </Container>
    );
}
