import firebase from "firebase";
import "firebase/firestore";

const hostname = window && window.location && window.location.hostname;
const useEmulator = false;

let firebaseConfig = {
    apiKey: "AIzaSyClU8nmq7vaVG8PKhzW-zuTbquvQc7ns9U",
    authDomain: "igo-travel-leads-f4ef5.firebaseapp.com",
    databaseURL: "https://igo-travel-leads-f4ef5.firebaseio.com",
    projectId: "igo-travel-leads-f4ef5",
    storageBucket: "igo-travel-leads-f4ef5.appspot.com",
    messagingSenderId: "1057279168557",
    appId: "1:1057279168557:web:1ed169e1dd0d07e2",
};

// if (hostname === "localhost") {
//     firebaseConfig["apiKey"] = "AIzaSyDjM24Pf9wSADq5ZOJl142nZZ6uUrwJCyU";
//     firebaseConfig["authDomain"] = "igo-travel-leads-test.firebaseapp.com";
//     firebaseConfig["databaseURL"] = "https://igo-travel-leads-test.firebaseio.com";
//     firebaseConfig["projectId"] = "igo-travel-leads-test";
//     firebaseConfig["storageBucket"] = "igo-travel-leads-test.appspot.com";
//     firebaseConfig["messagingSenderId"] = "511429550861";
//     firebaseConfig["appId"] = "1:511429550861:web:23763e67b09819eebf0644";
// }

// if (hostname == "localhost" && useEmulator === true) {
//     firebaseConfig[] = {
//         databaseURL: "localhost:8080",
//     };
// }

firebase.initializeApp(firebaseConfig);

if (hostname === "localhost" && useEmulator === true) {
    let db = firebase.firestore();

    db.settings({
        host: "localhost:8080",
        ssl: false,
    });
} else {
    firebase.firestore();
}

export default firebase;
