import React, { useState, useEffect } from "react";
import { Button, Header, Segment, Table, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { createBookingNew } from "../leadActions";
import format from "date-fns/format";

import firebase from "../../../app/config/firebase";
const firestore = firebase.firestore();

export default function LeadDetailedBookings(props) {
    const [bookings, setBookings] = useState([]);
    const [booking, setBooking] = useState(false);

    useEffect(() => {
        const unsubscribe = firestore
            .collection("bookings")
            .where("leadId", "==", props.lead.id)
            .onSnapshot((snapshot) => {
                let list = [];

                snapshot.docs.forEach((doc) => {
                    if (doc.exists) {
                        let appObj = { ...doc.data(), ["id"]: `${doc.id}` };
                        list.push(appObj);
                    }
                });

                list.sort((a, b) => (a.createDate < b.createDate ? 1 : -1));
                setBookings(list);
            });

        return () => unsubscribe();
    }, []);

    async function createNewBooking(lead) {
        setBooking(true);
        await createBookingNew(lead);
        setBooking(false);
    }

    return (
        <div style={{ marginBottom: 20 }}>
            <Segment attached="top">
                <Header as="h3">Bookings</Header>
            </Segment>

            <Segment attached>
                <Table celled compact="very">
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Booking No</Table.HeaderCell>
                            <Table.HeaderCell>Travel Date</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">
                                <Icon name="file alternate" />
                            </Table.HeaderCell>
                            <Table.HeaderCell>Action</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {bookings &&
                            bookings.length > 0 &&
                            bookings.map((book) => (
                                <Table.Row key={book.id}>
                                    <Table.Cell>{book.createDate ? format(book.createDate.toDate(), "yyyyMMdd") + "-" + book.booking_nr + "-" + book.booking_rev : ""}</Table.Cell>
                                    <Table.Cell>{book.travel_date ? format(book.travel_date.toDate(), "d MMM yyyy") : ""}</Table.Cell>
                                    <Table.Cell textAlign="center">{book.hasFiles && book.hasFiles === true && <Icon name="check" />}</Table.Cell>
                                    <Table.Cell>
                                        <Button size="medium" color="orange" content="Edit" as={Link} to={"/booking/confirmation/" + props.lead.clientId + "/" + props.lead.id + "/" + book.id} />
                                        <Button size="medium" color="orange" content="App" as={Link} to={"/booking/app/" + props.lead.clientId + "/" + props.lead.id + "/" + book.id} />
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                    </Table.Body>
                </Table>
            </Segment>

            <Segment attached="bottom" className="buttons_row">
                {props.lead.clientId ? (
                    <Button size="medium" color="orange" loading={booking} content="Create Booking" onClick={() => createNewBooking(props.lead)} />
                ) : (
                    "Please link to a client before creating a booking"
                )}
            </Segment>
        </div>
    );
}
