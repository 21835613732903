import React from "react";
import NumberFormat from "react-number-format";

const orangeBar = {
    background: "linear-gradient(90deg, rgba(246,140,70,1) 0%, rgba(250,166,36,1) 100%)",
    color: "white",
    padding: "5px 40px",
    textTransform: "uppercase",
    fontSize: "16px",
    lineHeight: "16px",
};

const flightTbl = {
    backgroundColor: "#eef0f1",
    padding: "5px 15px",
    marginTop: "10px",
};

const flightTbl2 = {
    display: "flex",
    justifyContent: "space-between",
    padding: "5px 15px",
};
const flightTbl2Inner = {
    width: "33%",
};

const QuotePDFPrice = ({ qtPackage }) => {
    return (
        <div>
            <div style={orangeBar}>PRICE BREAKDOWN</div>
            <div style={{ padding: "0 30px" }}>
                {qtPackage.price_breakdown && qtPackage.price_breakdown.length > 0 && (
                    <div style={flightTbl}>
                        <ul style={{ margin: 0, padding: "0 10px" }}>
                            {qtPackage.price_breakdown.map((price, key) => (
                                <li key={key}>{price.value}</li>
                            ))}
                        </ul>
                    </div>
                )}

                {qtPackage.show_discount && qtPackage.show_discount === true && qtPackage.discount && qtPackage.discount > 0 ? (
                    <div style={flightTbl}>
                        <div style={flightTbl2}>
                            <div style={flightTbl2Inner}>
                                TOTAL
                                <br />
                                <span style={{ fontSize: "30px", color: "#f68b42", lineHeight: "50px" }}>
                                    <strong>
                                        <NumberFormat value={qtPackage.price} displayType={"text"} thousandSeparator={" "} prefix={"R"} />
                                    </strong>
                                </span>
                            </div>
                            <div style={flightTbl2Inner}>
                                DISCOUNT
                                <br />
                                <span style={{ fontSize: "30px", color: "#f68b42", lineHeight: "50px" }}>
                                    <strong>
                                        <NumberFormat value={qtPackage.discount} displayType={"text"} thousandSeparator={" "} prefix={"R"} />
                                    </strong>
                                </span>
                            </div>
                            <div style={flightTbl2Inner}>
                                DUE
                                <br />
                                <span style={{ fontSize: "30px", color: "#f68b42", lineHeight: "50px" }}>
                                    <strong>
                                        <NumberFormat value={qtPackage.priceAfter} displayType={"text"} thousandSeparator={" "} prefix={"R"} />
                                    </strong>
                                </span>
                            </div>
                        </div>
                        Hotels and/or Airlines may change due to availability on the date of receiving payment.
                    </div>
                ) : (
                    <div style={flightTbl}>
                        TOTAL DUE
                        <br />
                        <span style={{ fontSize: "30px", color: "#f68b42", lineHeight: "50px" }}>
                            <strong>
                                <NumberFormat value={qtPackage.priceAfter ? qtPackage.priceAfter : qtPackage.price} displayType={"text"} thousandSeparator={" "} prefix={"R"} />
                            </strong>
                        </span>
                        <br />
                        Hotels and/or Airlines may change due to availability on the date of receiving payment.
                    </div>
                )}

                {qtPackage.recommendation && qtPackage.recommendation.length > 0 && (
                    <div style={flightTbl}>
                        <h5>WHY I RECOMMEND THIS PACKAGE</h5>
                        <div style={{ fontSize: 12, lineHeight: "16px" }} dangerouslySetInnerHTML={{ __html: qtPackage.recommendation }}></div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default QuotePDFPrice;
