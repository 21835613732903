import { toastr } from "react-redux-toastr";
import { toast } from "react-toastify";
import firebase from "../../app/config/firebase";

export async function addPayment(values, lead_booking, leadId, client) {
    const firestore = firebase.firestore();

    try {
        values.leadId = leadId;

        if (lead_booking && lead_booking.id) {
            values.bookingId = lead_booking.id;
        }

        values.client_email = client.client_email;
        values.client_name = client.client_name;
        values.client_phone = client.client_phone;
        values.clientId = client.id;
        let valuesObj = Object.assign({}, values);

        // console.log(valuesObj)

        //await firestore.add(`bookings/${lead_booking.id}/payments`, values);
        let payment = await firestore.collection("payments").add(valuesObj);

        let message = `
            <p>IGO Travel has requested a payment from you.</p>
            <p><a href="https://leads.igotravel.co.za/public/payfast/pay/${payment.id}">Click here to make payment</a></p>
        `;

        //Now check if we need to send an email
        if (values.status === "Email Request") {
            let email = {
                status: "Pending",
                addedDt: new Date(Date.now()),
                leadId: leadId,
                clientId: client.id,
                emailType: "Payment Email",
                message: message,
                subject: "IGO Travel - Payment Request",
                to: client.client_email,
            };

            if (lead_booking && lead_booking.id) {
                email.bookingId = lead_booking.id;
            }

            await firestore.collection("email").add(email);
        }

        toast.success("Payment added");
    } catch (error) {
        console.log(error.message);
        toast.error("Something went wrong");
    }
}

export async function savePayment(values) {
    const firestore = firebase.firestore();

    try {
        //let leadId = values.leadId;
        delete values.leadId;

        //let bookingId = values.bookingId;
        delete values.bookingId;

        let id = values.id;
        delete values.id;

        await firestore.collection("payments").doc(id).update(values);
        toast.success("Payment has been saved");
    } catch (error) {
        console.log(error.message);
        toast.error("Something went wrong");
    }
}

export async function removePayment(rowId) {
    const firestore = firebase.firestore();

    try {
        await firestore.collection("payments").doc(rowId).delete();

        toast.success("Payment row removed");
    } catch (error) {
        console.log(error.message);
        toast.error("Something went wrong");
    }
}

export const addManualFlight = (values, client_booking) => {
    return async (dispatch, getState, { getFirestore }) => {
        const firestore = getFirestore();

        try {
            values.bookingId = client_booking.id;
            values.itineraryType = "Flight";

            //Get the sort_order number to use
            let itinSnap = await firestore.collection("bookings").doc(client_booking.id).collection("itinerary").get();

            let itinCount = itinSnap.docs.length + 1;
            itinCount = parseInt(itinCount, 10);

            values.sort_order = itinCount;

            values.date = values.departDateTime;
            values.item = "From " + values.airportFrom + " To " + values.airportTo;
            values.booking_ref = values.flight_nr;

            await firestore.add(`bookings/${client_booking.id}/itinerary`, values);

            //Check if this booking should show on the App
            let clientRef = firestore.collection("bookings").doc(client_booking.id).collection("itinerary");
            let clientSnap = await clientRef.get();

            let showApp = false;

            if (clientSnap.docs.length > 0) {
                showApp = true;
            }

            await firestore.update(
                {
                    collection: "bookings",
                    doc: client_booking.id,
                },
                {
                    showOnApp: showApp,
                }
            );

            toastr.success("Success!", "Itinerary added");
        } catch (error) {
            console.log(error.message);
            toastr.error("Oops", "Something went wrong");
        }
    };
};

export async function addTraveller(values, lead_booking) {
    const firestore = firebase.firestore();

    // console.log(values);
    // console.log(lead_booking);

    try {
        values.createDate = new Date(Date.now());
        let traveller = await firestore.collection("bookings").doc(lead_booking.id).collection("travellers").get();
        values.sort_order = traveller.docs.length + 1;

        await firestore.collection("bookings").doc(lead_booking.id).collection("travellers").add(values);

        toast.success("Traveller added");
    } catch (error) {
        console.log(error.message);
        toast.error("Something went wrong");
    }
}

export async function saveTraveller(values, lead_booking) {
    const firestore = firebase.firestore();

    try {
        let id = values.id;
        delete values.id;

        if (!values.createDate) {
            values.createDate = new Date(Date.now());
        }

        await firestore.collection("bookings").doc(lead_booking.id).collection("travellers").doc(id).update(values);
        toast.success("Traveller has been saved");
    } catch (error) {
        console.log(error.message);
        toast.error("Something went wrong");
    }
}
