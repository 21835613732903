import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid } from "semantic-ui-react";
import format from "date-fns/format";
import addSeconds from "date-fns/addSeconds";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { openModal } from "../../../app/common/modals/modalReducer";
// import SmartDataTable from "react-smart-data-table";
// import { getAssignedLeads /*getConsultantUser, getcurrentUserProfileUser*/ } from "../leadActions";
import { getAssignedLeads, setFilterStatus /*getConsultantUser, getcurrentUserProfileUser*/ } from "../leadActions";
import { Formik } from "formik";
import MySelectInput from "../../../app/common/form/MySelectInput";
import MyTextInput from "../../../app/common/form/MyTextInput";
import { useTable, useGlobalFilter, useAsyncDebounce } from "react-table";

import firebase from "../../../app/config/firebase";
import { subMonths } from "date-fns";
const firestore = firebase.firestore();

// Define a default UI for filtering
function GlobalFilter({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) {
    const count = preGlobalFilteredRows.length;
    const [value, setValue] = useState(globalFilter);
    const onChange = useAsyncDebounce((value) => {
        setGlobalFilter(value || undefined);
    }, 1000);

    return (
        <div className="ui icon input" style={{ marginTop: -10 }}>
            <input
                type="text"
                name="filterValue"
                id="filterValue"
                value={value || ""}
                placeholder={`Filter ${count} results`}
                onChange={(e) => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
            />
            <i className="search icon" />
        </div>
    );
}

export default function LeadsDashboardPage(props) {
    const dispatch = useDispatch();
    const [usersFilterList, setUsersFilterList] = useState([]);
    const [filterValue, setFilterValue] = useState("");
    const [leads, setLeads] = useState([]);
    const [loadingLeads, setLoadingLeads] = useState([]);
    const [filterUser, setFilterUser] = useState("");
    // const [filterStatus, setFilterStatus] = useState("");
    const filterStatus = useSelector((state) => state.lead.filterStatus);
    const [filterEmail, setFilterEmail] = useState("");
    const [showSearchError, setShowSearchError] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);
    const [moreDisabled, setMoreDisabled] = useState(false);

    const { currentUserProfile } = useSelector((state) => state.profile);

    useEffect(() => {
        //Set default user as page loads for filter
        updateUser(currentUserProfile.id);

        //Set default status to Assigned
        // updateStatus("Assigned");
        let order = "";
        // if (filterStatus === "") {
        //     setFilterStatus(currentUserProfile.leadStatusOrder)
        // }
        if (currentUserProfile.leadStatusOrder && currentUserProfile.leadStatusOrder !== "") {
            // if (currentUserProfile.leadStatusOrder && currentUserProfile.leadStatusOrder !== "") {
            order = filterStatus || currentUserProfile.leadStatusOrder;
            // } else if (filterStatus !== "") {
        } else {
            if (filterStatus && filterStatus !== "") {
                order = filterStatus;
            } else {
                order = "Assigned";
            }
        }
        updateStatus(order);

        // if(currentUserProfile && currentUserProfile.noLeadsLoad && currentUserProfile.noLeadsLoad !== "0"){
        //     var last_date = subMonths(new Date(), currentUserProfile.noLeadsLoad);
        // }else{
        //     var last_date = new Date("2000-01-01");
        // }

        if (order !== "All") {
            firestore
                .collection("leads")
                .where("userUid", "==", currentUserProfile.id)
                .where("status", "==", filterStatus || order)
                // .where("status", "==", order)
                .onSnapshot((snapshot) => {
                    let list = [];

                    snapshot.docs.forEach((doc) => {
                        if (doc.exists) {
                            if (doc.data().status !== "Archived") {
                                let appObj = { ...doc.data(), id: doc.id };
                                list.push(appObj);
                            }
                        }
                    });
                    list.sort((a, b) => (a.assignDate < b.assignDate ? 1 : -1));
                    setLeads(list);
                });
        } else {
            firestore
                .collection("leads")
                .where("userUid", "==", currentUserProfile.id)
                .onSnapshot((snapshot) => {
                    let list = [];

                    snapshot.docs.forEach((doc) => {
                        if (doc.exists) {
                            if (doc.data().status !== "Archived") {
                                let appObj = { ...doc.data(), ["id"]: `${doc.id}` };
                                list.push(appObj);
                            }
                        }
                    });
                    list.sort((a, b) => (a.assignDate < b.assignDate ? 1 : -1));
                    setLeads(list);
                });
        }

        const unsubscribe2 = firestore
            .collection("users")
            .where("isConsultant", "==", true)
            .onSnapshot((snapshot) => {
                let list = [];

                snapshot.docs.forEach((doc) => {
                    if (doc.exists) {
                        let appObj = { ...doc.data(), ["id"]: `${doc.id}` };
                        list.push(appObj);
                    }
                });
                const externalConsultants = firestore
                    .collection("users")
                    .where("isExternal", "==", true)
                    .onSnapshot((snapshot) => {
                        snapshot.docs.forEach((doc) => {
                            if (doc.exists) {
                                let appObj = { ...doc.data(), id: doc.id };
                                list.push(appObj);
                            }
                        });
                        list.sort((a, b) => (a.displayName > b.displayName ? 1 : -1));
                        setUsersFilterList(list);
                    });
            });

        // const leadsTest = firestore.collection("leads").where("form_name", "==", "Contact Page Contact Form").orderBy("createDate").onSnapshot((snapshot) => {
        //     console.log(snapshot);
        // })

        return () => {
            // unsubscribe();
            unsubscribe2();
        };
    }, []);

    let usersList = [
        {
            key: "All",
            text: "All Users",
            value: "All",
        },
    ];

    if (usersFilterList) {
        Object.keys(usersFilterList).forEach(function (key) {
            usersList.push({
                key: usersFilterList[key].id,
                // text: usersFilterList[key].displayName,
                text: usersFilterList[key].isExternal ? `${usersFilterList[key].displayName} (external consultant)` : usersFilterList[key].displayName,
                value: usersFilterList[key].id,
            });
        });
    }

    const leadStatus = [
        { key: "All", text: "All", value: "All" },
        { key: "Assigned", text: "Assigned", value: "Assigned" },
        { key: "Booked", text: "Booked", value: "Booked" },
        { key: "Lost Lead", text: "Lost Lead", value: "Lost Lead" },
        { key: "Quoted", text: "Quoted", value: "Quoted" },
        { key: "Pending", text: "Pending", value: "Pending" },
        { key: "Follow Up", text: "Follow Up", value: "Follow Up" },
        { key: "Duplicate", text: "Duplicate", value: "Duplicate" },
        { key: "Awaiting Rates", text: "Awaiting Rates", value: "Awaiting Rates" },
        { key: "Travel out of date range", text: "Travel out of date range", value: "Travel out of date range" },
        { key: "Prospective", text: "Prospective", value: "Prospective" },
        { key: "Cancelled BKG", text: "Cancelled BKG", value: "Cancelled BKG" },
        { key: "Awaiting client feedback", text: "Awaiting client feedback", value: "Awaiting client feedback" },
        { key: "Archived", text: "Archived", value: "Archived" },
    ];

    const updateFilter = useCallback((value) => {
        setFilterValue(value);
    }, []);

    const updateUser = useCallback((value) => {
        setFilterUser(value);
    }, []);
    const updateStatus = useCallback((value) => {
        // setFilterStatus(value);
        dispatch(setFilterStatus(value));
    }, []);

    async function changeConsultant(userUid, status, email) {
        let fltUser = "";
        let fltStatus = "";
        let fltEmail = "";

        if (userUid !== "" && userUid !== undefined) {
            updateUser(userUid.value);
        }
        if (status !== "" && status !== undefined && filterStatus !== "" && filterStatus !== undefined) {
            // if (status !== "" && status !== undefined) {
            updateStatus(status.value);
        }

        if (email !== "" && email !== undefined) {
            setFilterEmail(email);
        }

        if (userUid !== "") {
            fltUser = userUid.value;
            fltStatus = filterStatus;
            fltEmail = filterEmail;
        } else if (status !== "") {
            fltUser = filterUser;
            fltStatus = status.value;
            fltEmail = filterEmail;
        } else if (email !== "") {
            fltStatus = filterStatus;
            fltUser = filterUser;
            fltEmail = email;
        }

        setLeads([]);
        setLoadingLeads(true);
        setGlobalFilter("");

        let filterValue = document.getElementById("filterValue").value;

        if (fltUser !== "All" || (fltUser === "All" && fltEmail !== "")) {
            // if(currentUserProfile && currentUserProfile.noLeadsLoad && currentUserProfile.noLeadsLoad !== "0"){
            //     var last_date = subMonths(new Date(), currentUserProfile.noLeadsLoad);
            // }else{
            //     var last_date = new Date("2000-01-01");
            // }

            let leadsQuery = await getAssignedLeads(fltUser, fltStatus, fltEmail);
            leadsQuery.sort((a, b) => (a.assignDate < b.assignDate ? 1 : -1));
            setLeads(leadsQuery);
            setShowSearchError(false);
        } else {
            setShowSearchError(true);
        }

        setGlobalFilter(filterValue);

        setLoadingLeads(false);
    }

    const data = useMemo(() => (leads ? leads : []), [leads]);

    const columns = useMemo(
        () => [
            {
                Header: "ID",
                accessor: "id",
                show: false,
            },
            {
                Header: "Assign Date",
                accessor: "assignDate.seconds",
                Cell: (props) => {
                    let helperDate = addSeconds(new Date(0), props.value);
                    let newDate = format(helperDate, "dd MMM yyyy HH:mm a");
                    return newDate;
                },
            },
            {
                Header: "Pickup Delay",
                accessor: "pickupDelay",
            },
            {
                Header: "Form",
                accessor: "form_name",
            },
            {
                Header: "Email",
                accessor: "client_email", // accessor is the "key" in the data
            },
            {
                Header: "Client Name",
                accessor: "client_name",
            },
            {
                Header: "Consultant",
                accessor: "userDisplayName",
            },
            {
                Header: "Status",
                accessor: "status",
            },
            {
                Header: "Destination",
                accessor: "destination",
            },
            {
                Header: "Package",
                accessor: "package",
            },
        ],
        []
    );

    const globalFilter = useMemo(() => "", []);

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, preGlobalFilteredRows, setGlobalFilter } = useTable(
        { columns, data, globalFilter, initialState: { hiddenColumns: ["id"] } },
        useGlobalFilter
    );

    // async function loadMore(){
    //     setLoadingMore(true)
    //     if(filterStatus !== "All"){
    //         await firestore
    //         .collection("leads")
    //         .where("userUid", "==", filterUser)
    //         .where("status", "==", filterStatus)
    //         .onSnapshot((snapshot) => {
    //             let list = [];

    //             snapshot.docs.forEach((doc) => {
    //                 if (doc.exists) {
    //                     if (doc.data().status !== "Archived") {
    //                         let appObj = { ...doc.data(), id: doc.id };
    //                         list.push(appObj);
    //                     }
    //                 }
    //             });
    //             list.sort((a, b) => (a.assignDate < b.assignDate ? 1 : -1));
    //             setLeads(list);
    //             setLoadingMore(false)
    //             setMoreDisabled(true);
    //         });
    //     }else{
    //         await firestore
    //         .collection("leads")
    //         .where("userUid", "==", filterUser)
    //         .onSnapshot((snapshot) => {
    //             let list = [];

    //             snapshot.docs.forEach((doc) => {
    //                 if (doc.exists) {
    //                     if (doc.data().status !== "Archived") {
    //                         let appObj = { ...doc.data(), id: doc.id };
    //                         list.push(appObj);
    //                     }
    //                 }
    //             });
    //             list.sort((a, b) => (a.assignDate < b.assignDate ? 1 : -1));
    //             setLeads(list);
    //             setLoadingMore(false)
    //             setMoreDisabled(true)
    //         });
    //     }

    // }

    return (
        <>
            <Formik
                initialValues={{ filterValue: "", filterStatus: filterStatus }}
                // initialValues={{ filterValue: "", filterStatus: "All" }}
                enableReinitialize="true"
                validate={(values) => {}}
                onSubmit={(values, { setSubmitting }) => {
                    setTimeout(() => {
                        setSubmitting(false);
                    }, 400);
                }}
            >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                    <form onSubmit={handleSubmit} className="ui form">
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={3}>
                                    <Button
                                        style={{ marginTop: -5, width: "100%" }}
                                        color="orange"
                                        onClick={() => dispatch(openModal({ modalType: "LeadCreateModal", modalProps: { status: "Assigned" } }))}
                                    >
                                        Add Lead
                                    </Button>
                                </Grid.Column>

                                {currentUserProfile && !currentUserProfile.isExternal && (
                                    <Grid.Column width={3}>
                                        <MySelectInput
                                            label=""
                                            name="filterUser"
                                            options={usersList}
                                            value={filterUser === "" ? (currentUserProfile && currentUserProfile.id ? currentUserProfile.id : "") : filterUser}
                                            placeholder="Select a Consultant"
                                            onChange={(field, value) => changeConsultant(value, "", "")}
                                            removeClearable={true}
                                        />
                                    </Grid.Column>
                                )}

                                <Grid.Column width={3}>
                                    <MySelectInput
                                        label=""
                                        name="filterStatus"
                                        options={leadStatus}
                                        value={filterStatus === "" || filterStatus === undefined ? "All" : filterStatus}
                                        // value={filterStatus === "" ? "All" : filterStatus}
                                        placeholder="Select a Status"
                                        onChange={(field, value) => changeConsultant("", value, "")}
                                        removeClearable={true}
                                    />
                                </Grid.Column>

                                <Grid.Column width={filterUser === "All" ? 3 : 7}>
                                    <GlobalFilter preGlobalFilteredRows={preGlobalFilteredRows} globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
                                </Grid.Column>

                                {filterUser === "All" && (
                                    <Grid.Column width={2}>
                                        <MyTextInput label="" name="filterEmail" placeholder="Email" />
                                    </Grid.Column>
                                )}

                                {filterUser === "All" && (
                                    <Grid.Column width={2}>
                                        <Button style={{ marginTop: -5 }} color="orange" onClick={() => changeConsultant("", "", values.filterEmail)}>
                                            Search
                                        </Button>
                                    </Grid.Column>
                                )}
                            </Grid.Row>

                            {showSearchError && showSearchError === true && (
                                <Grid.Row>
                                    <Grid.Column textAlign="right" style={{ color: "red" }}>
                                        Please type in an Email address and hit Search when using All users or All status
                                    </Grid.Column>
                                </Grid.Row>
                            )}
                        </Grid>
                    </form>
                )}
            </Formik>
            <br />

            <div>
                Showing {rows.length} of {data.length} Leads
            </div>

            {loadingLeads === true ? (
                <LoadingComponent />
            ) : (
                <>
                    <table {...getTableProps()} className="ui celled table">
                        <thead>
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {rows.map((row) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()} onClick={() => props.history.push("/leads/edit/" + row.values.id)}>
                                        {row.cells.map((cell) => {
                                            return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>

                    {/* {currentUserProfile && currentUserProfile.noLeadsLoad && currentUserProfile.noLeadsLoad !== "0" && (
                    <Button loading={loadingMore} onClick={() => loadMore()}>Show more</Button>
                )} */}
                </>
            )}
        </>
    );
}
