import React, { useEffect, useState } from "react";
import { Grid, Segment, Header, Button, Confirm } from "semantic-ui-react";
import { deleteAppUser } from "../clientActions";
// import ClientAppDetails from "./ClientAppDetails";

export default function ClientAppDetails(props) {
    const client = props.client
    const [delAppOpen, setDelAppOpen] = useState(false);

    function closeDelApp() {
        setDelAppOpen(false);
    }

    function openDelApp() {
        setDelAppOpen(true);
    }

    async function delAppUser(userUid) {
        await deleteAppUser(userUid);
        setDelAppOpen(false);
    }

    return (
        <div>
            <Segment attached="top">
                <Header as="h3">App Login Details</Header>
            </Segment>

            <Segment attached>
                <Grid verticalAlign="middle">
                    <Grid.Row>
                        <Grid.Column width={5}>
                            <label>
                                <strong>Username</strong>
                            </label>
                        </Grid.Column>

                        <Grid.Column width={11}>{client.client_email}</Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                        <Grid.Column width={5}>
                            <label>
                                <strong>Password</strong>
                            </label>
                        </Grid.Column>

                        <Grid.Column width={11}>{client.appPassword}</Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>

            {
                <Segment attached="bottom">
                    <Button type="submit" onClick={openDelApp} color="red" content="Delete App User" />
                    <Confirm open={delAppOpen} onCancel={closeDelApp} content="This will delete the user's app account." onConfirm={() => deleteAppUser(client.appUserUid)} />
                </Segment>
            }
        </div>
    );
}
