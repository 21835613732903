import React, { useEffect, useState } from "react";
import { addHolidays } from "../../features/quotes/quoteActions";

export default function LoadHolidays(props) {

    useEffect(() => {
        
        setAllHolidays();
      }, []);

      async function setAllHolidays(){
        let holidaysArr = [];

                try {
                    let data = await fetchHolidays(1);
                    data = data.map(mapProductName);
                    holidaysArr = multiDimensionalUnique([...new Set([...holidaysArr, ...data])]);
                } catch (e) {
                    console.log(e);
                }

                try {
                    let data1 = await fetchHolidays(2);
                    data1 = data1.map(mapProductName);
                    holidaysArr = multiDimensionalUnique([...new Set([...holidaysArr, ...data1])]);
                } catch (e) {
                    console.log(e);
                }

                try {
                    let data2 = await fetchHolidays(3);
                    data2 = data2.map(mapProductName);
                    holidaysArr = multiDimensionalUnique([...new Set([...holidaysArr, ...data2])]);
                } catch (e) {
                    console.log(e);
                }

                try {
                    let data4 = await fetchHolidays(4);
                    data4 = data4.map(mapProductName);
                    holidaysArr = multiDimensionalUnique([...new Set([...holidaysArr, ...data4])]);
                } catch (e) {
                    console.log(e);
                }

                try {
                    let data5 = await fetchHolidays(5);
                    data5 = data5.map(mapProductName);
                    holidaysArr = multiDimensionalUnique([...new Set([...holidaysArr, ...data5])]);
                } catch (e) {
                    console.log(e);
                }

                try {
                    let data6 = await fetchHolidays(6);
                    data6 = data6.map(mapProductName);
                    holidaysArr = multiDimensionalUnique([...new Set([...holidaysArr, ...data6])]);
                } catch (e) {
                    console.log(e);
                }


                await addHolidays(holidaysArr.flat());
      }

    

    async function fetchHolidays(page) {
        let tot = 100;
        let params = "&status=publish&per_page=" + tot + "&page=" + page;
        let route = "https://www.igotravel.co.za/wp-json/wc/v3/products?&consumer_key=ck_6da67ca9223a3ed71eaadb4197dffa67bf19b2cb&consumer_secret=cs_9ccfb1ed69cdb1c827e23eec3cfaa849c9225a43";

        let data = await fetch(route + params);
        let dataRes = await data.json();

        return dataRes;

    }

    function mapProductName(product) {
        return {
            id: product.id,
            value: product.id,
            text: product.name,
            product,
        };
    }

    function multiDimensionalUnique(arr) {
        var uniques = [];
        var itemsFound = {};
        for (var i = 0, l = arr.length; i < l; i++) {
            var stringified = JSON.stringify(arr[i]);
            if (itemsFound[stringified]) {
                continue;
            }
            uniques.push(arr[i]);
            itemsFound[stringified] = true;
        }
        return uniques;
    }

    return (
        <div style={{ marginBottom: 20 }}>
            <p>Holidays Page</p>
        </div>
    );
}
