import React from "react";
import { Modal } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { Button, Grid } from "semantic-ui-react";
import { closeModal } from "../../app/common/modals/modalReducer";
import { sendEmail } from "../leads/leadActions";
import { renderToString } from "react-dom/server";
import BookingPDFPackage from "../booking/BookingPDF/BookingPDFPackage";
import { toast } from "react-toastify";
import { getPackageFlights } from "../quotes/quoteActions";
import { getClientConfirmation, getTraverllers, getClientPayment } from "../clients/clientActions";
import { Formik } from "formik";
import MyTextInput from "../../app/common/form/MyTextInput";
import MyTinyMCE from "../../app/common/form/MyTinyMCE";
import firebase from "../../app/config/firebase";
const firestore = firebase.firestore();

export default function BookingEmailModal(props) {
    const dispatch = useDispatch();
    let client_email = props.client_email;
    let quote = props.quote;
    let client_booking = props.client_booking;
    let lead = props.lead;
    let initialValues = props.initialValues;

    return (
        <>
            <Modal size="large" open={true} onClose={() => dispatch(closeModal())} closeOnDimmerClick={false}>
                <Modal.Header>Send Email - {props.lead.client_name}</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <Formik
                            initialValues={initialValues}
                            enableReinitialize={true}
                            validate={(values) => {
                                const errors = {};
                                if (!values.message) {
                                    errors.message = "Message is required";
                                }
                                if (!values.subject) {
                                    errors.subject = "Subject is required";
                                }
                                return errors;
                            }}
                            onSubmit={async (values, { setSubmitting }) => {
                                console.log(values);

                                values.leadId = props.leadId;
                                values.quoteId = props.quoteId;
                                values.to = props.client_email;
                                values.emailType = props.emailType;

                                //Consultant Details
                                values.fromEmail = props.currentUserProfile.email ? props.currentUserProfile.email : "noreply@igotravel.co.za";
                                values.fromName = props.currentUserProfile.displayName ? props.currentUserProfile.displayName : "IGO Travel";

                                //Mailtrap
                                if (props.currentUserProfile.mailtrap && props.currentUserProfile.mailtrap === true) {
                                    values.mailtrap = true;
                                }

                                //Generate a quote URL
                                let quoteNew = props.quote;
                                let quote_templates = props.quote_templates;

                                //Find Quote template
                                Object.keys(quote_templates).forEach(function (key) {
                                    if (quote_templates[key].id === quote.template_id) {
                                        quoteNew.templateUrl = quote_templates[key].fileUrl;
                                        quoteNew.template_name = quote_templates[key].template_name;
                                        quoteNew.visa = quote_templates[key].visa;
                                        quoteNew.destination_link = quote_templates[key].destination_link;
                                        quoteNew.wording = quote_templates[key].wording ? quote_templates[key].wording : "";
                                    }
                                });

                                // let flights = await getPackageFlights(client_booking.quoteId);

                                // if (flights.length > 0) {
                                //     flights.sort((a, b) => (a.departDateTime > b.departDateTime ? 1 : -1));
                                // }

                                let flightlist = [];

                                firestore
                                    .collection("quotes")
                                    .doc(client_booking.quoteId)
                                    .collection("packages")
                                    .onSnapshot((snapshot) => {
                                        snapshot.docs.forEach((doc) => {
                                            if (doc.exists) {
                                                firestore
                                                    .collection("quotes")
                                                    .doc(client_booking.quoteId)
                                                    .collection("packages")
                                                    .doc(doc.id)
                                                    .collection("flights")
                                                    .onSnapshot((snapshot2) => {
                                                        if (snapshot2.docs && snapshot2.docs.length > 0) {
                                                            snapshot2.docs.forEach((doc2) => {
                                                                let flight = doc2.data();
                                                                flightlist.push(flight);
                                                            });
                                                        }
                                                    });
                                            }
                                        });
                                    });

                                const someTimeoutAction = () => {
                                    return new Promise((resolve) => {
                                        setTimeout(function () {
                                            resolve(flightlist);
                                        }, 500);
                                    });
                                };

                                const flights = await someTimeoutAction();

                                if (flights.length > 0) {
                                    flights.sort((a, b) => (a.departDateTime > b.departDateTime ? 1 : -1));
                                }

                                let client_confirmation = {};

                                await getClientConfirmation(client_booking.id).then(
                                    (res) => {
                                        let list = [];
                                        res.forEach((doc) => {
                                            if (doc.exists) {
                                                let appObj = { ...doc.data(), ["id"]: `${doc.id}` };
                                                list.push(appObj);
                                            }
                                        });
                                        client_confirmation = list;
                                    },
                                    (err) => {
                                        console.log(err);
                                    }
                                );

                                let travellers = {};

                                await getTraverllers(client_booking.id).then(
                                    (res) => {
                                        let list = [];
                                        res.forEach((doc) => {
                                            if (doc.exists) {
                                                let appObj = { ...doc.data(), ["id"]: `${doc.id}` };
                                                list.push(appObj);
                                            }
                                        });
                                        travellers = list;
                                    },
                                    (err) => {
                                        console.log(err);
                                    }
                                );

                                let payments = {};

                                await getClientPayment(client_booking.id).then(
                                    (res) => {
                                        let list = [];
                                        res.forEach((doc) => {
                                            if (doc.exists) {
                                                let appObj = { ...doc.data(), ["id"]: `${doc.id}` };
                                                list.push(appObj);
                                            }
                                        });
                                        payments = list;
                                    },
                                    (err) => {
                                        console.log(err);
                                    }
                                );

                                let qtPackages = [];
                                let breakdown = [];

                                firestore
                                    .collection("quotes")
                                    .doc(client_booking.quoteId)
                                    .collection("packages")
                                    .doc(client_booking.packageId)
                                    .onSnapshot((snapshot2) => {
                                        if (snapshot2.exists) {
                                            qtPackages.flight_html = snapshot2.data().flight_html;

                                            let payments = snapshot2.data().price_breakdown;

                                            for (var i = 0; i < payments.length; i++) {
                                                if (payments[i].value) {
                                                    breakdown.push(payments[i].value);
                                                }
                                            }
                                        }
                                    });

                                const someTimeoutActionPayment = () => {
                                    return new Promise((resolve) => {
                                        setTimeout(function () {
                                            resolve(qtPackages);
                                        }, 500);
                                    });
                                };

                                const qtPackage = await someTimeoutActionPayment();

                                const pdfPackage = renderToString(
                                    <BookingPDFPackage
                                        qtPackage={qtPackage}
                                        breakdown={breakdown}
                                        client_booking={client_booking}
                                        quote={quoteNew}
                                        lead={lead}
                                        quote_templates={quote_templates}
                                        client_confirmation={client_confirmation}
                                        travellers={travellers}
                                        flights={flights}
                                        payments={payments}
                                    />
                                );

                                let html = pdfPackage;

                                await fetch("https://v2018.api2pdf.com//chrome/html", {
                                    method: "post",
                                    headers: {
                                        Accept: "application/json",
                                        "Content-Type": "application/json",
                                        Authorization: "104a3c14-8782-4ee5-885f-000b5a7fb8a1",
                                    },
                                    body: JSON.stringify({
                                        html: html,
                                        inlinePdf: true,
                                        fileName: "test.pdf",
                                        options: {
                                            marginBottom: 0,
                                            marginLeft: 0,
                                            marginRight: 0,
                                            marginTop: 0,
                                        },
                                    }),
                                })
                                    .then((res) => res.json())
                                    .then(async (res) => {
                                        if (res.success === true) {
                                            values.pdf = res.pdf;

                                            let email_id = await sendEmail(values, props.currentUserProfile);

                                            let sendEmailAPI = firebase.app().functions("europe-west2").httpsCallable("sendEmailAPI");

                                            await sendEmailAPI({ email_id: email_id })
                                                .then(async (result) => {
                                                    if (result.data === "Email sent OK") {
                                                        toast.success("Email has been queued to send");
                                                        dispatch(closeModal());
                                                    } else {
                                                        toast.error("Oops", "Error sending email - Type 2");
                                                    }

                                                    console.log(result);

                                                    setSubmitting(false);
                                                })
                                                .catch((error) => {
                                                    console.log(error);
                                                    toast.error("Oops", "Error sending email - Type 3");
                                                    setSubmitting(false);
                                                });
                                        } else {
                                            toast.error("Oops", "Error sending email - Type 1");
                                            setSubmitting(false);
                                        }
                                    });
                            }}
                        >
                            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                                <form onSubmit={handleSubmit} className="ui form">
                                    <MyTextInput label="Subject*" name="subject" type="text" />
                                    {errors && errors.subject ? (
                                        <p>
                                            <div class="ui red basic label">Subject is Required</div>
                                        </p>
                                    ) : null}

                                    <Grid verticalAlign="middle">
                                        <Grid.Row>
                                            <Grid.Column width={5}>
                                                <label>
                                                    <strong>To</strong>
                                                </label>
                                            </Grid.Column>

                                            <Grid.Column width={11}>{client_email}</Grid.Column>
                                        </Grid.Row>
                                    </Grid>

                                    <MyTextInput label="CC" name="cc" type="text" />

                                    <MyTextInput label="BCC" name="bcc" type="text" />

                                    <p>
                                        <em>*Note, for multiple emails seperate with a comma.</em>
                                    </p>

                                    <div style={{ marginBottom: 20, marginTop: 20 }}>
                                        <p>
                                            <strong>Attach Documents</strong>
                                        </p>

                                        <label>
                                            <input
                                                type="checkbox"
                                                name="attachBankTravel"
                                                value="true"
                                                className="ui checkbox"
                                                onChange={(value) => setFieldValue("attachBankTravel", value.target.checked)}
                                            />{" "}
                                            &nbsp; Bank details (Travel)
                                        </label>
                                        <br />
                                        <br />

                                        <label>
                                            <input
                                                type="checkbox"
                                                name="attachBankCorporate"
                                                value="true"
                                                className="ui checkbox"
                                                onChange={(value) => setFieldValue("attachBankCorporate", value.target.checked)}
                                            />{" "}
                                            &nbsp; Bank details (Corporate)
                                        </label>
                                        <br />
                                        <br />

                                        <label>
                                            <input type="checkbox" name="attachCC" value="true" className="ui checkbox" onChange={(value) => setFieldValue("attachCC", value.target.checked)} /> &nbsp;
                                            Credit Card Form
                                        </label>
                                        <br />
                                        <br />

                                        <label>
                                            <input
                                                type="checkbox"
                                                name="attachBryteBrochure"
                                                value="true"
                                                className="ui checkbox"
                                                onChange={(value) => setFieldValue("attachBryteBrochure", value.target.checked)}
                                            />{" "}
                                            &nbsp; Bryte Brochure
                                        </label>
                                        <br />
                                        <br />

                                        <label>
                                            <input
                                                type="checkbox"
                                                name="attachBrytePolicy"
                                                value="true"
                                                className="ui checkbox"
                                                onChange={(value) => setFieldValue("attachBrytePolicy", value.target.checked)}
                                            />{" "}
                                            &nbsp; Bryte Policy Wording
                                        </label>
                                        <br />
                                        <br />

                                        <label>
                                            <input
                                                type="checkbox"
                                                name="attachAsataCertificate"
                                                value="true"
                                                className="ui checkbox"
                                                onChange={(value) => setFieldValue("attachAsataCertificate", value.target.checked)}
                                            />{" "}
                                            &nbsp; ASATA Certificate
                                        </label>
                                        <br />
                                        <br />

                                        <label>
                                            <input
                                                type="checkbox"
                                                name="attachIataCertificate"
                                                value="true"
                                                className="ui checkbox"
                                                onChange={(value) => setFieldValue("attachIataCertificate", value.target.checked)}
                                            />{" "}
                                            &nbsp; IATA Certificate
                                        </label>
                                        <br />
                                        <br />

                                        <label>
                                            <input
                                                type="checkbox"
                                                name="attachBookingTerms"
                                                value="true"
                                                className="ui checkbox"
                                                onChange={(value) => setFieldValue("attachBookingTerms", value.target.checked)}
                                            />{" "}
                                            &nbsp; Terms & Conditions
                                        </label>
                                        <br />
                                        <br />
                                    </div>

                                    <MyTinyMCE label="Email Message*" name="message" type="text" onEditorChange={(value) => setFieldValue("message", value)} />

                                    <div style={{ marginTop: 20 }}>
                                        {client_email && client_email.length > 0 && (
                                            <Button color="orange" disabled={isSubmitting} loading={isSubmitting}>
                                                Send Email
                                            </Button>
                                        )}

                                        <Button disabled={isSubmitting} onClick={() => dispatch(closeModal())}>
                                            Cancel
                                        </Button>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </Modal.Description>
                </Modal.Content>
            </Modal>
        </>
    );
}
