import React, { useEffect, useState, useCallback } from "react";
import { Segment, Header, Button, Grid, Confirm, Table, Ref } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { openModal } from "../../../app/common/modals/modalReducer";
import { getTraverllers } from "../../clients/clientActions";
import { saveSortOrder, removeItinerary } from "../../leads/leadActions";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import format from "date-fns/format";

import firebase from "../../../app/config/firebase";
const firestore = firebase.firestore();

function BookingTravellers({ client_booking, client }) {
    const dispatch = useDispatch();

    const [travellers, setTravellers] = useState([]);
    const [openB, setOpenB] = useState(false);
    const [removeItem, setRemoveItem] = useState([]);

    useEffect(() => {
        const unsubscribe = firestore
            .collection("bookings")
            .doc(client_booking.id)
            .collection("travellers")
            .orderBy("sort_order")
            .onSnapshot((snapshot) => {
                let list = [];

                snapshot.docs.forEach((doc) => {
                    if (doc.exists) {
                        let appObj = { ...doc.data(), ["id"]: `${doc.id}` };
                        list.push(appObj);
                    }
                });

                setTravellers(list);
            });

        return () => unsubscribe();
    }, []);

    const updateSorted = useCallback((dataUpdated, client_booking) => {
        //Sort our data
        dataUpdated.sort((a, b) => (a.sort_order > b.sort_order ? 1 : -1));

        //Update State
        setTravellers(dataUpdated);
    }, []);

    const getItemStyle = (isDragging, draggableStyle) => ({
        background: isDragging && "lightblue",
        ...draggableStyle,
    });

    async function onDragEnd(result) {
        const { destination, source, reason } = result;

        //Dropped back in same place
        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        //What table are we sorting?
        let sorted_data = {};

        sorted_data = Object.assign([], travellers);

        //Change order of the items
        const file = sorted_data[source.index];
        sorted_data.splice(source.index, 1);
        sorted_data.splice(destination.index, 0, file);

        console.log(sorted_data);

        //Save order
        saveSortOrder(sorted_data, "travellers", client_booking);

        //Update state via callback
        updateSorted(sorted_data, client_booking);
    }

    function openD(id, type) {
        setRemoveItem(id);
        setOpenB(true);
    }

    function closeB() {
        setOpenB(false);
    }

    async function removeItineraryData(client_booking) {
        await removeItinerary(client_booking, removeItem, "travellers");

        setOpenB(false);
    }

    return (
        <div>
            <Segment attached="top">
                <Grid>
                    <Grid.Row columns={2} verticalAlign="middle">
                        <Grid.Column>
                            <Header as="h3">Travellers</Header>
                        </Grid.Column>

                        <Grid.Column textAlign="right">
                            <Button
                                color="orange"
                                as="a"
                                onClick={() => dispatch(openModal({ modalType: "LeadBookingModal", modalProps: { lead_booking: client_booking, client: client, modalType: "Add Traveller" } }))}
                            >
                                Add Traveller
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
            <Segment attached>
                {travellers && travellers.length > 0 ? (
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Table celled>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Name</Table.HeaderCell>
                                    <Table.HeaderCell>ID Number</Table.HeaderCell>
                                    <Table.HeaderCell>Passport</Table.HeaderCell>
                                    <Table.HeaderCell>Passport Expiry</Table.HeaderCell>
                                    <Table.HeaderCell>Actions</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Droppable droppableId="table_travellers">
                                {(provided, snapshot) => (
                                    <Ref innerRef={provided.innerRef}>
                                        <Table.Body {...provided.droppableProps}>
                                            {travellers.map((traveller, idx) => {
                                                return (
                                                    <Draggable draggableId={traveller.id.toString()} index={idx} key={traveller.id}>
                                                        {(provided, snapshot) => (
                                                            <Ref innerRef={provided.innerRef}>
                                                                <Table.Row
                                                                    key={traveller.id}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                                                >
                                                                    <Table.Cell>{traveller.traveller_name}</Table.Cell>
                                                                    <Table.Cell>{traveller.traveller_id}</Table.Cell>
                                                                    <Table.Cell>{traveller.traveller_passport}</Table.Cell>
                                                                    <Table.Cell>
                                                                        {traveller.traveller_passport_expiry ? format(traveller.traveller_passport_expiry.toDate(), "dd MMM yyyy") : ""}
                                                                    </Table.Cell>
                                                                    <Table.Cell textAlign="right" width={3}>
                                                                        <Button
                                                                            color="orange"
                                                                            as="a"
                                                                            onClick={() =>
                                                                                dispatch(
                                                                                    openModal({
                                                                                        modalType: "LeadBookingModal",
                                                                                        modalProps: {
                                                                                            lead_booking: client_booking,
                                                                                            client_booking: client_booking,
                                                                                            traveller: traveller,
                                                                                            client: client,
                                                                                            modalType: "Edit Traveller",
                                                                                        },
                                                                                    })
                                                                                )
                                                                            }
                                                                        >
                                                                            Edit
                                                                        </Button>

                                                                        <Button size="medium" color="red" content="Delete" as="a" onClick={() => openD(traveller.id, "travellers")} />
                                                                    </Table.Cell>
                                                                </Table.Row>
                                                            </Ref>
                                                        )}
                                                    </Draggable>
                                                );
                                            })}
                                        </Table.Body>
                                    </Ref>
                                )}
                            </Droppable>
                        </Table>
                    </DragDropContext>
                ) : (
                    "No Travellers"
                )}
            </Segment>

            <Confirm
                header="You are about to delete this Itinerary Row"
                content="This cannot be undone, are you sure?"
                size="tiny"
                open={openB}
                onCancel={closeB}
                onConfirm={() => removeItineraryData(client_booking, "confirmation")}
                confirmButton="Yes"
            />
        </div>
    );
}

export default BookingTravellers;
