import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { Button, Container, Divider, Grid, Image } from "semantic-ui-react";
import { Formik } from "formik";
import MyTextInput from "../../../app/common/form/MyTextInput";
import MySelectInput from "../../../app/common/form/MySelectInput";
import MyCheckboxInput from "../../../app/common/form/MyCheckboxInput";
import MyTinyMCE from "../../../app/common/form/MyTinyMCE";
import { updateProfile } from "../adminActions";
import { useSelector } from "react-redux";

import firebase from "../../../app/config/firebase";
const firestore = firebase.firestore();

const divisionOptions = [
    { key: "", text: "Choose a Division", value: "" },
    { key: "Leisure", text: "Leisure", value: "Leisure" },
    { key: "Non-Leisure", text: "Non-Leisure", value: "Non-Leisure" },
];

export default function UsersDetailedPage(props) {
    const [user, setUser] = useState({});
    const [userUid, setuserUid] = useState("");

    var parts = window.location.href.split("/");
    var lastSegment = parts.pop() || parts.pop();
    const { currentUserProfile } = useSelector((state) => state.profile);

    useEffect(() => {
        var userId = props.match.params.id;
        setuserUid(userId);

        const unsubscribe = firestore
            .collection("users")
            .doc(userId)
            .onSnapshot((snapshot) => {
                let userData = snapshot.data()

                if(userData && userData.moreInfoMessage && userData.moreInfoMessage !== ""){
                    userData.moreInfoMessage =  userData.moreInfoMessage;
                }else{
                    userData.moreInfoMessage = '<div style="font-family: Calibri; font-size: 14px; line-height: 20px;"><p>Good afternoon,</p><p>Thank you for your lovely enquiry submitted to our website. In order for me to offer you the best value quotation, I would like to get a few additional details to proceed.</p><ul><li>Preferred destination, country or city?</li><li>Proposed dates of travel?</li><li>How many passengers, and if children/teens please provide ages at time of travel?</li><li>Will you be departing from Johannesburg, Durban or Cape Town?</li><li>Any special requirement e.g. honeymoon?</li><li>Please advise what your budget is and I can provide a few appropriate options?</li><li>Please provide your telephone number to contact you on and best times to call?</li></ul><p>Once I have these details I can work on the quote. Please feel free to contact me should you have any queries.</p><p>Kind regards</p></div>';
                }
                setUser(userData);
                
            });

        return () => unsubscribe();
    }, []);

    const leadStatus = [
        { key: "All", text: "All", value: "All" },
        { key: "Assigned", text: "Assigned", value: "Assigned" },
        { key: "Booked", text: "Booked", value: "Booked" },
        { key: "Lost Lead", text: "Lost Lead", value: "Lost Lead" },
        { key: "Quoted", text: "Quoted", value: "Quoted" },
        { key: "Pending", text: "Pending", value: "Pending" },
        { key: "Follow Up", text: "Follow Up", value: "Follow Up" },
        { key: "Duplicate", text: "Duplicate", value: "Duplicate" },
        { key: "Awaiting Rates", text: "Awaiting Rates", value: "Awaiting Rates" },
        { key: "Travel out of date range", text: "Travel out of date range", value: "Travel out of date range" },
        { key: "Prospective", text: "Prospective", value: "Prospective" },
        { key: "Cancelled BKG", text: "Cancelled BKG", value: "Cancelled BKG" },
        { key: "Awaiting client feedback", text: "Awaiting client feedback", value: "Awaiting client feedback" },
        { key: "Archived", text: "Archived", value: "Archived" },
    ];

    return (
        <Container>
            <Formik
                enableReinitialize={true}
                initialValues={user}
                validate={(values) => {
                    const error = {};

                    return error;
                }}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                    values.id = lastSegment;
                    await updateProfile(values);
                    resetForm({
                        uploadFile: "",
                    });
                    setSubmitting(false);
                }}
            >
                {({ values, errors, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue, resetForm }) => (
                    <form className="ui form" onSubmit={handleSubmit}>
                        <Grid centered>
                            <Grid.Row>
                                <Grid.Column width={8}>
                                    {userUid.length === 1 && <MyTextInput label="Email*:" name="email" type="text" placeholder="Email Address" />}
                                    {userUid.length === 1 && <MyTextInput label="Password*:" name="password" type="password" placeholder="Password" />}

                                    <MyTextInput label="Full Name*:" name="displayName" type="text" placeholder="Known As" />

                                    <MyTextInput label="Cell Number:" name="cellNumber" type="text" placeholder="Cell Number" />

                                    <Grid>
                                        {currentUserProfile && !currentUserProfile.isExternal && (
                                            <Grid.Row columns={5}>
                                                <Grid.Column>
                                                    <label>Role:</label>
                                                </Grid.Column>

                                                <Grid.Column>
                                                    <MyCheckboxInput labelcb="Consultant" name="isConsultant" type="checkbox" />
                                                </Grid.Column>

                                                <Grid.Column>
                                                    <MyCheckboxInput labelcb="Super User" name="isSuper" type="checkbox" />
                                                </Grid.Column>

                                                <Grid.Column>
                                                    <MyCheckboxInput labelcb="Reports" name="isReports" type="checkbox" />
                                                </Grid.Column>

                                                <Grid.Column>
                                                    <MyCheckboxInput labelcb="External Consultant" name="isExternal" type="checkbox" />
                                                </Grid.Column>
                                            </Grid.Row>
                                        )}
                                        {currentUserProfile && !currentUserProfile.isExternal && (
                                            <Grid.Row columns={4}>
                                                <Grid.Column>
                                                    <label>Dashboard:</label>
                                                </Grid.Column>

                                                <Grid.Column>
                                                    <MyCheckboxInput labelcb="Show" name="showDashboard" type="checkbox" />
                                                </Grid.Column>
                                            </Grid.Row>
                                        )}
                                        {currentUserProfile && !currentUserProfile.isExternal && (
                                            <Grid.Row columns={4}>
                                                <Grid.Column>
                                                    <label>Use Mailtrap:</label>
                                                </Grid.Column>
                                                <Grid.Column>
                                                    <MyCheckboxInput labelcb="Yes" name="mailtrap" type="checkbox" />
                                                </Grid.Column>
                                            </Grid.Row>
                                        )}
                                        {currentUserProfile && !currentUserProfile.isExternal && (
                                            <Grid.Row columns={1}>
                                                <Grid.Column>
                                                    <MySelectInput label="Division" name="division" type="text" placeholder="Choose a Division" options={divisionOptions} />
                                                </Grid.Column>
                                            </Grid.Row>
                                        )}
                                        <Grid.Row columns={1} style={{ marginTop: "10px" }}>
                                            <Grid.Column>
                                                <MySelectInput label="Lead Status Order" name="leadStatusOrder" type="text" placeholder="Choose an Order" options={leadStatus} />
                                            </Grid.Column>
                                        </Grid.Row>

                                        <Grid.Row columns={1} style={{ marginTop: "10px" }}>
                                            <Grid.Column>
                                                <MyTextInput label="No. of Months to Load" name="noLeadsLoad" type="text" placeholder="6" />
                                                <small>Set to 0 to load all leads</small>
                                            </Grid.Column>
                                        </Grid.Row>

                                        <Grid.Row columns={1}>
                                            <Grid.Column>
                                                <MyTinyMCE name="moreInfoMessage" label="More Info Message" type="text" onEditorChange={(value) => setFieldValue("moreInfoMessage", value)} />
                                            </Grid.Column>
                                        </Grid.Row>

                                        <Grid.Row columns={1}>
                                            <Grid.Column>
                                                <MyTinyMCE name="defaultMessage" label="Default Message" type="text" onEditorChange={(value) => setFieldValue("defaultMessage", value)} />
                                            </Grid.Column>
                                        </Grid.Row>

                                        <Grid.Row columns={1}>
                                            <Grid.Column>
                                                <MyTinyMCE
                                                    name="defaultQuoteMessage"
                                                    label="Default Quote Message"
                                                    type="text"
                                                    onEditorChange={(value) => setFieldValue("defaultQuoteMessage", value)}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>

                                        <Grid.Row columns={1}>
                                            <Grid.Column>
                                                <MyTinyMCE
                                                    name="defaultBookingMessage"
                                                    label="Default Booking Message"
                                                    type="text"
                                                    onEditorChange={(value) => setFieldValue("defaultBookingMessage", value)}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>

                                        {userUid.length !== 1 && (
                                            <Grid.Row columns={2}>
                                                <Grid.Column>
                                                    <label>Email Signature</label>
                                                    <input
                                                        id="uploadFile"
                                                        name="uploadFile"
                                                        type="file"
                                                        onChange={(event) => {
                                                            setFieldValue("uploadFile", event.currentTarget.files[0]);
                                                        }}
                                                        className="form-control"
                                                    />

                                                    {user.fileUrl && user.fileUrl.length > 0 && <Image size="small" style={{ marginTop: 20 }} src={user.fileUrl} />}
                                                </Grid.Column>
                                            </Grid.Row>
                                        )}
                                    </Grid>
                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Row>
                                <Grid.Column width={8}>
                                    <Divider />

                                    <Button type="submit" disabled={isSubmitting} loading={isSubmitting} size="large" color="orange" content={userUid.length > 1 ? "Update User" : "Create User"} />

                                    <Button size="large" as={Link} to={`/admin/users/`}>
                                        Cancel
                                    </Button>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </form>
                )}
            </Formik>
        </Container>
    );
}
