import React, { useState, useEffect, useCallback } from "react";
// import { connect, useDispatch } from "react-redux";
import { Grid } from "semantic-ui-react";
import QuoteDetailedStatus from "./QuoteDetailedStatus";
import QuoteDetailedManPack from "./QuoteDetailedManPack";
import QuoteDetailedClonePack from "./QuoteDetailedClonePack";
import QuoteDetailedLoadHoliday from "./QuoteDetailedLoadHoliday";
import QuoteDetailedPackages from "./QuoteDetailedPackages";
import { getQuotes, getLeadsData, getUsers, getAuthUser } from "../quoteActions";
import ErrorBoundary from "../../../app/layout/ErrorBoundary";

import firebase from "../../../app/config/firebase";
import { useSelector } from "react-redux";
const firestore = firebase.firestore();

export default function QuoteDetailedPage(props) {
    let quoteId = props.match.params.id;
    const [quote, setQuote] = useState([]);
    const [quotePackages, setQuotePackages] = useState([]);
    // const [quote_templates, setQuote_templates] = useState([]);
    const [lead, setLeads] = useState([]);
    const [consultant, setConsultant] = useState([]);
    const [client, setClient] = useState([]);

    const { currentUserProfile } = useSelector((state) => state.profile);



    // make wrapper function to give child
    // const wrapperSetParentState = useCallback(
    //     (val) => {
    //         setQuotePackages(val);
    //     },
    //     [setQuotePackages]
    // );

    useEffect(() => {

        const unsubscribe = firestore
            .collection("quotes")
            .doc(quoteId)
            .onSnapshot((doc) => {
                let newClient = doc.data();
                newClient.id = doc.id;

                setQuote(newClient);

                if(newClient.clientId){

                    firestore.collection("clients").doc(newClient.clientId).onSnapshot((doc2) => {
                        setClient(doc2.data())
                    })
                }


                getLeadsData(newClient.id).then(
                    (res) => {
                        if (res.exists) {
                            let appObj = { ...res.data(), ["id"]: `${res.id}` };
                            setLeads(appObj);
                        }
                    },
                    (err) => {
                        console.log(err);
                    }
                );
                getUsers(newClient.userUid).then(
                    (res) => {
                        if (res.exists) {
                            let appObj = { ...res.data(), ["id"]: `${res.id}` };
                            setConsultant(appObj);
                        }
                    },
                    (err) => {
                        console.log(err);
                    }
                );
            });

        // getQuotes(quoteId).then(
        //     (res) => {
        //         if (res.exists) {
        //             let appObj = { ...res.data(), ["id"]: `${res.id}` };
        //             setQuote(appObj);
        //             getLeadsData(res.data().leadId).then(
        //                 (res) => {
        //                     if (res.exists) {
        //                         let appObj = { ...res.data(), ["id"]: `${res.id}` };
        //                         setLeads(appObj);
        //                     }
        //                 },
        //                 (err) => {
        //                     console.log(err);
        //                 }
        //             );
        //             getUsers(appObj.userUid).then(
        //                 (res) => {
        //                     if (res.exists) {
        //                         let appObj = { ...res.data(), ["id"]: `${res.id}` };
        //                         setConsultant(appObj);
        //                     }
        //                 },
        //                 (err) => {
        //                     console.log(err);
        //                 }
        //             );
        //         }
        //     },
        //     (err) => {
        //         console.log(err);
        //     }
        // );

        const unsubscribe2 = firestore
            .collection("quotes")
            .doc(quoteId)
            .collection("packages")
            .onSnapshot((snapshot) => {
                let list = [];

                snapshot.docs.forEach((doc) => {
                    if (doc.exists) {
                        let appObj = { ...doc.data(), ["id"]: `${doc.id}` };
                        list.push(appObj);
                    }
                });

                list.sort((a, b) => (a.createDate > b.createDate ? 1 : -1));
                setQuotePackages(list);
            });

        return () => {
            unsubscribe();
            unsubscribe2();
        };
    }, []);

    return (
        (!currentUserProfile.isExternal?true:(currentUserProfile.isExternal && quote.displayName==currentUserProfile.displayName)?true:false) && <>
            <Grid columns={2}>
                <Grid.Row>
                    <Grid.Column>
                        <ErrorBoundary>
                            <QuoteDetailedStatus
                                quote={quote}
                                quotePackages={quotePackages}
                                // quote_templates={quote_templates}
                                initialValues={quote}
                                lead={lead}
                                profile={currentUserProfile}
                                consultant={consultant}
                                client={client}
                            />
                        </ErrorBoundary>

                        {quote.quote_status === "Draft" && (
                            <ErrorBoundary>
                                <QuoteDetailedManPack quote={quote} profile={currentUserProfile} />
                            </ErrorBoundary>
                        )}
                    </Grid.Column>

                    <Grid.Column>
                        {quote.quote_status === "Draft" && (
                            <ErrorBoundary>
                                <QuoteDetailedLoadHoliday quote={quote} />
                            </ErrorBoundary>
                        )}

                        {quote.quote_status === "Draft" && (
                            <ErrorBoundary>
                                <QuoteDetailedClonePack quote={quote} quotePackages={quotePackages} profile={currentUserProfile} />
                            </ErrorBoundary>
                        )}
                    </Grid.Column>
                </Grid.Row>
            </Grid>

            {quote && quote.id && quotePackages && currentUserProfile && (
                <ErrorBoundary>
                    <QuoteDetailedPackages quote={quote} quotePackages={quotePackages} profile={currentUserProfile}  />
                </ErrorBoundary>
            )}

            {/* {quotePackages &&
                quote &&
                profile &&
                consultant &&
                quotePackages.length > 0 &&
                quotePackages.map((quotePackage) => (
                    <QuoteDetailedPackage
                        key={quotePackage.id}
                        quote={quote}
                        quotePackage={quotePackage}
                        form={"quotePackage_" + quotePackage.id}
                        // initialValues={quotePackage}
                        profile={currentUserProfile}
                        consultant={consultant}
                        parentStateSetter={wrapperSetParentState}
                        quotePackages={quotePackages}
                    />
                ))} */}
        </>
    );
}
