import React from "react";
import { Divider, Header, Segment, Table, List } from "semantic-ui-react";

export default function LeadDetailedContactDetails({ cfdb }) {
    return (
        <Segment>
            <Header as="h3">Contact Form Data</Header>

            <Divider />

            <Table celled compact>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Field Name</Table.HeaderCell>
                        <Table.HeaderCell>Field Value</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {cfdb.map(c => (
                        <Table.Row key={c.id}>
                            <Table.Cell>{c.field_name}</Table.Cell>
                            <Table.Cell style={{ wordWrap: "break-word", maxWidth: "250px" }}>{c.field_value}</Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </Segment>
    );
};
