import React from "react";
import { Modal } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { Button, Grid } from "semantic-ui-react";
import { closeModal } from "../../app/common/modals/modalReducer";
import { sendEmail } from "../leads/leadActions";
import { renderToString } from "react-dom/server";
import QuotePDFPackage from "../quotes/QuotePDF/QuotePDFPackage";
import { toastr } from "react-redux-toastr";
import { Formik } from "formik";
import MyTextInput from "../../app/common/form/MyTextInput";
import MyTinyMCE from "../../app/common/form/MyTinyMCE";
import { getPackageFlights, getQuoteTemplates } from "../quotes/quoteActions";
import { toast } from "react-toastify";

import firebase from "../../app/config/firebase";
// const firestore = firebase.firestore();

export default function LeadGeneralEmailModal(props) {
    let initialValuesData = props.initialValues;
    let email = props.client_email;
    const dispatch = useDispatch();
    var parts = window.location.href.split("/");
    var lastSegment = parts.pop() || parts.pop();
    return (
        <>
            <Modal size="large" open={true} onClose={() => dispatch(closeModal())}>
                <Modal.Header>Send Email</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <Formik
                            initialValues={initialValuesData}
                            validate={(values) => {
                                const error = {};
                                if (!values.to) {
                                    error.to = "To Email is required";
                                }
                                if (!values.subject) {
                                    error.subject = "Subject is required";
                                }
                                if (!values.message) {
                                    error.message = "Email Message is required";
                                }
                                return error;
                            }}
                            onSubmit={async (values, { setSubmitting }) => {
                                values.leadId = props.leadId;
                                values.quoteId = props.quoteId;
                                values.emailType = props.emailType;

                                //Consultant Details
                                values.fromEmail = props.profile.email ? props.profile.email : "noreply@igotravel.co.za";
                                values.fromName = props.profile.displayName ? props.profile.displayName : "IGO Travel";

                                //Mailtrap
                                if (props.profile.mailtrap && props.profile.mailtrap === true) {
                                    values.mailtrap = true;
                                }

                                //Generate a quote URL
                                if (props.includeQuote && props.includeQuote === true) {
                                    let quoteNew = props.quote;

                                    await getQuoteTemplates().then(
                                        (res) => {
                                            let list = [];
                                            res.forEach((doc) => {
                                                if (doc.exists) {
                                                    let appObj = { ...doc.data(), ["id"]: `${doc.id}` };
                                                    list.push(appObj);
                                                }
                                            });

                                            quoteNew = { ...quoteNew };

                                            //Find Quote template
                                            Object.keys(list).forEach(function (key) {
                                                if (list[key].id === props.quote.template_id) {
                                                    quoteNew.templateUrl = list[key].fileUrl;
                                                    quoteNew.template_name = list[key].template_name;
                                                    quoteNew.visa = list[key].visa;
                                                    quoteNew.destination_link = list[key].destination_link;
                                                    quoteNew.wording = list[key].wording ? list[key].wording : "";
                                                }
                                            });
                                        },
                                        (err) => {
                                            console.log(err);
                                        }
                                    );

                                    let flights = await getPackageFlights(lastSegment);
                                    if (flights.length > 0) {
                                        //Sort logs - for some reason we can't use where and orderBy on firestoreConnect
                                        flights.sort((a, b) => (a.departDateTime > b.departDateTime ? 1 : -1));
                                    }
                                    const pdfPackage = renderToString(
                                        <QuotePDFPackage quote={quoteNew} quotePackages={props.quotePackages} lead={props.lead} consultant={props.consultant} flights={flights} />
                                    );

                                    let html = pdfPackage;
                                    await fetch("https://v2018.api2pdf.com//chrome/html", {
                                        method: "post",
                                        headers: {
                                            Accept: "application/json",
                                            "Content-Type": "application/json",
                                            Authorization: "104a3c14-8782-4ee5-885f-000b5a7fb8a1",
                                        },
                                        body: JSON.stringify({
                                            html: html,
                                            inlinePdf: true,
                                            fileName: "test.pdf",
                                            options: {
                                                marginBottom: 0,
                                                marginLeft: 0,
                                                marginRight: 0,
                                                marginTop: 0,
                                            },
                                        }),
                                    })
                                        .then((res) => res.json())
                                        .then(async (res) => {
                                            if (res.success === true) {
                                                values.pdf = res.pdf;

                                                let email_id = await sendEmail(values, props.profile);

                                                let sendEmailAPI = firebase.app().functions("europe-west2").httpsCallable("sendEmailAPI");

                                                await sendEmailAPI({ email_id: email_id })
                                                    .then(async (result) => {
                                                        if (result.data === "Email sent OK") {
                                                            toast.success("Email has been queued to send");
                                                            dispatch(closeModal());
                                                        } else {
                                                            toastr.error("Oops", "Error sending email - Type 2");
                                                        }

                                                        console.log(result);

                                                        setSubmitting(false);
                                                    })
                                                    .catch((error) => {
                                                        console.log(error);
                                                        toastr.error("Oops", "Error sending email - Type 3");
                                                        setSubmitting(false);
                                                    });
                                            } else {
                                                toastr.error("Oops", "Error sending email - Type 1");
                                                setSubmitting(false);
                                            }
                                        });
                                } else {
                                    let email_id = await sendEmail(values, props.profile);

                                    let sendEmailAPI = firebase.app().functions("europe-west2").httpsCallable("sendEmailAPI");

                                    await sendEmailAPI({ email_id: email_id })
                                        .then(async (result) => {
                                            if (result.data === "Email sent OK") {
                                                toast.success("Email has been queued to send");
                                                dispatch(closeModal());
                                            } else {
                                                toast.error("Something went wrong 2");
                                            }

                                            console.log(result);

                                            setSubmitting(false);
                                        })
                                        .catch((error) => {
                                            console.log(error);
                                            toast.error("Something went wrong 3");
                                            setSubmitting(false);
                                        });
                                }
                            }}
                        >
                            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                                <form onSubmit={handleSubmit} className="ui form">
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column>
                                                <MyTextInput label="Subject*" name="subject" type="text" />
                                                <MyTextInput label="TO*" name="to" type="text" />
                                                <MyTextInput label="CC" name="cc" type="text" />
                                                <MyTextInput label="BCC" name="bcc" type="text" />
                                                <p>
                                                    <em>*Note, for multiple emails seperate with a comma.</em>
                                                </p>

                                                {props.includeQuote && props.includeQuote === true && (
                                                    <div style={{ marginBottom: 20, marginTop: 20 }}>
                                                        <p>
                                                            <strong>Attach Documents</strong>
                                                        </p>

                                                        <label>
                                                            <input
                                                                type="checkbox"
                                                                name="attachBryteBrochure"
                                                                value="true"
                                                                className="ui checkbox"
                                                                onChange={(value) => setFieldValue("attachBryteBrochure", value.target.checked)}
                                                            />{" "}
                                                            &nbsp; Bryte Brochure
                                                        </label>
                                                        <br />
                                                        <br />

                                                        <label>
                                                            <input
                                                                type="checkbox"
                                                                name="attachBrytePolicy"
                                                                value="true"
                                                                className="ui checkbox"
                                                                onChange={(value) => setFieldValue("attachBrytePolicy", value.target.checked)}
                                                            />{" "}
                                                            &nbsp; Bryte Policy Wording
                                                        </label>
                                                        <br />
                                                        <br />

                                                        <label>
                                                            <input
                                                                type="checkbox"
                                                                name="attachAsataCertificate"
                                                                value="true"
                                                                className="ui checkbox"
                                                                onChange={(value) => setFieldValue("attachAsataCertificate", value.target.checked)}
                                                            />{" "}
                                                            &nbsp; ASATA Certificate
                                                        </label>
                                                        <br />
                                                        <br />

                                                        <label>
                                                            <input
                                                                type="checkbox"
                                                                name="attachIataCertificate"
                                                                value="true"
                                                                className="ui checkbox"
                                                                onChange={(value) => setFieldValue("attachIataCertificate", value.target.checked)}
                                                            />{" "}
                                                            &nbsp; IATA Certificate
                                                        </label>
                                                        <br />
                                                        <br />

                                                        <label>
                                                            <b>Upload custom document:</b>
                                                        </label>
                                                        <input
                                                            id="uploadFile"
                                                            name="uploadFile"
                                                            type="file"
                                                            onChange={(event) => {
                                                                setFieldValue("uploadFile", event.currentTarget.files);
                                                            }}
                                                            className="form-control"
                                                            multiple
                                                        />
                                                        <br />
                                                        <br />
                                                    </div>
                                                )}

                                                <MyTinyMCE label="Email Message*" name="message" type="text" onEditorChange={(value) => setFieldValue("message", value)} />
                                            </Grid.Column>
                                        </Grid.Row>

                                        <Grid.Row>
                                            <Grid.Column textAlign="right">
                                                {email && email.length > 0 && <Button type="submit" disabled={isSubmitting} loading={isSubmitting} color="orange" content="Send Email" />}
                                                <Button type="button" disabled={isSubmitting} onClick={() => dispatch(closeModal())}>
                                                    Cancel
                                                </Button>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </form>
                            )}
                        </Formik>
                    </Modal.Description>
                </Modal.Content>
            </Modal>
        </>
    );
}
