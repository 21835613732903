import React from "react";
import { useField, useFormikContext } from "formik";
import { FormField, Grid, Label } from "semantic-ui-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function MyDateInput({ label, labelTop, disabledDays, ...props }) {
    const { setFieldValue } = useFormikContext();
    const [field, meta] = useField(props);
    return (
        <Grid verticalAlign="middle">
            <Grid.Row>
                {label && label.length > 0 && (
                    <Grid.Column width={5}>
                        <label>
                            <strong>{label}</strong>
                        </label>
                    </Grid.Column>
                )}

                {console.log(meta.error)}
                {console.log("meta.error")}

                <Grid.Column width={label && label.length > 0 ? 11 : 16}>
                    <FormField error={meta.touched && !!meta.error}>
                        {/* <label>{label}</label> */}
                        {labelTop && labelTop.length > 0 && <label>{labelTop}</label>}
                        <DatePicker
                            {...field}
                            {...props}
                            selected={(props.selected && new Date(props.selected)) || (field.value && new Date(field.value)) || null}
                            onChange={(value) => setFieldValue(field.name, value)}
                            autcomplete="false"
                            // minDate={new Date()}
                        />
                        {meta.touched && meta.error ? (
                            <Label basic color="red">
                                {meta.error}
                            </Label>
                        ) : null}
                    </FormField>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
}
