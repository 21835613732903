import React from "react";
import format from "date-fns/format";
import QuotePDFHeader from "./QuotePDFHeader";
import QuotePDFPrice from "./QuotePDFPrice";
import QuotePDFFinal from "./QuotePDFFinal";
//import QuotePDFFlights from './QuotePDFFlights';

const styleHtml = {
    fontFamily: "Gotham, sans-serif",
    fontSize: "13px",
    lineHeight: "17px",
};

const orangeBar = {
    background: "linear-gradient(90deg, rgba(246,140,70,1) 0%, rgba(250,166,36,1) 100%)",
    color: "white",
    padding: "15px 40px",
    textTransform: "uppercase",
    fontSize: "16px",
    lineHeight: "20px",
};

const orangeBarCondensed = {
    background: "linear-gradient(90deg, rgba(246,140,70,1) 0%, rgba(250,166,36,1) 100%)",
    color: "white",
    padding: "5px 40px",
    textTransform: "uppercase",
    fontSize: "16px",
    lineHeight: "16px",
};

const orangeBar2 = {
    background: "linear-gradient(90deg, rgba(246,140,70,1) 0%, rgba(250,166,36,1) 100%)",
    color: "white",
    padding: "5px 40px",
    textTransform: "uppercase",
    fontSize: "16px",
    lineHeight: "16px",
};

const heading2 = {
    fontSize: "16px",
    lineHeight: "22px",
};

const padding = {
    padding: "20px 30px",
};

const paddingCondensed = {
    padding: "5px 30px",
};

const flightTbl = {
    backgroundColor: "#eef0f1",
    padding: "5px 15px",
    marginTop: "10px",
};
const flightTblCondensed = {
    backgroundColor: "#eef0f1",
    padding: "2px 15px",
    marginTop: "2px",
};

const airportsAll = require("airport-codes");

const QuotePDFPackage = ({ quote, quotePackages, lead, consultant, flights }) => {
    quotePackages[0]["nopagebreak"] = "nobreak";

    let pagenr = 1;

    Object.keys(quotePackages).forEach(function (key) {
        quotePackages[key].pagenr = pagenr;
        pagenr = pagenr + 2;

        quotePackages[key].showFlights = false;
        quotePackages[key].showFlightsHtml = false;

        if ((!quotePackages[key].flight_html || quotePackages[key].flight_html === "") && flights && flights.length > 0) {
            Object.keys(flights).forEach(function (key2) {
                if (flights[key2].packageId === quotePackages[key].id) {
                    quotePackages[key].showFlights = true;

                    //First make it uppercase
                    flights[key2].airportFrom = flights[key2].airportFrom.toUpperCase();
                    flights[key2].airportTo = flights[key2].airportTo.toUpperCase();

                    //Now try to find the airport details
                    try {
                        flights[key2].airportFromName = airportsAll.findWhere({ iata: flights[key2].airportFrom }).get("name") + " (" + flights[key2].airportFrom + ")";
                    } catch (e) {
                        flights[key2].airportFromName = flights[key2].airportFrom;
                    }

                    try {
                        flights[key2].airportToName = airportsAll.findWhere({ iata: flights[key2].airportTo }).get("name") + " (" + flights[key2].airportTo + ")";
                    } catch (e) {
                        flights[key2].airportToName = flights[key2].airportTo;
                    }

                    //Check for airport with long name
                    if (flights[key2].airportFromName === "Sir Seewoosagur Ramgoolam Intl (MRU)") {
                        flights[key2].airportFromName = "Mauritius (MRU)";
                    }
                    if (flights[key2].airportToName === "Sir Seewoosagur Ramgoolam Intl (MRU)") {
                        flights[key2].airportToName = "Mauritius (MRU)";
                    }
                }
            });
        } else {
            if (quotePackages[key].flight_html !== undefined) {
                quotePackages[key].showFlightsHtml = true;

                let cssFlight = "<style>table { width: 100%; font-size: 8pt; line-height: '17px'; }</style>";
                quotePackages[key].flight_html = cssFlight + quotePackages[key].flight_html;
            }
        }
    });

    return (
        <div style={styleHtml}>
            {quotePackages &&
                quotePackages.length > 0 &&
                quotePackages.map((qtPackage) => (
                    <div className="content" key={qtPackage.id} style={{ pageBreakBefore: "always" }}>
                        <QuotePDFHeader quote={quote} pagenr={qtPackage.pagenr} />
                        {(!quote.template_id || quote.template_id !== "Custom Upload") && quote.templateUrl && quote.templateUrl.length > 0 && (
                            <div>
                                <div>
                                    <img style={{ width: "100%" }} src={quote.templateUrl} alt="" />
                                </div>

                                <div style={quote.condensed_pdf === true ? orangeBarCondensed : orangeBar}>{quote.template_name}</div>
                            </div>
                        )}
                        {quote.template_id && quote.template_id === "Custom Upload" && quote.fileUrl.length > 0 && (
                            <div style={{ height: "126px", overflow: "hidden" }}>
                                <img style={{ width: "100%" }} src={quote.fileUrl} alt="" />
                            </div>
                        )}
                        <div style={quote.condensed_pdf === true ? paddingCondensed : padding}>
                            <div style={heading2}>{qtPackage.package_name}</div>

                            <div style={{ marginTop: quote.condensed_pdf === true ? 2 : 10, marginBottom: quote.condensed_pdf === true ? 2 : 10 }}>
                                {qtPackage.stars && qtPackage.stars >= 1 && <img style={{ width: 20 }} src="https://leads.igotravel.co.za/assets/pdf_star.png" alt="Star" />}
                                {qtPackage.stars && qtPackage.stars >= 2 && <img style={{ width: 20 }} src="https://leads.igotravel.co.za/assets/pdf_star.png" alt="Star" />}
                                {qtPackage.stars && qtPackage.stars >= 3 && <img style={{ width: 20 }} src="https://leads.igotravel.co.za/assets/pdf_star.png" alt="Star" />}
                                {qtPackage.stars && qtPackage.stars >= 4 && <img style={{ width: 20 }} src="https://leads.igotravel.co.za/assets/pdf_star.png" alt="Star" />}
                                {qtPackage.stars && qtPackage.stars == 5 && <img style={{ width: 20 }} src="https://leads.igotravel.co.za/assets/pdf_star.png" alt="Star" />}
                            </div>

                            <div style={{ fontSize: 12, lineHeight: "16px", paddingBottom: 0 }} dangerouslySetInnerHTML={{ __html: qtPackage.holiday_description }}></div>

                            {qtPackage.showFlights === false && qtPackage.showFlightsHtml === true && qtPackage.flight_html && qtPackage.flight_html !== "" && qtPackage.flight_html !== "<style>table { width: 100%; font-size: 8pt; line-height: '17px'; }</style>" && (
                                <div>
                                    <div style={{ marginBottom: 5, heading2 }}>Your flight itinerary</div>
                                    <div style={flightTbl}>
                                        <div dangerouslySetInnerHTML={{ __html: qtPackage.flight_html }}></div>
                                    </div>
                                </div>
                            )}
                        </div>

                        {qtPackage.showFlights === true && (
                            <div style={quote.condensed_pdf === true ? paddingCondensed : padding}>                                
                                <div style={{ marginBottom: 5, heading2 }}>Your flight itinerary</div>

                                <div style={quote.condensed_pdf === true ? flightTblCondensed : flightTbl}>
                                    <table width="100%" style={{ width: "100%", fontSize: 12, lineHeight: "16px" }}>
                                        <tr>
                                            <td>Flight</td>
                                            <td>From</td>
                                            <td>To</td>
                                            <td>Depart</td>
                                            <td>Arrive</td>
                                        </tr>

                                        {flights.map((flght) => {
                                            if (flght.packageId === qtPackage.id)
                                                return (
                                                    <tr>
                                                        <td>{flght.flight_nr}</td>
                                                        <td>{flght.airportFromName}</td>
                                                        <td>{flght.airportToName}</td>
                                                        <td>{format(flght.departDateTime.toDate(), "dd MMM yyyy HH:mm")}</td>
                                                        <td>{format(flght.arriveDateTime.toDate(), "dd MMM yyyy HH:mm")}</td>
                                                    </tr>
                                                );
                                        })}
                                    </table>
                                </div>
                            </div>
                        )}
                        {qtPackage.includes && qtPackage.includes.length > 0 && (
                            <div>
                                <div style={orangeBar2}>YOUR TRAVEL PACKAGE INCLUDES</div>

                                <div style={quote.condensed_pdf === true ? paddingCondensed : padding}>
                                    <ul style={{ margin: 0, padding: "0 20px" }}>
                                        {qtPackage.includes.map((include, key) => (
                                            <li key={key}>{include.value}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        )}
                        {qtPackage.pricing_new_pg && qtPackage.pricing_new_pg === true ? (
                            <div style={{ pageBreakBefore: "always" }}>
                                <QuotePDFHeader quote={quote} pagenr={qtPackage.pagenr + 1} />

                                <QuotePDFPrice qtPackage={qtPackage} />
                            </div>
                        ) : (
                            <QuotePDFPrice qtPackage={qtPackage} />
                        )}
                    </div>
                ))}

            <div style={{ pageBreakBefore: "always" }}>
                <QuotePDFHeader quote={quote} pagenr={pagenr + 1} />
                <QuotePDFFinal quote={quote} lead={lead} consultant={consultant} quotePackages={quotePackages} />
            </div>
        </div>
    );
};

export default QuotePDFPackage;
