import React, { useEffect, useState } from "react";
import { Modal } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Table } from "semantic-ui-react";
import { assignUnassLead, getLeadUn, getCommentsUn } from "../leads/leadActions";
import { closeModal } from "../../app/common/modals/modalReducer";
import LeadDetailedComments from "../leads/LeadDetailed/LeadDetailedComments";
import { format } from "date-fns";

import firebase from "../../app/config/firebase";
const firestore = firebase.firestore();

export default function LeadUnassViewModal(props) {
    const dispatch = useDispatch();
    let lead = props.lead;

    let cfdb = [];
    let leadDate = lead.cfdb;
    const [leads_dup2, setLeads_dup2] = useState([]);

    const { currentUserProfile } = useSelector((state) => state.profile);

    useEffect(() => {
        if (lead.client_email !== undefined) {
            const unsubscribe = firestore
                .collection("leads")
                .where("client_email", "==", lead.client_email)
                .onSnapshot((snapshot) => {
                    let list = [];

                    snapshot.docs.forEach((doc) => {
                        if (doc.exists) {
                            let appObj = { ...doc.data(), id: doc.id };

                            if (appObj.status !== "Unassigned") {
                                list.push(appObj);
                            }
                        }
                    });

                    setLeads_dup2(list);
                });

            return () => unsubscribe();
        }
    }, []);

    let includes = [
        "travel_from_date",
        "travel_to_date",
        "dates_flexible",
        "adults",
        "children",
        "child_1",
        "child_2",
        "child_3",
        "child_4",
        "child_5",
        "child_6",
        "departing_from",
        "estimated_budget",
        "anything_else",
        "client_name",
        "client_surname",
        "client_email",
        "client_phone",
        "form_title",
        "where_go",
        "children_age",
        "privacy_consent",
        "entry_date",
        "source_url",
        "your_message",
        "wh_fb_campaign",
        "wh_query_string",
        "wh_referer_first",
        "wh_referer_last",
        "wh_referer_time",
    ];

    if (lead.form_name === "Woocommerce" || lead.form_name === "Quote Basket") {
        if (lead.cfdb.meta_data) {
            //Per website Form
            cfdb.push({ key: "Travel date (from)", value: lead.cfdb.meta_data[0].value });
            cfdb.push({ key: "Travel date (to)", value: lead.cfdb.meta_data[1].value });
            cfdb.push({ key: "Flexible dates", value: lead.cfdb.meta_data[2].value });
            cfdb.push({ key: "Adults", value: lead.cfdb.meta_data[3].value });

            cfdb.push({ key: "Children", value: lead.cfdb.meta_data ? lead.cfdb.meta_data[4].value : "" });
            cfdb.push({ key: "Child 1 Age", value: lead.cfdb.meta_data && lead.cfdb.meta_data[5] ? lead.cfdb.meta_data[5].value : "" });
            cfdb.push({ key: "Child 2 Age", value: lead.cfdb.meta_data && lead.cfdb.meta_data[6] ? lead.cfdb.meta_data[6].value : "" });
            cfdb.push({ key: "Child 3 Age", value: lead.cfdb.meta_data && lead.cfdb.meta_data[7] ? lead.cfdb.meta_data[7].value : "" });
            cfdb.push({ key: "Child 4 Age", value: lead.cfdb.meta_data && lead.cfdb.meta_data[8] ? lead.cfdb.meta_data[8].value : "" });
            cfdb.push({ key: "Child 5 Age", value: lead.cfdb.meta_data && lead.cfdb.meta_data[9] ? lead.cfdb.meta_data[9].value : "" });
            cfdb.push({ key: "Child 6 Age", value: lead.cfdb.meta_data && lead.cfdb.meta_data[10] ? lead.cfdb.meta_data[10].value : "" });
            cfdb.push({ key: "Departing from", value: lead.cfdb.meta_data && lead.cfdb.meta_data[11] ? lead.cfdb.meta_data[11].value : "" });

            // cfdb.push({ key: "Estimated budget", value: "MISSING" });
            // cfdb.push({ key: "Additional info", value: "MISSING" });

            cfdb.push({ key: "First name", value: lead.cfdb.billing.first_name });
            cfdb.push({ key: "Last Name", value: lead.cfdb.billing.last_name });
            cfdb.push({ key: "Email Address", value: lead.cfdb.billing.email });
            cfdb.push({ key: "Contact phone", value: lead.cfdb.billing.phone });

            //Session tracking data
            cfdb.push({ key: "wh_referer_first", value: lead.cfdb.billing ? lead.cfdb.billing.wh_referer_first : "" });
            cfdb.push({ key: "wh_referer_last", value: lead.cfdb.billing ? lead.cfdb.billing.wh_referer_last : "" });
            cfdb.push({ key: "wh_query_string", value: lead.cfdb.billing ? lead.cfdb.billing.wh_query_string : "" });
            cfdb.push({ key: "wh_referer_time", value: lead.cfdb.billing ? lead.cfdb.billing.wh_referer_time : "" });
            cfdb.push({ key: "wh_fb_campaign", value: lead.cfdb.billing ? lead.cfdb.billing.wh_fb_campaign : "" });

            Object.keys(lead.cfdb.line_items).forEach(function (key) {
                cfdb.push({
                    key: "name",
                    value: lead.cfdb.line_items[key].name,
                });
                cfdb.push({
                    key: "total",
                    value: lead.cfdb.line_items[key].subtotal,
                });
            });
        } else {
            const cfdbObj = JSON.parse(lead.cfdb[0].field_value);

            cfdb.push({ key: "Travel date (from)", value: cfdbObj.meta_data[0].value });
            cfdb.push({ key: "Travel date (to)", value: cfdbObj.meta_data[1].value });
            cfdb.push({ key: "Flexible dates", value: cfdbObj.meta_data[2].value });
            cfdb.push({ key: "Adults", value: cfdbObj.meta_data[3].value });
            

            cfdb.push({ key: "Children", value: cfdbObj.meta_data ? cfdbObj.meta_data[4].value : "" });
            cfdb.push({ key: "Child 1 Age", value: cfdbObj.meta_data && cfdbObj.meta_data[5] ? cfdbObj.meta_data[5].value : "" });
            cfdb.push({ key: "Child 2 Age", value: cfdbObj.meta_data && cfdbObj.meta_data[6] ? cfdbObj.meta_data[6].value : "" });
            cfdb.push({ key: "Child 3 Age", value: cfdbObj.meta_data && cfdbObj.meta_data[7] ? cfdbObj.meta_data[7].value : "" });
            cfdb.push({ key: "Child 4 Age", value: cfdbObj.meta_data && cfdbObj.meta_data[8] ? cfdbObj.meta_data[8].value : "" });
            cfdb.push({ key: "Child 5 Age", value: cfdbObj.meta_data && cfdbObj.meta_data[9] ? cfdbObj.meta_data[9].value : "" });
            cfdb.push({ key: "Child 6 Age", value: cfdbObj.meta_data && cfdbObj.meta_data[10] ? cfdbObj.meta_data[10].value : "" });
            cfdb.push({ key: "Departing from", value: cfdbObj.meta_data && cfdbObj.meta_data[11] ? cfdbObj.meta_data[11].value : "" });

            cfdb.push({ key: "Customer Note", value: cfdbObj.customer_note ? cfdbObj.customer_note : "" });

            cfdb.push({ key: "First name", value: cfdbObj.billing.first_name });
            cfdb.push({ key: "Last Name", value: cfdbObj.billing.last_name });
            cfdb.push({ key: "Email Address", value: cfdbObj.billing.email });
            cfdb.push({ key: "Contact phone", value: cfdbObj.billing.phone });

            //Session tracking data
            cfdb.push({ key: "wh_referer_first", value: cfdbObj.billing ? cfdbObj.billing.wh_referer_first : "" });
            cfdb.push({ key: "wh_referer_last", value: cfdbObj.billing ? cfdbObj.billing.wh_referer_last : "" });
            cfdb.push({ key: "wh_query_string", value: cfdbObj.billing ? cfdbObj.billing.wh_query_string : "" });
            cfdb.push({ key: "wh_referer_time", value: cfdbObj.billing ? cfdbObj.billing.wh_referer_time : "" });
            cfdb.push({ key: "wh_fb_campaign", value: cfdbObj.billing ? cfdbObj.billing.wh_fb_campaign : "" });

            Object.keys(cfdbObj.line_items).forEach(function (key) {
                cfdb.push({
                    key: "Quantity",
                    value: cfdbObj.line_items[key].quantity,
                });
                cfdb.push({
                    key: "name",
                    value: cfdbObj.line_items[key].name,
                });
                cfdb.push({
                    key: "total",
                    value: cfdbObj.line_items[key].subtotal,
                });
                
            });
        }
    } else if (lead.form_name !== "Unsure") {
        if (leadDate !== undefined) {
            if (leadDate[0] !== undefined && leadDate[0].field_name === "original_data") {
                let originalArr = JSON.parse(leadDate[0].field_value);

                cfdb.push({ key: "Travel date (from)", value: originalArr[14] });
                cfdb.push({ key: "Travel date (to)", value: originalArr[15] });
                cfdb.push({ key: "Flexible dates", value: originalArr[13] });

                cfdb.push({ key: "Departing from", value: originalArr[21] });
                cfdb.push({ key: "Estimated budget", value: originalArr[22] });

                cfdb.push({ key: "First name", value: originalArr[1] });
                cfdb.push({ key: "Last Name", value: originalArr[8] });
                cfdb.push({ key: "Email Address", value: originalArr[3] });
                cfdb.push({ key: "Contact phone", value: originalArr[9] });
            } else {
                var i;
                for (i = 0; i < includes.length; i++) {
                    // eslint-disable-next-line no-loop-func
                    Object.keys(leadDate).forEach(function (key) {
                        if (key == includes[i]) {
                            cfdb.push({
                                key: key,
                                value: lead.cfdb[key],
                            });
                        }
                    });
                }
            }
        }
    }

    if (lead.form_name === "Manual Add") {
        cfdb.push({ key: "Client Email", value: lead.client_email });
        cfdb.push({ key: "Client Name", value: lead.client_name });
        cfdb.push({ key: "Client Phone", value: lead.client_phone });
        cfdb.push({ key: "Departing From", value: lead.departing_from });
        cfdb.push({ key: "Destination", value: lead.destination });
        cfdb.push({ key: "Enquiry Source", value: lead.enquiry_source });
        cfdb.push({ key: "Enquiry Source Other", value: lead.enquiry_source_other });
        cfdb.push({ key: "Number of Travellers", value: lead.number_travellers });
        cfdb.push({ key: "Package", value: lead.package });

        if (lead.travel_date_from && lead.travel_date_from !== "") {
            cfdb.push({ key: "Travel Date From", value: format(lead.travel_date_from.toDate(), "dd-MMM-yyyy") });
        }
        if (lead.travel_date && lead.travel_date !== "") {
            cfdb.push({ key: "Travel Date To", value: format(lead.travel_date.toDate(), "dd-MMM-yyyy") });
        }

        cfdb.push({ key: "Comments", value: lead.unassigned_comments });
        cfdb.push({ key: "Website Division", value: lead.website_division });
        cfdb.push({ key: "Website Source", value: lead.website_source });
    }

    async function AssignLead(lead) {
        await assignUnassLead(lead, currentUserProfile);
        dispatch(closeModal());
    }

    return (
        <>
            <Modal size="large" open={true} onClose={() => dispatch(closeModal())} closeOnDimmerClick={false}>
                <Modal.Header>Lead Details</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        {leadDate && leadDate.length > 0 && leadDate.meta_data !== undefined && (
                            <Table>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell textAlign="center" colSpan={2}>
                                            Lead Data
                                        </Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {leadDate.map((c) => (
                                        <Table.Row key={c.key + c.value}>
                                            <Table.Cell>{c.key}</Table.Cell>
                                            <Table.Cell>{c.value}</Table.Cell>
                                        </Table.Row>
                                    ))}
                                </Table.Body>
                            </Table>
                        )}

                        {cfdb && cfdb.length > 0 && (
                            <Table>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell textAlign="center" colSpan={2}>
                                            Website Data
                                        </Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {cfdb.map((c) => (
                                        <Table.Row key={c.key + c.value}>
                                            <Table.Cell>{c.key}</Table.Cell>
                                            <Table.Cell>{c.value}</Table.Cell>
                                        </Table.Row>
                                    ))}
                                </Table.Body>
                            </Table>
                        )}
                        {leads_dup2 && leads_dup2.length > 0 && (
                            <Table>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell textAlign="center" colSpan={3}>
                                            Duplicates
                                        </Table.HeaderCell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.HeaderCell>Created On</Table.HeaderCell>
                                        <Table.HeaderCell>Assigned To</Table.HeaderCell>
                                        <Table.HeaderCell>Actions</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {leads_dup2.map((ld) => (
                                        <Table.Row key={ld.id}>
                                            <Table.Cell>
                                                {ld.assignDate && ld.assignDate !== ""
                                                    ? format(ld.assignDate.toDate(), "dd MMM yyyy HH:mm a")
                                                    : ld.createDate && ld.createDate !== ""
                                                    ? format(ld.createDate.toDate(), "dd MMM yyyy HH:mm a")
                                                    : ""}
                                            </Table.Cell>
                                            <Table.Cell>{ld.userDisplayName}</Table.Cell>
                                            <Table.Cell>
                                                <a target="_blank" href={`/leads/edit/${ld.id}`}>
                                                    View
                                                </a>
                                            </Table.Cell>
                                        </Table.Row>
                                    ))}
                                </Table.Body>
                            </Table>
                        )}

                        <LeadDetailedComments lead={lead} />
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button color="orange" onClick={() => AssignLead(lead)}>
                        Assign Lead
                    </Button>
                    <Button onClick={() => dispatch(closeModal())}>Cancel</Button>
                </Modal.Actions>
            </Modal>
        </>
    );
}
