import React from 'react';
import { Dimmer, Loader } from "semantic-ui-react";


const LoadingComponent = ({prodlength}) => {
    return (
        <Dimmer inverted active={true}>
            {prodlength && prodlength !== "" ? (
                <Loader content={"Loading... (" + prodlength + " loaded)"} />
            ) : (
                <Loader content="Loading..." />
            )}            
        </Dimmer>
    );
};

export default LoadingComponent;
