import React, { useEffect, useState } from "react";
import { Segment, Button, Header, Grid, Confirm } from "semantic-ui-react";
import { Formik } from "formik";
import MyTextInput from "../../../app/common/form/MyTextInput";
import { registerClientUser } from "../../auth/authActions";

export default function ClientUserForm(props) {
    // const [client, setClient] = useState([]);

    // useEffect(() => {
    //     var clientData = props.client;
    //     setClient(clientData);
    // }, [props.client]);

    return (
        <>
            <Formik
                initialValues={props.client}
                validate={(values) => {
                    const error = {};
                    if (!values.password) {
                        error.password = "Password is required";
                    }
                    // if (values.password.length < 8) {
                    //     error.password = "Password needs to be at least 8 characters";
                    // }
                    return error;
                }}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                    await registerClientUser(values, props.client);

                    await resetForm();

                    setSubmitting(false);
                }}
            >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                    <form onSubmit={handleSubmit} className="ui form">
                        <Segment attached="top">
                            <Header as="h3">App Login Details</Header>
                        </Segment>
                        <Segment attached>
                            <Grid verticalAlign="middle">
                                <Grid.Row>
                                    <Grid.Column width={5}>
                                        <label>
                                            <strong>Username</strong>
                                        </label>
                                    </Grid.Column>

                                    <Grid.Column width={11}>{props.client.client_email}</Grid.Column>
                                </Grid.Row>
                            </Grid>
                            <MyTextInput name="password" type="password" label="Password:" placeholder="Password" />
                        </Segment>

                        <Segment attached="bottom">
                            <Button type="submit" disabled={isSubmitting} loading={isSubmitting} color="orange" content="Create User" />
                        </Segment>
                    </form>
                )}
            </Formik>
        </>
    );
}
