import { SET_FILTER_STATUS } from "./leadConstants";
import { toastr } from "react-redux-toastr";
import format from "date-fns/format";
import differenceInMinutes from "date-fns/differenceInMinutes";
import { asyncActionError, asyncActionFinish, asyncActionStart } from "../async/asyncActions";
import cuid from "cuid";
import subYears from "date-fns/subYears";
import addDays from "date-fns/addDays";
import { toast } from "react-toastify";

import firebase from "../../app/config/firebase";
import { ContinuousColorLegend, ContinuousSizeLegend } from "react-vis";
import { subMonths } from "date-fns/esm";

const db = firebase.firestore();
const firestore = firebase.firestore();

export async function createLead(lead) {
    const firestore = firebase.firestore();

    const auth = firebase.auth().currentUser;
    let userDataId = auth.uid;
    let userData = db.collection("users").doc(userDataId);
    let profile = [];

    await userData.get().then(
        (res) => {
            if (res.exists) {
                let appObj = { ...res.data(), ["id"]: `${res.id}` };
                profile.push(appObj);
            }
        },
        (err) => {
            console.log(err);
        }
    );

    //Check if client already exists
    if (lead.client_email) {
        let clientRef = firestore.collection("clients").where("client_email", "==", lead.client_email);
        let clientSnap = await clientRef.get();

        if (clientSnap.docs.length > 0) {
            let client_data = clientSnap.docs[0].data();

            if (client_data.auto_link_off === undefined || client_data.auto_link_off === false) {
                let client_data = clientSnap.docs[0].data();
                lead.clientId = clientSnap.docs[0].id;
                lead.client_name = client_data.client_name;
                lead.client_phone = client_data.client_phone;
            }
        }
    }

    lead.createDate = new Date(Date.now());
    lead.assignDate = new Date(Date.now());
    //lead.status = "Assigned";
    lead.form_name = "Manual Add";
    //lead.assignDate = new Date(Date.now());
    lead.pickupDelay = 0;

    //Only add consultant information if this lead is not Unassigned
    if (lead.status !== "Unassigned") {
        lead.userDisplayName = profile[0].displayName;
        lead.userUid = profile[0].id;
    }

    try {
        let newLead = await firestore.collection("leads").add(lead);

        //Send Thank You email
        if (lead.send_thank_you && lead.send_thank_you === true && lead.client_email && lead.client_email !== "") {
            let message = `
            <table width="100%">
            <tr>
                <td>
                <table width="600" align="center">
                    <tr>
                    <td>
                        <img src="https://leads.igotravel.co.za/assets/emails/thank_you/thank_you_header.jpg" alt="" />
                    </td>
                    </tr>
                    <tr>
                    <td
                        style="
                        font-family: Arial, Helvetica, sans-serif;
                        letter-spacing: 2px;
                        font-weight: 700;
                        text-align: center;
                        padding-top: 30px;
                        padding-bottom: 10px;
                        "
                    >
                        YOU'RE ONE STEP CLOSER TO YOUR DREAM HOLIDAY
                    </td>
                    </tr>
                    <tr>
                    <td
                        style="
                        font-family: Arial, Helvetica, sans-serif;
                        letter-spacing: 1px;
                        text-align: center;
                        padding-top: 20px;
                        padding-left: 20px;
                        padding-right: 20px;
                        padding-bottom: 40px;
                        "
                    >
                        One of our consultants are working on your quote as we speak and
                        will be in contact within 24 hours. Keep an eye on your inbox - or
                        let us know and we'll phone you to discuss your quote.
                    </td>
                    </tr>
                    <tr>
                    <td>
                        <a href="https://www.igotravel.co.za"
                        ><img src="https://leads.igotravel.co.za/assets/emails/thank_you/thank_you_subscribe.jpg" alt=""
                        /></a>
                    </td>
                    </tr>
                    <tr>
                    <td
                        style="
                        font-family: Arial, Helvetica, sans-serif;
                        text-align: center;
                        font-weight: 600;
                        padding-top: 40px;
                        "
                    >
                        Don't forget to follow us on Facebook
                        <a
                        href="https://facebook.com/iGoTravel"
                        target="_blank"
                        style="text-decoration: none; color: black"
                        ><img src="https://leads.igotravel.co.za/assets/emails/thank_you/facebook.jpg" alt="" />@igotravel</a
                        >
                    </td>
                    </tr>
                    <tr>
                    <td
                        style="
                        font-family: Arial, Helvetica, sans-serif;
                        text-align: center;
                        font-weight: 600;
                        padding-top: 10px;
                        padding-bottom: 40px;
                        "
                    >
                        and on Instagram
                        <a
                        href="https://www.instagram.com/igotravelza/"
                        target="_blank"
                        style="text-decoration: none; color: black"
                        ><img src="https://leads.igotravel.co.za/assets/emails/thank_you/instagram.jpg" alt="" />@igotravelza
                        </a>
                    </td>
                    </tr>
                    <tr>
                    <td>
                        <a href="https://www.igotravel.co.za"
                        ><img src="https://leads.igotravel.co.za/assets/emails/thank_you/thank_you_footer.jpg" alt=""
                        /></a>
                    </td>
                    </tr>
                </table>
                </td>
            </tr>
            </table>
            `;

            let email = {
                status: "Pending",
                addedDt: new Date(Date.now()),
                leadId: newLead.id,
                emailType: "Thank You",
                message: message,
                subject: "Thank You",
                to: lead.client_email,
            };

            if (profile[0] && profile[0].mailtrap && profile[0].mailtrap === true) {
                email.mailtrap = true;
            }

            await firestore.collection("email").add(email);

            //Log email
            let log = {
                createDate: new Date(Date.now()),
                table: "leads",
                rowId: email.leadId,
                type: "Send Email: " + email.emailType,
                userDisplayName: profile[0].displayName,
                userUid: profile[0].id,
            };

            await firestore.collection("logs").add(log);
        }

        if (lead.status === "Unassigned") {
            let unassignedEmail = {
                status: "Pending",
                addedDt: new Date(Date.now()),
                leadId: newLead.id,
                message: "A new unassigned lead has been manually added.<br /><a href='https://leads.igotravel.co.za/leads/unassigned'>Click here to open</a>",
                subject: "Lead manually added",
                to: "leisure@igotravel.co.za",
            };

            if (profile[0] && profile[0].mailtrap && profile[0].mailtrap === true) {
                unassignedEmail.mailtrap = true;
            }

            await firestore.collection("email").add(unassignedEmail);

            let unassignedlog = {
                createDate: new Date(Date.now()),
                table: "leads",
                rowId: unassignedEmail.leadId,
                userDisplayName: profile[0].displayName,
                userUid: profile[0].id,
            };
            await firestore.collection("logs").add(unassignedlog);
        }

        toast.success("Lead has been created");
    } catch (error) {
        console.log(error);
        toast.error("Something went wrong");
    }
}

export async function updateComment(comments) {
    const firestore = firebase.firestore();
    firestore.collection("leads").doc(comments.leadId).collection("comments").doc(comments.id).update({ comment: comments.comment });
}

export async function updateLead(lead, profile) {
    const firestore = firebase.firestore();

    let oldLead = [];
    await firestore
        .collection("leads")
        .doc(lead.id)
        .get()
        .then(
            (res) => {
                if (res.exists) {
                    let appObj = { ...res.data(), leadId: res.id };
                    oldLead = appObj;
                }
            },
            (err) => {
                console.log(err);
            }
        );

    await firestore.collection("leadTrail").add(oldLead);

    try {
        if (lead.status !== "Unassigned") {
            let userRef = firestore.collection("users").doc(lead.userUid);
            let userSnap = await userRef.get();

            lead.userDisplayName = userSnap.data().displayName;

            // Check if this lead is being reassigned
            if (lead.reassign && lead.reassign !== "" && lead.reassign !== undefined) {
                let userNewSnap = await firestore.collection("users").doc(lead.reassign).get();
                let user_new_data = userNewSnap.data();

                let email = {};
                email.status = "Pending";
                email.addedDt = new Date(Date.now());
                email.emailType = "Lead Update";
                email.leadId = lead.id;

                email.message = "Lead " + lead.client_name + " (" + lead.client_email + ")" + " Re-Assigned to you from " + userSnap.data().displayName + "<br />";
                email.message += "<a href='https://leads.igotravel.co.za/leads/edit/" + lead.id + "'>Click here to view Lead</a>";
                email.subject = "Lead Re-Assigned";
                email.to = user_new_data.email;

                if (profile && profile.mailtrap && profile.mailtrap === true) {
                    email.mailtrap = true;
                }

                await firestore.collection("email").add(email);

                //Log email
                let log = {
                    createDate: new Date(Date.now()),
                    table: "leads",
                    rowId: email.leadId,
                    type: "Send Email: " + email.emailType,
                    userUid: lead.userUid,
                };

                await firestore.collection("logs").add(log);

                //Update the users details
                if (!user_new_data.displayName) {
                    lead.userDisplayName = user_new_data.email;
                } else {
                    lead.userDisplayName = user_new_data.displayName;
                }

                lead.userUid = lead.reassign;
                delete lead.reassign;
            }

            let leadRef = firestore.collection("leads").doc(lead.id);
            let leadSnap = await leadRef.get();
            let lead_data = leadSnap.data();

            lead.pickupDelay =
                lead_data.createDate && lead_data.assignDate && lead_data.createDate !== lead.lead_data
                    ? differenceInMinutes(lead_data.assignDate.toDate(), lead_data.createDate.toDate()) + " Minutes"
                    : "N/A";

            await firestore.collection("leads").doc(lead.id).update(lead);

            let log = {
                createDate: new Date(Date.now()),
                table: "leads",
                rowId: lead.id,
                type: "Updated",
                userDisplayName: lead.userDisplayName,
                userUid: lead.userUid,
            };

            await firestore.collection("logs").add(log);

            //Update lead details on quotes
            let batch = firestore.batch();

            let quoteDocRef = await firestore.collection("quotes").where("leadId", "==", lead.id);
            let quoteQuerySnap = await quoteDocRef.get();

            for (let i = 0; i < quoteQuerySnap.docs.length; i++) {
                let subquoteDocRef = await firestore.collection("quotes").doc(quoteQuerySnap.docs[i].id);

                if (lead.clientId) {
                    await batch.update(subquoteDocRef, {
                        clientId: lead.clientId,
                        client_email: lead.client_email ? lead.client_email : "",
                        client_name: lead.client_name ? lead.client_name : "",
                        client_phone: lead.client_phone ? lead.client_phone : "",
                    });
                } else {
                    await batch.update(subquoteDocRef, {
                        client_email: lead.client_email ? lead.client_email : "",
                        client_name: lead.client_name ? lead.client_name : "",
                        client_phone: lead.client_phone ? lead.client_phone : "",
                    });
                }
            }

            await batch.commit();

            //Update Client Status
            if (lead.clientId && lead.clientId.length > 0) {
                let quotesRef = firestore.collection("leads").where("clientId", "==", lead.clientId);
                let quotesSnap = await quotesRef.get();

                let booked = 0;
                let quotes = 0;

                for (let i = 0; i < quotesSnap.docs.length; i++) {
                    if (quotesSnap.docs[i].data().status === "Booked") {
                        booked++;
                    } else {
                        quotes++;
                    }
                }

                let client_status = "New Lead";
                if (booked === 0 && quotes > 1) {
                    client_status = "Prospecitve Lead";
                }
                if (booked === 1) {
                    client_status = "Booked Client";
                }
                if (booked > 1) {
                    client_status = "Repeat Client";
                }

                await firestore.collection("clients").doc(lead.clientId).update({ status: client_status });
            }
        } else {
            let leadRef = firestore.collection("leads").doc(lead.id);

            await leadRef.update({
                userUid: firebase.firestore.FieldValue.delete(),
                assignDate: firebase.firestore.FieldValue.delete(),
                pickupDelay: firebase.firestore.FieldValue.delete(),
                userDisplayName: firebase.firestore.FieldValue.delete(),
                status: lead.status,
            });
        }

        toast.success("Lead has been updated");
    } catch (error) {
        console.log(error);
        toast.error("Something went wrong");
    }
}

export async function createComment(comment) {
    const firestore = firebase.firestore();
    comment.createDate = new Date(Date.now());

    try {
        await firestore.collection("leads").doc(comment.leadId).collection("comments").add(comment);
        toast.success("Comment has been created");
    } catch (error) {
        console.log(error.message);
        toast.error("Something went wrong");
    }
}

export async function createFollowUp(followup) {
    const firestore = firebase.firestore();
    followup.createDate = new Date(Date.now());
    console.log(followup);

    try {
        await firestore.collection("leads").doc(followup.leadId).collection("followups").add(followup);
        toast.success("Follow Up has been created");
    } catch (error) {
        console.log(error.message);
        toast.error("Something went wrong");
    }
}

export function setFilterStatus(newStatus) {
    return {
        type: SET_FILTER_STATUS,
        payload: newStatus,
    };
}

export async function createQuote(lead, profile) {
    const firestore = firebase.firestore();

    let quote = {};
    quote.leadId = lead.id;

    quote.client_name = lead.client_name;

    if (lead.client_email) {
        quote.client_email = lead.client_email;
    }

    if (lead.client_phone) {
        quote.client_phone = lead.client_phone;
    }

    quote.userUid = profile.id;
    quote.displayName = profile.displayName;

    quote.createDate = new Date(Date.now());
    quote.quote_status = "Draft";

    if (profile.division) {
        quote.logo_division = profile.division;
    }

    let dtToday = new Date();
    dtToday.setHours(0, 0, 0, 0);

    let quotesRef = firestore.collection("quotes").where("createDate", ">=", dtToday);
    let quoteQuery = await quotesRef.orderBy("createDate", "asc").orderBy("quote_nr", "asc");
    let quoteQuerySnap = await quoteQuery.get();

    let max = 0;
    for (let i = 0; i < quoteQuerySnap.docs.length; i++) {
        if (quoteQuerySnap.docs[i].data().quote_nr > max) {
            max = quoteQuerySnap.docs[i].data().quote_nr;
        }
    }

    quote.quote_nr = parseInt(max) + 1;
    quote.quote_rev = 1;

    try {
        let newQuote = await firestore.collection("quotes").add(quote);

        toast.success("Quote has been created");

        return newQuote.id;
    } catch (error) {
        console.log(error);
        toast.error("Something went wrong");
    }
}

export async function createBookingNew(lead) {
    const firestore = firebase.firestore();

    let booking = {};

    booking.createDate = new Date(Date.now());

    let bookingRef = firestore.collection("bookings").where("leadId", "==", lead.id);
    let bookingQuery = await bookingRef.orderBy("booking_nr", "asc");
    let bookingQuerySnap = await bookingQuery.get();

    let max = 0;
    for (let i = 0; i < bookingQuerySnap.docs.length; i++) {
        if (bookingQuerySnap.docs[i].data().booking_nr > max) {
            max = bookingQuerySnap.docs[i].data().booking_nr;
        }
    }

    booking.booking_nr = max + 1;
    booking.booking_rev = 1;
    booking.leadId = lead.id;
    booking.destination = lead.destination ? lead.destination : "";
    booking.travel_date = lead.travel_date ? lead.travel_date : "";
    booking.clientId = lead.clientId;

    try {
        await firestore.collection("bookings").add(booking);
        toast.success("Booking has been created");
    } catch (error) {
        console.log(error);
        toast.error("Something went wrong");
    }
}

export async function assignUnassLead(lead, currentUserProfile) {
    const firestore = firebase.firestore();

    // const auth = firebase.auth().currentUser;
    // let userDataId = auth.uid;
    // let userData = db.collection("users").doc(userDataId);
    // let profile = [];

    // await userData.get().then(
    //     (res) => {
    //         if (res.exists) {
    //             let appObj = { ...res.data(), id: res.id };
    //             profile.push(appObj);
    //         }
    //     },
    //     (err) => {
    //         console.log(err);
    //     }
    // );

    let newStatus = {};
    newStatus.status = "Assigned";
    newStatus.assignDate = new Date(Date.now());

    newStatus.userDisplayName = currentUserProfile.displayName;
    newStatus.userUid = currentUserProfile.id;

    if (lead.form_name === "Woocommerce" || lead.form_name === "Quote Basket") {
        if (!lead.cfdb.meta_data) {
            newStatus.cfdb = JSON.parse(lead.cfdb[0].field_value);
        }
    }

    try {
        await firestore.collection("leads").doc(lead.id).set(newStatus, { merge: true });
        toast.success("Lead has been assigned");
    } catch (error) {
        console.log(error.message);
        toast.error("Something went wrong");
    }
}

export async function sendEmail(email, profile) {
    const firestore = firebase.firestore();

    email.status = "In Progress";
    email.addedDt = new Date(Date.now());

    if (profile && profile.mailtrap && profile.mailtrap === true) {
        email.mailtrap = true;
    }

    try {
        if (email.cc === undefined) {
            delete email.cc;
        }

        let customAttchments = [];

        if (email.uploadFile) {
            let files = email.uploadFile;
            // Object.keys(files).forEach(async function (key) {
            //     console.log(files[key])

            //     const file = files[key];

            //     const path = `emails/${email.leadId}`;
            //     const options = {
            //         name: cuid() + "-" + file.name,
            //     };

            //     let uploadedFile = await firebase.uploadFile(path, file, null, options).then((ret) => ret.uploadTaskSnapshot.ref.getDownloadURL());

            //     let values = {};

            //     values.fileName = file.name;
            //     values.fileUrl = uploadedFile;
            //     values.documentNameRand = options.name;

            //     customAttchments.push(values)
            // })

            for (const file of files) {
                console.log(file);

                // const file = file;

                const path = `emails/${email.leadId}`;
                const options = {
                    name: cuid() + "-" + file.name,
                };

                let uploadedFile = await firebase.uploadFile(path, file, null, options).then((ret) => ret.uploadTaskSnapshot.ref.getDownloadURL());

                let values = {};

                values.fileName = file.name;
                values.fileUrl = uploadedFile;
                values.documentNameRand = options.name;

                customAttchments.push(values);
            }
        }

        // const someTimeoutAction = () => {
        //     return new Promise((resolve) => {
        //         setTimeout(function () {
        //             resolve(customAttchments);
        //         }, 3000);
        //     });
        // };

        // const allAttchments = await someTimeoutAction();

        email.customAttchments = customAttchments;

        delete email.uploadFile;

        let email_id = await firestore
            .collection("email")
            .add(email)
            .then(async (email_new) => {
                let email_id = email_new.id;

                console.log(email_new, email_id);

                //Log email
                let log = {
                    createDate: new Date(Date.now()),
                    table: "leads",
                    rowId: email.leadId,
                    type: "Send Email: " + email.emailType,
                    userDisplayName: profile.displayName,
                    userUid: profile.id,
                };

                await firestore.collection("logs").add(log);

                return email_id;
            });

        return email_id;
    } catch (error) {
        console.log(error.message);
        toast.error("Something went wrong");
    }
}

export async function addScheduleEmail(email) {
    const firestore = firebase.firestore();

    try {
        email.status = "Pending";
        email.added_dt = new Date(Date.now());

        await firestore.collection("email_scheduled").add(email);
        toast.success("Email has been scheduled to send");
    } catch (error) {
        console.log(error.message);
        toast.error("Something went wrong");
    }
}

export async function deleteScheduleEmail(id) {
    const firestore = firebase.firestore();

    try {
        await firestore.collection("email_scheduled").doc(id).delete();

        toast.success("Scheduled Email has been deleted");
    } catch (error) {
        console.log(error.message);
        toast.error("Something went wrong");
    }
}

export async function getPackages(leadId) {
    const firestore = firebase.firestore();

    let packagesList = [];

    try {
        let quotesRef = firestore.collection("quotes").where("leadId", "==", leadId);
        let quotesSnap = await quotesRef.get();

        for (let i = 0; i < quotesSnap.docs.length; i++) {
            let quote_id = quotesSnap.docs[i].id;
            let quote_nr = format(quotesSnap.docs[i].data().createDate.toDate(), "yyyyMMdd") + "-" + quotesSnap.docs[i].data().quote_nr + "-" + quotesSnap.docs[i].data().quote_rev;

            let packagesRef = firestore.collection("quotes").doc(quote_id).collection("packages");
            let packagesSnap = await packagesRef.get();

            for (let x = 0; x < packagesSnap.docs.length; x++) {
                let cur_package = {
                    key: packagesSnap.docs[x].id + "|" + quote_id,
                    text: quote_nr + " - " + packagesSnap.docs[x].data().package_name,
                    value: packagesSnap.docs[x].id + "|" + quote_id,
                    price: packagesSnap.docs[x].data().price,
                    holiday_description: packagesSnap.docs[x].data().holiday_description,
                };

                packagesList.push(cur_package);
            }
        }

        return packagesList;
    } catch (error) {
        console.log(error.message);
        toast.error("Something went wrong");
    }
}

export const createBooking = (values, lead) => {
    return async (dispatch, getState, { getFirestore }) => {
        const firestore = getFirestore();

        try {
            let quotesRef = firestore.collection("quotes").where("leadId", "==", lead.id);
            let quotesSnap = await quotesRef.get();

            for (let i = 0; i < quotesSnap.docs.length; i++) {
                let quote_id = quotesSnap.docs[i].id;
                values.quoteId = quote_id;

                //Set lead booking details
                await firestore.update(`leads/${lead.id}`, values);

                let quote_status = "Locked";

                let packagesRef = firestore.collection("quotes").doc(quote_id).collection("packages");
                let packagesSnap = await packagesRef.get();

                for (let i = 0; i < packagesSnap.docs.length; i++) {
                    let this_package_update = {};

                    if (packagesSnap.docs[i].id === values.booked_package_id) {
                        this_package_update.package_status = "Booked";
                        quote_status = "Booked";
                    } else {
                        this_package_update.package_status = "Locked";
                    }

                    await firestore.update(`quotes/${quote_id}/packages/${packagesSnap.docs[i].id}`, this_package_update);
                }

                await firestore.update(`quotes/${quote_id}`, { quote_status: quote_status });
            }

            toast.success("Booking has been created");
        } catch (error) {
            console.log(error.message);
            toast.error("Something went wrong");
        }
    };
};

export async function saveItinerary(values, bookingType) {
    // const firebase = firebase.storage().getReference();

    try {
        if (values.date && values.date === "") {
            values.date = null;
        }

        //Check if we have a custom background to upload
        if (values.uploadFile) {
            const file = values.uploadFile;
            delete values.uploadFile;

            const path = `bookings/${values.bookingId}`;
            const options = {
                name: cuid() + "-" + file.name,
            };

            let uploadedFile = await firebase.uploadFile(path, file, null, options).then((ret) => ret.uploadTaskSnapshot.ref.getDownloadURL());

            values.fileName = file.name;
            values.fileUrl = uploadedFile;
            values.documentNameRand = options.name;
            values.hasBG = true;
        }

        if (values.textColour === undefined) {
            delete values.textColour;
        }

        await db.collection("bookings").doc(values.bookingId).collection(bookingType).doc(values.id).update(values);
        toast.success("Itinerary has been saved");
    } catch (error) {
        console.log(error.message);
        toast.error("Something went wrong");
    }
}

export async function removeBookingItinImage(itin, lead_booking, bookingType) {
    const firestore = firebase.firestore();

    try {
        await firebase
            .deleteFile(`bookings/${lead_booking.id}/${itin.documentNameRand}`)
            .then(function () {
                console.log("file del went ok");
            })
            .catch(function (error) {
                console.log(error);
            });
    } catch (error) {
        console.log("file delete error: ", error.message);
    }

    try {
        let bookingRef = firestore.collection("bookings").doc(lead_booking.id).collection(bookingType).doc(itin.id);

        await bookingRef.update({
            fileName: firebase.firestore.FieldValue.delete(),
            documentNameRand: firebase.firestore.FieldValue.delete(),
            fileUrl: firebase.firestore.FieldValue.delete(),
        });

        await firestore.collection("bookings").doc(lead_booking.id).collection(bookingType).doc(itin.id).update({ hasBG: false });

        toast.success("Background Image removed");
    } catch (error) {
        console.log("itin update error: ", error.message);
        toast.error("Something went wrong");
    }
}

export const saveBookingPackage = (values, lead_booking) => {
    return async (dispatch, getState, { getFirestore }) => {
        const firestore = getFirestore();

        try {
            if (values.booked_package_id) {
                let packid = values.booked_package_id.split("|");
                values.packageId = packid[0];
                values.quoteId = packid[1];
            }

            await firestore.update(`bookings/${lead_booking.id}`, values);
            toast.success("Package has been saved");
        } catch (error) {
            console.log(error.message);
            toast.error("Something went wrong");
        }
    };
};

export async function importIncludes(leadId, booking, type) {
    const firestore = firebase.firestore();

    try {
        // First get existing includes for sort order
        let existingIncludesRef = firestore.collection("bookings").doc(booking.id).collection(type);

        existingIncludesRef.get().then(function (existingDocs) {
            let sort_order = existingDocs.docs.length;

            let packagesRef = firestore.collection("quotes").doc(booking.quoteId).collection("packages").doc(booking.packageId);

            packagesRef
                .get()
                .then(function (doc) {
                    if (doc.exists) {
                        let pack = doc.data();

                        if (pack.includes && pack.includes.length > 0) {
                            Object.keys(pack.includes).forEach(function (key) {
                                pack.includes[key].itineraryType = "Itinerary";
                                pack.includes[key].itineraryClass = "Includes";
                                pack.includes[key].leadId = leadId;
                                pack.includes[key].bookingId = booking.id;

                                pack.includes[key].booking_ref = pack.includes[key].value;
                                delete pack.includes[key].value;

                                if (!pack.includes[key].date) {
                                    pack.includes[key].date = new Date(Date.now());
                                }

                                sort_order++;
                                pack.includes[key].sort_order = sort_order;

                                firestore.collection("bookings").doc(booking.id).collection(type).add(pack.includes[key]);

                                //Check if this booking should show on the App
                                if (type === "itinerary") {
                                    firestore.collection("bookings").doc(booking.id).update({ showOnApp: true });
                                }
                            });
                        }
                    } else {
                        // doc.data() will be undefined in this case
                        console.log("No such document!");
                    }
                })
                .catch(function (error) {
                    console.log("Error getting document:", error);
                });
        });

        toast.success("Includes have been imported");
    } catch (error) {
        console.log(error.message);
        toast.error("Something went wrong");
    }
}

export async function importExcludes(leadId, booking, type) {
    const firestore = firebase.firestore();

    try {
        //First get existing includes for sort order
        let existingExcludesRef = firestore.collection("bookings").doc(booking.id).collection(type);

        existingExcludesRef.get().then(function (existingDocs) {
            let sort_order = existingDocs.docs.length;

            let packagesRef = firestore.collection("quotes").doc(booking.quoteId).collection("packages").doc(booking.packageId);

            packagesRef
                .get()
                .then(function (doc) {
                    if (doc.exists) {
                        let pack = doc.data();

                        if (pack.excludes && pack.includes.length > 0) {
                            Object.keys(pack.excludes).forEach(function (key) {
                                pack.excludes[key].itineraryType = "Itinerary";
                                pack.excludes[key].itineraryClass = "Excludes";
                                pack.excludes[key].leadId = leadId;
                                pack.excludes[key].bookingId = booking.id;

                                pack.excludes[key].booking_ref = pack.excludes[key].value;
                                delete pack.excludes[key].value;

                                if (!pack.excludes[key].date) {
                                    pack.excludes[key].date = new Date(Date.now());
                                }

                                sort_order++;
                                pack.excludes[key].sort_order = sort_order;

                                firestore.collection("bookings").doc(booking.id).collection(type).add(pack.excludes[key]);

                                //Check if this booking should show on the App
                                if (type === "itinerary") {
                                    firestore.collection("bookings").doc(booking.id).update({ showOnApp: true });
                                }
                            });
                        }
                    } else {
                        // doc.data() will be undefined in this case
                        console.log("No such document!");
                    }
                })
                .catch(function (error) {
                    console.log("Error getting document:", error);
                });
        });

        toast.success("Excludes have been imported");
    } catch (error) {
        console.log(error.message);
        toast.error("Something went wrong");
    }
}

export async function importItinerary(leadId, booking, type) {
    const firestore = firebase.firestore();

    try {
        //First get existing includes for sort order
        let existingIncludesRef = firestore.collection("bookings").doc(booking.id).collection(type);

        existingIncludesRef.get().then(function (existingDocs) {
            let sort_order = existingDocs.docs.length;

            let packagesRef = firestore.collection("quotes").doc(booking.quoteId).collection("packages").doc(booking.packageId);

            packagesRef
                .get()
                .then(function (doc) {
                    if (doc.exists) {
                        let pack = doc.data();

                        if (pack.itinerary && pack.itinerary.length > 0) {
                            Object.keys(pack.itinerary).forEach(function (key) {
                                pack.itinerary[key].itineraryType = "Itinerary";
                                pack.itinerary[key].leadId = leadId;
                                pack.itinerary[key].bookingId = booking.id;

                                if (!pack.itinerary[key].date) {
                                    pack.itinerary[key].date = new Date(Date.now());
                                }

                                sort_order++;
                                pack.itinerary[key].sort_order = sort_order;

                                firestore.collection("bookings").doc(booking.id).collection(type).add(pack.itinerary[key]);

                                //Check if this booking should show on the App
                                if (type === "itinerary") {
                                    firestore.collection("bookings").doc(booking.id).update({ showOnApp: true });
                                }
                            });
                        }
                    } else {
                        // doc.data() will be undefined in this case
                        console.log("No such document!");
                    }
                })
                .catch(function (error) {
                    console.log("Error getting document:", error);
                });
        });

        toast.success("Itinerary have been imported");
    } catch (error) {
        console.log(error.message);
        toast.error("Something went wrong");
    }
}

export async function importFlights(leadId, booking, type) {
    const firestore = firebase.firestore();

    try {
        //First get existing includes for sort order
        let existingIncludesRef = firestore.collection("bookings").doc(booking.id).collection(type);

        existingIncludesRef.get().then(function (existingDocs) {
            let sort_order = existingDocs.docs.length;

            let packagesRef = firestore.collection("quotes").doc(booking.quoteId).collection("packages").doc(booking.packageId).collection("flights");

            packagesRef
                .get()
                .then(function (doc) {
                    if (doc.docs.length > 0) {
                        for (let i = 0; i < doc.docs.length; i++) {
                            let flight = doc.docs[i].data();

                            flight.date = flight.departDateTime;
                            flight.item = "From " + flight.airportFrom + " To " + flight.airportTo;
                            flight.booking_ref = flight.flight_nr;
                            flight.itineraryType = "Flight";
                            flight.leadId = leadId;
                            flight.bookingId = booking.id;

                            sort_order++;
                            flight.sort_order = sort_order;

                            firestore.collection("bookings").doc(booking.id).collection(type).add(flight);

                            //Check if this booking should show on the App
                            if (type === "itinerary") {
                                firestore.collection("bookings").doc(booking.id).update({ showOnApp: true });
                            }
                        }
                    } else {
                        // doc.data() will be undefined in this case
                        console.log("No such document!");
                    }
                })
                .catch(function (error) {
                    console.log("Error getting document:", error);
                });
        });

        toast.success("Flights have been imported");
    } catch (error) {
        console.log(error.message);
        toast.error("Something went wrong");
    }
}

export async function removeItinerary(booking, rowId, removeType) {
    const firestore = firebase.firestore();
    // const firebase = getFirebase();

    try {
        //Remove files
        if (removeType === "itinerary") {
            let filesSnap = await firestore.collection("bookings").doc(booking.id).collection("appFiles").where("rowId", "==", rowId).where("fileType", "==", removeType).get();

            if (filesSnap.docs.length > 0) {
                for (let i = 0; i < filesSnap.docs.length; i++) {
                    let file = filesSnap.docs[i].data();
                    let file_id = filesSnap.docs[i].id;

                    await firebase
                        .deleteFile(`bookings/${booking.id}/appFiles/${file.documentNameRand}`)
                        .then(function () {
                            console.log("file del went ok");
                        })
                        .catch(function (error) {
                            console.log(error);
                        });

                    await firestore.collection("bookings").doc(booking.id).collection("appFiles").doc(file_id).delete();
                }
            }
        }

        //Remove firestore user
        //console.log(booking, rowId, removeType);

        await firestore.collection("bookings").doc(booking.id).collection(removeType).doc(rowId).delete();

        //TODO: [IGO-5] if type is user remove user from firebase + users collection

        //Check if this booking should show on the App
        if (removeType === "itinerary") {
            let clientRef = firestore.collection("bookings").doc(booking.id).collection("itinerary");

            let clientSnap = await clientRef.get();

            let showApp = false;

            if (clientSnap.docs.length > 0) {
                showApp = true;
            }

            await firestore.collection("bookings").doc(booking.id).update({ showOnApp: showApp });
        }

        toast.success("Itinerary row removed");
    } catch (error) {
        console.log(error.message);
        toast.error("Something went wrong");
    }
}

export async function addItinerary(values, lead_booking, leadId, type) {
    const firestore = firebase.firestore();

    try {
        values.leadId = leadId;
        values.bookingId = lead_booking.id;
        values.itineraryType = "Itinerary";

        //Get the sort_order number to use
        let itinSnap = await firestore.collection("bookings").doc(lead_booking.id).collection(type).get();

        let itinCount = itinSnap.docs.length + 1;
        itinCount = parseInt(itinCount, 10);

        values.sort_order = itinCount;

        await firestore.collection("bookings").doc(lead_booking.id).collection(type).add(values);

        //Check if this booking should show on the App
        if (type === "itinerary") {
            let clientRef = firestore.collection("bookings").doc(lead_booking.id).collection("itinerary");
            let clientSnap = await clientRef.get();

            let showApp = false;

            if (clientSnap.docs.length > 0) {
                showApp = true;
            }

            await firestore.collection("bookings").doc(lead_booking.id).update({ showOnApp: showApp });
        }

        toast.success("Itinerary added");
    } catch (error) {
        console.log(error.message);
        toast.error("Something went wrong");
    }
}

export async function createClientFromLead(lead) {
    const firestore = firebase.firestore();

    try {
        //Check if client already exists
        let clientRef = firestore.collection("clients").where("client_email", "==", lead.client_email);
        let clientSnap = await clientRef.get();

        let clientId = "";

        if (clientSnap.docs.length > 0) {
            clientId = clientSnap.docs[0].id;
        } else {
            let newClient = {};

            newClient.client_name = lead.client_name ? lead.client_name : "";
            newClient.client_email = lead.client_email ? lead.client_email : "";
            newClient.client_phone = lead.client_phone ? lead.client_phone : "";
            newClient.createDate = new Date(Date.now());

            let client = await firestore.collection("clients").add(newClient);
            clientId = client.id;
        }

        await firestore.collection("leads").doc(lead.id).update({ clientId: clientId });

        //Update Client Status
        let quotesRef = firestore.collection("leads").where("clientId", "==", clientId);
        let quotesSnap = await quotesRef.get();

        let booked = 0;
        let quotes = 0;

        for (let i = 0; i < quotesSnap.docs.length; i++) {
            if (quotesSnap.docs[i].status === "Booked") {
                booked++;
            } else {
                quotes++;
            }
        }

        let client_status = "New Lead";
        if (booked === 0 && quotes > 1) {
            client_status = "Prospecitve Lead";
        }
        if (booked === 1) {
            client_status = "Booked Client";
        }
        if (booked > 1) {
            client_status = "Repeat Client";
        }

        await firestore.collection("clients").doc(clientId).update({ status: client_status });

        toast.success("Client created and linked to lead");
    } catch (error) {
        console.log(error.message);
        toast.error("Something went wrong");
    }
}

// export const addHotel = (values, lead_booking, leadId, type) => {
//     return async (dispatch, getState, { getFirestore }) => {
//         const firestore = getFirestore();

//         try {
//             values.leadId = leadId;
//             values.bookingId = lead_booking.id;
//             values.itineraryType = "Hotel";

//             await firestore.add(`bookings/${lead_booking.id}/${type}`, values);

//             toast.success("Hotel added");
//         } catch (error) {
//             console.log(error.message);
//             toast.error("Something went wrong");
//         }
//     };
// };

// export const saveHotel = (values, type) => {
//     return async (dispatch, getState, { getFirestore }) => {
//         const firestore = getFirestore();

//         try {
//             let leadId = values.leadId;
//             delete values.leadId;

//             let bookingId = values.bookingId;
//             delete values.bookingId;

//             let id = values.id;
//             delete values.id;

//             await firestore.update(`bookings/${bookingId}/${type}/${id}`, values);
//             toast.success("Hotel has been saved");
//         } catch (error) {
//             console.log(error.message);
//             toast.error("Something went wrong");
//         }
//     };
// };

export async function uploadBookItinFiles(file, itin, lead_booking, type, groupUser) {
    const imageName = cuid();
    const firestore = firebase.firestore();

    if (type === "User") {
        type = "users";
    }

    const path = `bookings/${lead_booking.id}/appFiles/`;
    const options = {
        name: cuid() + "-" + file.name,
    };

    let client_name = "";
    let clientId = "";

    if (groupUser && groupUser.length && groupUser.length > 0) {
        let splitUser = groupUser.split("|");
        clientId = splitUser[0];
        client_name = splitUser[1];
    } else if (type === "users") {
        client_name = itin.client_name;
    }

    try {
        // upload the file to firebase storage
        let downloadURL = await firebase.uploadFile(path, file, null, options).then((ret) => ret.uploadTaskSnapshot.ref.getDownloadURL());

        //Get current appFiles so that we can work out sort order
        let fileSnap = await firestore.collection("bookings").doc(lead_booking.id).collection("appFiles").get();

        let filesCount = fileSnap.docs.length + 1;
        filesCount = parseInt(filesCount, 10);

        //Add the file to Files Subcollection directly on booking for easy access by Apps Travel Docs
        await firestore
            .collection("bookings")
            .doc(lead_booking.id)
            .collection("appFiles")
            .add({
                creadeDate: new Date(Date.now()),
                downloadURL: downloadURL,
                fileName: file.name,
                documentNameRand: options.name,
                groupUserId: clientId,
                groupUserName: client_name,
                fileType: type,
                sort_order: filesCount,
                rowId: itin.id,
            });

        //Mark the Type as having files
        await firestore.collection("bookings").doc(lead_booking.id).collection(type).doc(itin.id).update({ hasFiles: true });

        //Mark Booking as having files
        await firestore.collection("bookings").doc(lead_booking.id).update({ hasFiles: true });

        //Mark the lead as having files
        if (itin.leadId) {
            await firestore.collection("leads").doc(itin.leadId).update({ hasFiles: true });
        }

        toast.success("File uploaded!");

        return true;
    } catch (e) {
        console.log(e);
        throw new Error("Problem uploading image");
    }
}

export async function deleteBookItinFile(file, itin, lead_booking, type) {
    console.log("Start file delete");

    if (type === "User") {
        type = "users";
    }

    try {
        // if (!file.fileType) {
        //     await firebase.deleteFile(`bookings/${lead_booking.id}/${type}/${itin.id}/${file.documentNameRand}`);

        //     await firestore.delete({
        //         collection: "bookings",
        //         doc: lead_booking.id,
        //         subcollections: [
        //             {
        //                 collection: type,
        //                 doc: itin.id,
        //                 subcollections: [
        //                     {
        //                         collection: "files",
        //                         doc: file.id
        //                     }
        //                 ]
        //             }
        //         ]
        //     });
        // } else {
        try {
            await firebase.deleteFile(`bookings/${lead_booking.id}/${type}/${itin.id}/${file.documentNameRand}`);
            console.log("File Deleted");
        } catch (e) {
            console.log(e);
        }

        try {
            await firebase.deleteFile(`bookings/${lead_booking.id}/appFiles/${file.documentNameRand}`);
            console.log("File Deleted 2");
        } catch (e) {
            console.log(e);
        }

        await firestore.collection("bookings").doc(lead_booking.id).collection("appFiles").doc(file.id).delete();

        //Now check if this item has any files left
        try {
            let filesSnap = await firestore.collection("bookings").doc(lead_booking.id).collection("appFiles").where("rowId", "==", itin.id).where("fileType", "==", type).get();

            console.log(filesSnap);

            if (filesSnap.empty === true) {
                await firestore.collection("bookings").doc(lead_booking.id).collection("appFiles").update({ hasFiles: false });
            }
        } catch (e) {
            console.log("error updating 1");
        }

        try {
            let itinSnap = await firestore.collection("bookings").doc(lead_booking.id).collection(type).where("hasFiles", "==", true).get();

            if (itinSnap.empty === true) {
                await firestore.collection("bookings").doc(lead_booking.id).collection(type).update({ hasFiles: false });

                if (itin.leadId) {
                    await firestore.collection("leads").doc(itin.leadId).update({ hasFiles: false });
                }
            }
        } catch (e) {
            console.log("error updating 2");
        }
    } catch (e) {
        console.log(e);
        throw new Error("Problem deleting the File");
    }
}

export async function deleteLead(lead_id) {
    const firestore = firebase.firestore();

    try {
        //Get quotes
        let quotesRef = firestore.collection("quotes").where("leadId", "==", lead_id.id);
        let quotesSnap = await quotesRef.get();

        //Delete quotes
        for (let i = 0; i < quotesSnap.docs.length; i++) {
            let quote_id = quotesSnap.docs[i].id;

            let packagesRef = firestore.collection("quotes").doc(quote_id).collection("packages");
            let packagesSnap = await packagesRef.get();

            for (let i = 0; i < packagesSnap.docs.length; i++) {
                let cur_pack_id = packagesSnap.docs[i].id;

                let imagesRef = firestore.collection("quotes").doc(quote_id).collection("packages").doc(cur_pack_id).collection("images");
                let imagesSnap = await imagesRef.get();

                for (let i = 0; i < imagesSnap.docs.length; i++) {
                    let cur_img = imagesSnap.docs[i].id;

                    await firestore.collection("images").doc(cur_img).delete();
                }

                let flightsRef = firestore.collection("quotes").doc(quote_id).collection("packages").doc(cur_pack_id).collection("flights");
                let flightsSnap = await flightsRef.get();

                for (let i = 0; i < flightsSnap.docs.length; i++) {
                    let flightId = flightsSnap.docs[i].id;

                    await firestore.collection("quotes").doc(quote_id).collection("packages").doc(cur_pack_id).collection("flights").doc(flightId).delete();
                }

                await firestore.collection("quotes").doc(quote_id).collection("packages").doc(cur_pack_id).delete();
            }
        }

        //Delete Bookings
        let bookingsRef = firestore.collection("bookings").where("leadId", "==", lead_id.id);
        let bookingsSnap = await bookingsRef.get();

        for (let i = 0; i < bookingsSnap.docs.length; i++) {
            let bookingId = bookingsSnap.docs[i].id;

            //Delete Confirmation
            let confirmationRef = firestore.collection("bookings").doc(bookingId).collection("confirmation");
            let confirmationSnap = await confirmationRef.get();

            for (let i = 0; i < confirmationSnap.docs.length; i++) {
                let confirmationId = confirmationSnap.docs[i].id;

                await firestore.collection("bookings").doc(bookingId).collection("confirmation").doc(confirmationId).delete();
            }

            //Delete Itinerary
            let bookingiRef = firestore.collection("bookings").doc(bookingId).collection("itinerary");
            let bookingiSnap = await bookingiRef.get();

            for (let i = 0; i < bookingiSnap.docs.length; i++) {
                let cur_itin_id = bookingiSnap.docs[i].id;
                //let itin = bookingiSnap.docs[i].data();

                //Remove files
                let bookingifRef = firestore.collection("bookings").doc(bookingId).collection("itinerary").doc(cur_itin_id).collection("files");
                let bookingifSnap = await bookingifRef.get();

                for (let i = 0; i < bookingifSnap.docs.length; i++) {
                    // let file = bookingifSnap.docs[i].data();
                    let file_id = bookingifSnap.docs[i].id;

                    // await firebase.deleteFile(`bookings/${bookingId}/itinerary/${cur_itin_id}/${file.documentNameRand}`);
                    console.log("removed file from storage");

                    await firestore.collection("bookings").doc(bookingId).collection("itinerary").doc(cur_itin_id).collection("files").doc(file_id).delete();
                    console.log("removed files collection");
                }

                await firestore.collection("bookings").doc(bookingId).collection("itinerary").doc(cur_itin_id).delete();
                console.log("removed itinerary collection");
            }

            //Delete Users
            let bookingiuRef = firestore.collection("bookings").doc(bookingId).collection("users");
            let bookingiuSnap = await bookingiuRef.get();

            for (let i = 0; i < bookingiuSnap.docs.length; i++) {
                let user_id = bookingiuSnap.docs[i].id;

                await firestore.collection("bookings").doc(bookingId).collection("users").doc(user_id).delete();
                console.log("removed users collection");
            }

            //Delete Payments
            let paymentsRef = firestore.collection("bookings").doc(bookingId).collection("payments");
            let paymentsSnap = await paymentsRef.get();

            for (let i = 0; i < paymentsSnap.docs.length; i++) {
                let paymentId = paymentsSnap.docs[i].id;

                await firestore.collection("bookings").doc(bookingId).collection("payments").doc(paymentId).delete();
            }

            await firestore.collection("bookings").doc(bookingId).delete();
        }

        //Delete Comments
        let commentsRef = firestore.collection("leads").doc(lead_id.id).collection("comments");
        let commentsSnap = await commentsRef.get();

        for (let i = 0; i < commentsSnap.docs.length; i++) {
            let commentId = commentsSnap.docs[i].id;
            await firestore.collection("leads").doc(lead_id.id).collection("comments").doc(commentId).delete();
        }

        //Delete Follow Ups
        let followupsRef = firestore.collection("leads").doc(lead_id.id).collection("followups");
        let followupsSnap = await followupsRef.get();

        for (let i = 0; i < followupsSnap.docs.length; i++) {
            let followupId = followupsSnap.docs[i].id;
            await firestore.collection("leads").doc(lead_id.id).collection("followups").doc(followupId).delete();
        }

        //Delete Logs
        let logsRef = firestore.collection("logs").where("table", "==", "leads").where("rowId", "==", lead_id.id);
        let logsSnap = await logsRef.get();

        for (let i = 0; i < logsSnap.docs.length; i++) {
            let logId = logsSnap.docs[i].id;
            await firestore.collection("logs").doc(logId).delete();
        }

        //Delete Lead
        await firestore.collection("leads").doc(lead_id.id).delete();

        toast.success("Lead has been deleted");
    } catch (error) {
        console.log(error);
        toast.error("Something went wrong");
    }
}

export async function deleteBooking(lead, lead_booking) {
    const firestore = firebase.firestore();

    try {
        let bookingiRef = firestore.collection("bookings").doc(lead_booking.id).collection("itinerary");
        let bookingiSnap = await bookingiRef.get();

        if (bookingiSnap.docs.length > 0) {
            for (let i = 0; i < bookingiSnap.docs.length; i++) {
                let cur_itin_id = bookingiSnap.docs[i].id;
                //let itin = bookingiSnap.docs[i].data();

                //Remove files
                let bookingifRef = firestore.collection("bookings").doc(lead_booking.id).collection("itinerary").doc(cur_itin_id).collection("files");
                let bookingifSnap = await bookingifRef.get();

                if (bookingifSnap.docs.length > 0) {
                    for (let i = 0; i < bookingifSnap.docs.length; i++) {
                        let file = bookingifSnap.docs[i].data();
                        let file_id = bookingifSnap.docs[i].id;

                        // var deleteFileRef = storageRef.child(`bookings/${lead_booking.id}/itinerary/${cur_itin_id}/${file.documentNameRand}`);
                        // await deleteFileRef.delete().then(() => {console.log("removed file from storage")}).catch((error) => {console.log(error)});
                        await firebase.deleteFile(`bookings/${lead_booking.id}/itinerary/${cur_itin_id}/${file.documentNameRand}`);
                        console.log("removed file from storage");

                        await firestore.collection("bookings").doc(lead_booking.id).collection("itinerary").doc(cur_itin_id).collection("files").doc(file_id).delete();
                        console.log("removed files collection");
                    }
                }

                await firestore.collection("bookings").doc(lead_booking.id).collection("itinerary").doc(cur_itin_id).delete();
                console.log("removed files itinerary collection");
            }
        }

        let bookingpRef = firestore.collection("bookings").doc(lead_booking.id).collection("payments");
        let bookingpSnap = await bookingpRef.get();

        if (bookingpSnap.docs.length > 0) {
            for (let i = 0; i < bookingpSnap.docs.length; i++) {
                let cur_pay = bookingpSnap.docs[i].id;

                await firestore.collection("bookings").doc(lead_booking.id).collection("payments").doc(cur_pay).delete();
            }
        }

        await firestore.collection("bookings").doc(lead_booking.id).delete();

        toast.success("Booking has been deleted");
    } catch (error) {
        console.log(error);
        toast.error("Something went wrong");
    }
}

// export const addBookingUser = (values, lead_booking, leadId, type) => {
//     return async (dispatch, getState, { getFirestore }) => {
//         const firestore = getFirestore();

//         try {
//             values.leadId = leadId;
//             values.bookingId = lead_booking.id;

//             let newUser = await firestore.add(`bookings/${lead_booking.id}/users`, values);

//             console.log(newUser.id);

//             //Now add to Firebase users

//             //clientId
//             //bookingUserId

//             toast.success("User added");
//         } catch (error) {
//             console.log(error.message);
//             toast.error("Something went wrong");
//         }
//     };
// };

export async function addBookingUser(values, lead_booking, lead) {
    const firestore = firebase.firestore();

    // console.log(lead_booking);
    // console.log(values);

    try {
        values.leadId = lead.id;
        values.bookingId = lead_booking.id;
        let newUser = await firestore.collection("bookings").doc(lead_booking.id).collection("users").add(values);

        let thisClient = await firestore.collection("clients").doc(lead_booking.clientId).get();

        if (thisClient.data().client_email === values.client_email && thisClient.data().appPassword === undefined) {
            let clientUser = firebase.app().functions("europe-west2").httpsCallable("registerClientUser");

            await clientUser({ client_email: values.client_email, password: values.password })
                .then(async (result) => {
                    console.log(result);

                    if (!result.data.uid) {
                        console.log(result.data);
                        toast.error(result.data);
                    } else {
                        //Update Client doc
                        await firestore.collection("clients").doc(lead_booking.clientId).update({ appUserUid: result.data.uid, appPassword: values.password });

                        console.log("values.password");

                        //Add User doc
                        await firestore
                            .collection("users")
                            .doc(result.data.uid)
                            .set({
                                displayName: thisClient.data().client_name,
                                createdAt: new Date(Date.now()),
                                clientId: thisClient.id,
                                email: thisClient.data().client_email,
                                id: result.data.uid,
                            });
                    }

                    return true;
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            if (!values.userType) {
                values.userType = null;
            }

            let clientUser = firebase.app().functions("europe-west2").httpsCallable("registerClientUser");

            await clientUser({ client_email: values.client_email, password: values.password })
                .then(async (result) => {
                    console.log(result);

                    if (!result.data.uid) {
                        console.log(result.data);
                        toast.error(result.data);
                    } else {
                        //Add User doc
                        await firestore
                            .collection("users")
                            .doc(result.data.uid)
                            .set(
                                {
                                    displayName: values.client_name,
                                    createdAt: new Date(Date.now()),
                                    email: values.client_email,
                                    id: result.data.uid,
                                    bookingUserId: newUser.id,
                                    userType: values.userType,
                                    clientId: thisClient.id,
                                },
                                { merge: true }
                            );

                        //Link User to client
                        firestore.collection("bookings").doc(lead_booking.id).collection("users").doc(newUser.id).set({ appUserUid: result.data.uid }, { merge: true });
                    }

                    return true;
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    } catch (error) {
        console.log(error);
        toast.error("Something went wrong");
    }
}

export async function saveBookingUser(values, bookingType) {
    const firestore = firebase.firestore();

    if (values.uploadFile) {
        delete values.uploadFile;
    }

    try {
        await firestore.collection("bookings").doc(values.bookingId).collection("users").doc(values.id).update(values);

        toast.success("Itinerary has been saved");
    } catch (error) {
        console.log(error.message);
        toast.error("Something went wrong");
    }
}

export const updateOldFiles = () => {
    return async (dispatch, getState, { getFirestore }) => {
        const firestore = getFirestore();

        console.log("Start updateOldFiles");

        try {
            let bookingSnap = await firestore.collection("bookings").get();

            console.log("Booking Count " + bookingSnap.docs.length);

            if (bookingSnap.docs.length > 0) {
                for (let i = 0; i < bookingSnap.docs.length; i++) {
                    let bookingId = bookingSnap.docs[i].id;

                    let itinerarySnap = await firestore.collection("bookings").doc(bookingId).collection("itinerary").get();

                    console.log("Itin Count " + itinerarySnap.docs.length);

                    if (itinerarySnap.docs.length > 0) {
                        let fileSnap = await firestore.collection("bookings").doc(bookingId).collection("appFiles").get();

                        let filesCount = fileSnap.docs.length + 1;
                        filesCount = parseInt(filesCount, 10);

                        for (let i = 0; i < itinerarySnap.docs.length; i++) {
                            let itinId = itinerarySnap.docs[i].id;

                            let filesSnap = await firestore.collection("bookings").doc(bookingId).collection("itinerary").doc(itinId).collection("files").get();

                            console.log("File Count " + filesSnap.docs.length);

                            if (filesSnap.docs.length > 0) {
                                for (let i = 0; i < filesSnap.docs.length; i++) {
                                    let fileId = filesSnap.docs[i].id;
                                    let fileData = filesSnap.docs[i].data();

                                    fileData.fileType = "itinerary";
                                    fileData.rowId = itinId;
                                    fileData.sort_order = filesCount + i;
                                    fileData.groupUserId = "";
                                    fileData.groupUserName = "";

                                    //Move the file to new collection
                                    await firestore.add(
                                        {
                                            collection: "bookings",
                                            doc: bookingId,
                                            subcollections: [
                                                {
                                                    collection: "appFiles",
                                                },
                                            ],
                                        },
                                        fileData
                                    );

                                    //Delete the old files
                                    await firestore.delete(`bookings/${bookingId}/itinerary/${itinId}/files/${fileId}`);
                                }
                            }
                        }
                    }
                }
            }

            toast.success("Done!");
        } catch (error) {
            console.log(error.message);
            toast.error("Something went wrong");
        }
    };
};

export async function saveSortOrder(values, type, lead_booking) {
    const firestore = firebase.firestore();

    try {
        let batch = firestore.batch();

        let sort_order = 0;
        Object.keys(values).forEach(function (key) {
            sort_order++;
            values[key].sort_order = sort_order;

            let bookItinDocRef = firestore.collection("bookings").doc(lead_booking.id).collection(type).doc(values[key].id);

            batch.update(bookItinDocRef, values[key]);
        });

        await batch.commit();
    } catch (error) {
        console.log(error.message);
        toast.error("Something went wrong");
    }
}

export async function uploadFile(values) {
    const firestore = firebase.firestore();

    values.createDate = new Date(Date.now());
    console.log(values);

    try {
        //Upload files
        const file = values.uploadFile;
        delete values.uploadFile;

        const path = `leads/${values.leadId}`;
        const options = {
            name: cuid() + "-" + file.name,
        };

        let uploadedFile = await firebase.uploadFile(path, file, null, options).then((ret) => ret.uploadTaskSnapshot.ref.getDownloadURL());

        values.fileName = file.name;
        values.fileUrl = uploadedFile;
        values.documentNameRand = options.name;

        //Create file in sub collection
        await firestore.collection("leads").doc(values.leadId).collection("files").add(values);

        toast.success("File has been uploaded");
    } catch (error) {
        console.log(error.message);
        toast.error("Something went wrong");
    }
}

export async function getAssignedLeads(userId, status, email) {
    const firestore = firebase.firestore();

    try {
        let leads = [];

        let query = firestore.collection("leads");

        if (typeof status !== "undefined" && status !== "All" && status !== "") {
            query = query.where("status", "==", status);
        } else {
            query = query.where("status", "!=", "Unassigned");
        }

        if (typeof userId !== "undefined" && userId !== "All" && userId !== "") {
            query = query.where("userUid", "==", userId);
        }

        if (typeof email !== "undefined" && email !== "") {
            query = query.where("client_email", "==", email);
        }

        await query
            .get()
            .then(function (querySnapshot) {
                querySnapshot.forEach(function (doc) {
                    if (doc.exists) {
                        let new_data = doc.data();
                        new_data.id = doc.id;

                        if (typeof status !== "undefined" && ((status === "All" && new_data.status !== "Archived") || status !== "All")) {
                            // if(new_data.createDate && new_data.createDate.toDate() >= last_date){
                            leads.push(new_data);
                            // }
                        }
                    }
                });
            })
            .catch(function (error) {
                console.log("Error getting timetrack: ", error);
            });

        return leads;
    } catch (error) {
        console.log(error);
        toast.error("Something went wrong");
    }
}

export const getUnassignedLeads = async () => {
    let leads = [];

    let leadsRef = await db.collection("leads").where("status", "==", "Unassigned").orderBy("createDate", "desc").get();

    for (let i = 0; i < leadsRef.docs.length; i++) {
        let new_lead_data = leadsRef.docs[i].data();
        new_lead_data.id = leadsRef.docs[i].id;

        new_lead_data.dupes = 0;

        let date_dupes = new Date(Date.now());
        date_dupes = subYears(date_dupes, 1);

        if (new_lead_data.client_email && new_lead_data.client_email !== "") {
            let dupesRef = await db.collection("leads").where("client_email", "==", new_lead_data.client_email).where("createDate", ">", date_dupes).get();
            let dupes = dupesRef.docs.length > 0 ? dupesRef.docs.length - 1 : 0;
            new_lead_data.dupes = dupes;
        }

        leads.push(new_lead_data);
    }

    return leads;
};

export const getConsultantUser = async () => {
    let usersRef = await db.collection("users").where("isConsultant", "==", true).get();
    return usersRef;
};

export async function getLeadEmail(leadId) {
    return await db.collection("email_scheduled").where("leadId", "==", leadId).get();
}

export async function getCurrLead(leadId) {
    return await db.collection("leads").doc(leadId).get();
}
export async function getCurrLeadComments(leadId) {
    return await db.collection("leads").doc(leadId).collection("comments").get();
}
export async function getCurrLeadFiles(leadId) {
    return await db.collection("leads").doc(leadId).collection("files").get();
}
export async function getCurrLeadFollow(leadId) {
    return await db.collection("leads").doc(leadId).collection("followups").get();
}
export async function getCurrLeadQuote(leadId) {
    return await db.collection("quotes").where("leadId", "==", leadId).get();
}
export async function getCurrLeadBookings(leadId) {
    return await db.collection("bookings").where("leadId", "==", leadId).get();
}
export async function getCurrLeadLogs(leadId) {
    return await db.collection("logs").where("rowId", "==", leadId).where("table", "==", "leads").get();
}

export async function getLeadUn(email) {
    let emailData = "";
    if (email && email !== "") {
        emailData = email;
    } else {
        emailData = "None";
    }
    return await db.collection("leads").where("client_email", "==", emailData).get();
}
export async function getCommentsUn(email) {
    let emailData = "";
    if (email && email !== "") {
        emailData = email;
    } else {
        emailData = "None";
    }

    return await db.collection("leads").where("client_email", "==", emailData).get();
}

export async function getGroupList(lead_booking) {
    return await db.collection("bookings").doc(lead_booking.id).collection("users").get();
}
export async function getItinFiles(lead_booking, itin) {
    return await db.collection("bookings").doc(lead_booking.id).collection("appFiles").where("rowId", "==", itin.id).where("fileType", "==", "itinerary").get();
}
export async function getItinFilesOld(lead_booking, itin) {
    return await db.collection("bookings").doc(lead_booking.id).collection("itinerary").doc(itin.id).collection("files").get();
}

export async function updateSource(id) {
    await firestore.collection("leads").doc(id).update({ enquiry_source: "Facebook" });
}

export const getLogs = async (leadId) => {
    let emailRef = await firestore.collection("email").where("leadId", "==", leadId).get();

    let emails = [];

    for (let i = 0; i < emailRef.docs.length; i++) {
        let id = emailRef.docs[i].id;
        let email_data = emailRef.docs[i].data();

        let logsRef = await firestore.collection("email_logs").where("emailId", "==", id).get();

        email_data.logs = [];

        for (let x = 0; x < logsRef.docs.length; x++) {
            if (logsRef.docs[x] && logsRef.docs[x].exists) {
                email_data.logs.push(logsRef.docs[x].data());
            }
        }

        emails.push(email_data);
    }

    console.log(emails);

    return emails;
};

export const getMailgunData = (emailId) => {
    return firestore.collection("email_logs").where("email_id", "==", emailId).get();
};

export const getLeaduserFilesOld = (bookingId, user) => {
    let id = "None";
    if (user !== undefined) {
        id = user.id;
    }

    return firestore.collection("bookings").doc(bookingId).collection("users").doc(id).collection("files").get();
};

export const getLeaduserFiles = (bookingId, user) => {
    let id = "None";
    if (user !== undefined) {
        id = user.id;
    }
    return firestore.collection("bookings").doc(bookingId).collection("appFiles").where("rowId", "==", id).where("fileType", "==", "users").get();
};
