import React from "react";
import { Header, Segment, Grid } from "semantic-ui-react";

export default function LeadsDetailedData(props){
    let lead = props.lead;
    let cfdb = [];

    let includes = [
        "travel_from_date",
        "travel_to_date",
        "dates_flexible",
        "adults",
        "children",
        "child_1",
        "child_2",
        "child_3",
        "child_4",
        "child_5",
        "child_6",
        "departing_from",
        "estimated_budget",
        "anything_else",
        "client_name",
        "client_surname",
        "client_email",
        "client_phone",
        "form_title",
        "where_go",
        "children_age",
        "privacy_consent",
        "entry_date",
        "source_url",
        "your_message",
        "wh_fb_campaign",
        "wh_query_string",
        "wh_referer_first",
        "wh_referer_last",
        "wh_referer_time",
        "message",
        "select_an_added_extra",
    ];

    if (lead.form_name !== "Woocommerce" && lead.form_name !== "Quote Basket" && lead.form_name !== "Unsure") {
        let leadDate = lead.cfdb;

        if (leadDate !== undefined) {
            if (leadDate[0] !== undefined && leadDate[0].field_name === "original_data") {
                let originalArr = JSON.parse(leadDate[0].field_value);

                cfdb.push({ key: "Travel date (from)", value: originalArr[14] });
                cfdb.push({ key: "Travel date (to)", value: originalArr[15] });
                cfdb.push({ key: "Flexible dates", value: originalArr[13] });

                cfdb.push({ key: "Departing from", value: originalArr[21] });
                cfdb.push({ key: "Estimated budget", value: originalArr[22] });

                cfdb.push({ key: "First name", value: originalArr[1] });
                cfdb.push({ key: "Last Name", value: originalArr[8] });
                cfdb.push({ key: "Email Address", value: originalArr[3] });
                cfdb.push({ key: "Contact phone", value: originalArr[9] });
            } else {
                var i;
                for (i = 0; i < includes.length; i++) {
                    Object.keys(leadDate).forEach(function (key) {
                        if (key == includes[i]) {
                            cfdb.push({
                                key: key,
                                value: lead.cfdb[key],
                            });
                        }
                    });
                }
            }
        }
    } else if (lead.form_name === "Woocommerce" || lead.form_name === "Quote Basket") {
        //Per website Form
        cfdb.push({ key: "Travel date (from)", value: lead.cfdb.meta_data ? lead.cfdb.meta_data[0].value : "" });
        cfdb.push({ key: "Travel date (to)", value: lead.cfdb.meta_data ? lead.cfdb.meta_data[1].value : "" });
        cfdb.push({ key: "Flexible dates", value: lead.cfdb.meta_data ? lead.cfdb.meta_data[2].value : "" });
        cfdb.push({ key: "Adults", value: lead.cfdb.meta_data ? lead.cfdb.meta_data[3].value : "" });

        //check if this is for data from before or after we added child ages
        if (lead.cfdb.meta_data && lead.cfdb.meta_data[5].key === "_billing_infants") {
            cfdb.push({ key: "Children (2-17)", value: lead.cfdb.meta_data ? lead.cfdb.meta_data[4].value : "" });
            cfdb.push({ key: "Infants (under 2)", value: lead.cfdb.meta_data ? lead.cfdb.meta_data[5].value : "" });
            cfdb.push({ key: "Departing from", value: lead.cfdb.meta_data ? lead.cfdb.meta_data[7].value : "" });
        } else {
            cfdb.push({ key: "Children", value: lead.cfdb.meta_data ? lead.cfdb.meta_data[4].value : "" });
            cfdb.push({ key: "Child 1 Age", value: lead.cfdb.meta_data ? lead.cfdb.meta_data[5].value : "" });
            cfdb.push({ key: "Child 2 Age", value: lead.cfdb.meta_data ? lead.cfdb.meta_data[6].value : "" });
            cfdb.push({ key: "Child 3 Age", value: lead.cfdb.meta_data ? lead.cfdb.meta_data[7].value : "" });
            cfdb.push({ key: "Child 4 Age", value: lead.cfdb.meta_data ? lead.cfdb.meta_data[8].value : "" });
            cfdb.push({ key: "Child 5 Age", value: lead.cfdb.meta_data ? lead.cfdb.meta_data[9].value : "" });
            cfdb.push({ key: "Child 6 Age", value: lead.cfdb.meta_data ? lead.cfdb.meta_data[10].value : "" });
            cfdb.push({ key: "Departing from", value: lead.cfdb.meta_data ? lead.cfdb.meta_data[11].value : "" });
        }

        // cfdb.push({ key: "Estimated budget", value: "MISSING" });
        // cfdb.push({ key: "Additional info", value: "MISSING" });

        cfdb.push({ key: "First name", value: lead.cfdb.billing ? lead.cfdb.billing.first_name : "" });
        cfdb.push({ key: "Last Name", value: lead.cfdb.billing ? lead.cfdb.billing.last_name : "" });
        cfdb.push({ key: "Email Address", value: lead.cfdb.billing ? lead.cfdb.billing.email : "" });
        cfdb.push({ key: "Contact phone", value: lead.cfdb.billing ? lead.cfdb.billing.phone : "" });

        //Session tracking data
        cfdb.push({ key: "wh_referer_first", value: lead.cfdb.billing ? lead.cfdb.billing.wh_referer_first : "" });
        cfdb.push({ key: "wh_referer_last", value: lead.cfdb.billing ? lead.cfdb.billing.wh_referer_last : "" });
        cfdb.push({ key: "wh_query_string", value: lead.cfdb.billing ? lead.cfdb.billing.wh_query_string : "" });
        cfdb.push({ key: "wh_referer_time", value: lead.cfdb.billing ? lead.cfdb.billing.wh_referer_time : "" });
        cfdb.push({ key: "wh_fb_campaign", value: lead.cfdb.billing ? lead.cfdb.billing.wh_fb_campaign : "" });

        if (lead.cfdb.line_items) {
            Object.keys(lead.cfdb.line_items).forEach(function (key) {
                cfdb.push({
                    key: "name",
                    value: lead.cfdb.line_items[key].name,
                });
                cfdb.push({
                    key: "total",
                    value: lead.cfdb.line_items[key].subtotal,
                });
            });
        }
    }

    return(
        <div style={{ marginBottom: 20, marginTop: 20 }}>
            <Segment attached="top">
                <Header as="h3">Lead Details</Header>
            </Segment>

            <Segment attached>
                {cfdb && cfdb.length > 0 && (
                    <Grid>
                        {cfdb.map((c) => (
                            <Grid.Row columns={2} key={c.key + c.value}>
                                <Grid.Column>
                                    <strong>{c.key}</strong>
                                </Grid.Column>
                                <Grid.Column>{c.value}</Grid.Column>
                            </Grid.Row>
                        ))}
                    </Grid>
                )}
            </Segment>
        </div>
    )
}