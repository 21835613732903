import React from "react";
import { Modal } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { Button, Grid } from "semantic-ui-react";
import { createClient } from "../clients/clientActions";
import { Formik } from "formik";
import MyTextInput from "../../app/common/form/MyTextInput";
import { closeModal } from "../../app/common/modals/modalReducer";
import { useSelector } from "react-redux";

export default function ClientCreateModal() {
    const dispatch = useDispatch();

    const { currentUserProfile } = useSelector((state) => state.profile);

    return (
        <>
            <Modal size="tiny" open={true} onClose={() => dispatch(closeModal())}>
                <Modal.Header>Client Details</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <Formik
                            initialValues={{ client_name: "", client_email: "", client_phone: "" }}
                            validate={(values) => {
                                const error = {};
                                if (!values.client_name) {
                                    error.client_name = "Client Name is required";
                                }
                                if (!values.client_email) {
                                    error.client_email = "Client Email is required";
                                }
                                return error;
                            }}
                            onSubmit={async (values, { setSubmitting }) => {
                                let profile = currentUserProfile;
                                let res = await createClient(values, profile);

                                if (res === true) {
                                    setSubmitting(false);
                                    dispatch(closeModal());
                                }
                                setSubmitting(false);
                            }}
                        >
                            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                                <form onSubmit={handleSubmit} className="ui form">
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column>
                                                <MyTextInput label="Client Name*" name="client_name" type="text" placeholder="Client Name" />
                                                <MyTextInput label="Email*" name="client_email" type="email" placeholder="Client Email" />
                                                <MyTextInput label="Client Phone" name="client_phone" type="text" placeholder="Client Phone" />
                                            </Grid.Column>
                                        </Grid.Row>

                                        <Grid.Row>
                                            <Grid.Column textAlign="right">
                                                <Button type="submit" disabled={isSubmitting} loading={isSubmitting} color="orange" content="Add Client" />
                                                <Button type="button" disabled={isSubmitting} onClick={() => dispatch(closeModal())}>
                                                    Cancel
                                                </Button>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </form>
                            )}
                        </Formik>
                    </Modal.Description>
                </Modal.Content>
            </Modal>
        </>
    );
}
