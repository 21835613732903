import React, {Component} from 'react';
import { connect } from 'react-redux';
import { Table } from "semantic-ui-react";
import {firestoreConnect} from "react-redux-firebase";
import format from 'date-fns/format';
import { openModal } from "../../modals/modalActions";

const mapState = (state, ownProps) => {
    return {
        auth: state.firebase.auth,
        email_scheduled: state.firestore.ordered.email_scheduled
    }
};

const actions = {
    openModal
};

class RemindersDashboardPage extends Component {
    handleClick(row) {
        this.props.history.push('/leads/edit/' + row);
    };

    render() {
        const { email_scheduled } = this.props;


        return (
            <div>
                {email_scheduled && email_scheduled.length > 0 &&
                    <Table celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Action Type</Table.HeaderCell>
                                <Table.HeaderCell>Action Date</Table.HeaderCell>
                                <Table.HeaderCell>Email Client</Table.HeaderCell>
                                <Table.HeaderCell>Status</Table.HeaderCell>
                                <Table.HeaderCell>Message</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {email_scheduled && email_scheduled.length > 0 && email_scheduled.map((es) => (
                                <Table.Row key={es.leadId} onClick={() => {this.handleClick(es.leadId)}} style={{cursor: 'pointer'}}>
                                    <Table.Cell>{es.action_type}</Table.Cell>
                                    <Table.Cell>{format(es.action_dt.toDate(), 'dd MMMM yyyy')}</Table.Cell>
                                    <Table.Cell>{es.send_client_email && es.send_client_email === true ? "Yes" : "No"}</Table.Cell>
                                    <Table.Cell>{es.status}</Table.Cell>
                                    <Table.Cell>{es.message}</Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                }
            </div>
        );
    }
}

export default connect(mapState, actions)(
    firestoreConnect([
        {
            collection: 'email_scheduled',
            where: ['action_dt', '<=', new Date(Date.now())]
        }
    ])(RemindersDashboardPage)
);