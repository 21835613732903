import React from "react";
import { useDispatch } from "react-redux";
import { Button, Grid, Image } from "semantic-ui-react";
import { saveItinerary, removeBookingItinImage } from "../leadActions";
import { Formik } from "formik";
import { closeModal } from "../../../app/common/modals/modalReducer";
import MySelectInput from "../../../app/common/form/MySelectInput";

export default function LeadBookingItinBGForm(props) {
    let type = props.type;
    let itin = props.itin;
    let bookingType = props.bookingType;
    let lead_booking = props.lead_booking;
    let bookingId = props.bookingId;
    const dispatch = useDispatch();

    console.log(props)

    // var parts = window.location.href.split("/");
    // var thirdLastSegment = parts[parts.length - 2];

    const colours = [
        { key: "white", text: "White", value: "white" },
        { key: "black", text: "Black", value: "black" },
    ];

    async function deleteImage() {
        await removeBookingItinImage(itin, lead_booking, bookingType);
    }

    let initialV = { textColour: "", uploadFile: "" }

    if(props.itin !== undefined){
        initialV = { textColour: props.itin.textColour, uploadFile: "" }
    }

    return (
        <>
            <Formik
                initialValues={initialV}
                enableReinitialize
                validate={(values) => {
                    const errors = {};
                    return errors;
                }}
                onSubmit={async (values, { setSubmitting }) => {
                    values.bookingId = bookingId;
                    values.id = itin.id;

                    console.log(values)

                    await saveItinerary(values, props.bookingType);
                    setSubmitting(false);

                    dispatch(closeModal());
                }}
            >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                    <form onSubmit={handleSubmit} className="ui form">
                        <Grid>
                            <Grid.Row>
                                <Grid.Column>
                                    {itin.hasBG === true && itin.fileUrl && itin.fileUrl.length > 0 && (
                                        <div>
                                            <Image size="medium" style={{ marginBottom: 20 }} src={itin.fileUrl} />
                                            <Button disabled={isSubmitting} loading={isSubmitting} as="a" color="orange" content="Delete Image" onClick={() => deleteImage()} />
                                        </div>
                                    )}
                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Row>
                                <Grid.Column>
                                    <MySelectInput
                                        labelTop="Text Colour"
                                        name="textColour"
                                        options={colours}
                                        value={values.textColour}
                                        onChange={(field, value) => setFieldValue("textColour", value.value)}
                                        placeholder="Choose a colour"
                                    />
                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Row>
                                <Grid.Column>
                                    <label>
                                        <strong>Upload Custom Background</strong>
                                    </label>

                                    <input
                                        id="uploadFile"
                                        name="uploadFile"
                                        type="file"
                                        onChange={(event) => {
                                            setFieldValue("uploadFile", event.currentTarget.files[0]);
                                        }}
                                    />
                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Row>
                                <Grid.Column>
                                    <Button style={{ marginTop: 20 }} disabled={isSubmitting} loading={isSubmitting} size="large" color="orange" content={type} />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </form>
                )}
            </Formik>
        </>
    );
}
