import { toastr } from "react-redux-toastr";
import format from "date-fns/format";
import firebase from "../../app/config/firebase";
import { toast } from "react-toastify";

const firestore = firebase.firestore();

export async function dashhboardQuery(values, type, lead_booking) {
    try {
        let leads = [];
        let users = [];

        let yearNow = format(Date.now(), "yyyy");
        let monthNow = format(Date.now(), "M");
        let fromDateFilter = new Date(yearNow + "-" + monthNow + "-01");

        let leadsRef = firestore.collection("leads").where("assignDate", ">=", fromDateFilter);
        let leadsSnap = await leadsRef.get();

        for (let i = 0; i < leadsSnap.docs.length; i++) {
            leads.push(leadsSnap.docs[i].data());
        }

        let usersRef = firestore.collection("users").where("isConsultant", "==", true).where("showDashboard", "==", true); /*.where("assignDate", ">=", fromDateFilter)*/
        let usersSnap = await usersRef.get();

        for (let i = 0; i < usersSnap.docs.length; i++) {
            let leads_count = 0;
            let user_id = usersSnap.docs[i].id;
            let user_data = usersSnap.docs[i].data();

            for (let x = 0; x < leads.length; x++) {
                if (leads[x].userUid === user_id) {
                    leads_count++;
                }
            }

            user_data.leads_count = leads_count;

            users.push(user_data);
        }

        //Now get leads by source
        let leads_by_source = [];

        for (let i = 0; i < leads.length; i++) {
            if (leads[i].enquiry_source !== "undefined" && leads[i].enquiry_source !== undefined) {
                let source = leads[i].enquiry_source;
                let data = {
                    angle: 1,
                    //radius: 1,
                    label: source,
                };

                if (!leads_by_source[source]) {
                    leads_by_source[source] = data;
                } else {
                    leads_by_source[source]["angle"]++;
                }
            }
        }

        return { users, leads_by_source };
    } catch (error) {
        console.log(error.message);
        toast.error("Something went wrong");
    }
}
