import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Button, Grid, Icon, List, Confirm } from "semantic-ui-react";
import { addBookingUser, saveBookingUser, uploadBookItinFiles, deleteBookItinFile, getLeaduserFilesOld, getLeaduserFiles } from "../leadActions";
import { Formik } from "formik";
import MyTextInput from "../../../app/common/form/MyTextInput";
import MySelectInput from "../../../app/common/form/MySelectInput";
import { closeModal } from "../../../app/common/modals/modalReducer";

import firebase from "../../../app/config/firebase";
const firestore = firebase.firestore();

export default function LeadBookingUserForm(props) {
    // console.log(props)
    let type = props.type;
    let lead_booking = props.lead_booking;
    let bookingId = props.bookingId;
    let bookingType = props.bookingType;
    let lead = props.lead;
    const [open, setOpen] = useState(false);
    const [file, setFile] = useState(null);
    const [lead_user_files_old, set_lead_user_files_old] = useState([]);
    const [lead_user_files, set_lead_user_files] = useState([]);
    const dispatch = useDispatch();
    var parts = window.location.href.split("/");
    var lastSegment = parts.pop() || parts.pop();
    var secondLastSegment = parts[parts.length - 1];
    const [user, setUser] = useState({});

    useEffect(() => {
        if(props.user !== undefined){
            setUser(props.user)
        }
        if(type !== "Add" && props.user !== undefined){
            
            const unsubscribe = firestore
                .collection("bookings")
                .doc(bookingId)
                .collection("users")
                .doc(props.user.id)
                .collection("files")
                .onSnapshot((snapshot) => {
                    let list = [];
    
                    snapshot.docs.forEach((doc) => {
                        if (doc.exists) {
                            let appObj = { ...doc.data(), ["id"]: `${doc.id}` };
                            list.push(appObj);
                        }
                    });
    
                    set_lead_user_files_old(list);
                });
            
            const unsubscribe2 = firestore
                .collection("bookings")
                .doc(bookingId)
                .collection("appFiles")
                .where("rowId", "==", props.user.id)
                .where("fileType", "==", "users")
                .onSnapshot((snapshot) => {
                    let list = [];
    
                    snapshot.docs.forEach((doc) => {
                        if (doc.exists) {
                            let appObj = { ...doc.data(), ["id"]: `${doc.id}` };
                            list.push(appObj);
                        }
                    });
    
                    set_lead_user_files(list);
                });
    
            return () => {
                unsubscribe();
                unsubscribe2();
            };
        }
    }, []);

    const userType = [
        { key: "", text: "Choose a User Type", value: "" },
        { key: "User", text: "User", value: "User" },
        { key: "Group Admin", text: "Group Admin", value: "Group Admin" },
    ];

    function close() {
        setOpen(false);
    }

    function openFile(fl) {
        setOpen(true);
        setFile(fl);
    }

    async function deleteFile() {
        await deleteBookItinFile(file, user, lead_booking, bookingType);

        setOpen(false);
        setFile(null);
    }

    return (
        <>
            <Formik
                initialValues={user}
                enableReinitialize
                validate={(values) => {
                    const errors = {};
                    if (!values.client_name) {
                        errors.client_name = "Required";
                    }
                    if (!values.client_email) {
                        errors.client_email = "Required";
                    }
                    if (!values.password) {
                        errors.password = "Required";
                    }
                    return errors;
                }}
                onSubmit={async (values, { setSubmitting }) => {

                    if (type === "Add") {
                        lead.id = secondLastSegment;
                        lead_booking.id = lastSegment;
                        await addBookingUser(values, lead_booking, lead, bookingType);
                    } else {
                        lead_booking.id = lastSegment;
                        values.bookingId = lastSegment;
                        let fileUpload = values.uploadFile
                        await saveBookingUser(values, bookingType);
                        await uploadBookItinFiles(fileUpload, user, lead_booking, bookingType, "");
                    }

                    setSubmitting(false);
                    dispatch(closeModal());
                }}
            >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                    <form onSubmit={handleSubmit} className="ui form">
                        <Grid style={{ padding: "20px 0" }}>
                            <Grid.Row columns={3} verticalAlign="middle">
                                <Grid.Column>
                                    <MyTextInput labelTop="Name*" name="client_name" type="text" />
                                </Grid.Column>

                                <Grid.Column>
                                    <MyTextInput labelTop="Email*" name="client_email" type="text" />
                                </Grid.Column>

                                <Grid.Column>
                                    <MyTextInput labelTop="Password*" name="password" type="text" />
                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Row columns={3} verticalAlign="middle">
                                <Grid.Column>
                                    <MySelectInput label="User Type" name="userType" options={userType} onChange={(field, value) => setFieldValue("userType", value.value)} placeholder="User Type" />
                                </Grid.Column>

                                <Grid.Column></Grid.Column>

                                <Grid.Column textAlign="right">
                                    <Button disabled={isSubmitting} loading={isSubmitting} size="large" color="orange" type="submit" content={type} />
                                </Grid.Column>
                            </Grid.Row>

                            {type !== "Add" && (
                                <Grid.Row columns={2}>
                                    <Grid.Column width={6}>
                                        <input
                                            id="uploadFile"
                                            name="uploadFile"
                                            type="file"
                                            onChange={(event) => {
                                                setFieldValue("uploadFile", event.currentTarget.files[0]);
                                            }}
                                            className="form-control"
                                        />
                                    </Grid.Column>

                                    <Grid.Column width={5}>
                                        <h2>Files</h2>

                                        {lead_user_files && lead_user_files.length > 0 ? (
                                            <List>
                                                {lead_user_files.map((fl) => (
                                                    <List.Item key={fl.id}>
                                                        <List.Content floated="right">
                                                            <Icon onClick={() => openFile(fl)} name="delete" />
                                                        </List.Content>

                                                        <List.Content>
                                                            <a href={fl.downloadURL} target="_blank">
                                                                {fl.groupUserName && fl.groupUserName.length > 0 ? fl.fileName + " - " + fl.groupUserName : fl.fileName + " - All"}
                                                            </a>
                                                        </List.Content>
                                                    </List.Item>
                                                ))}
                                            </List>
                                        ) : (
                                            <p>No files have been uploaded.</p>
                                        )}

                                        {lead_user_files_old && lead_user_files_old.length > 0 && (
                                            <List>
                                                {lead_user_files_old.map((fl) => (
                                                    <List.Item key={fl.id}>
                                                        <List.Content floated="right">
                                                            <Icon onClick={() => openFile(fl)} name="delete" />
                                                        </List.Content>

                                                        <List.Content>
                                                            <a href={fl.downloadURL} target="_blank">
                                                                {fl.groupUserName && fl.groupUserName.length > 0 ? fl.fileName + " - " + fl.groupUserName + " - OLD" : fl.fileName + " - All - OLD"}
                                                            </a>
                                                        </List.Content>
                                                    </List.Item>
                                                ))}
                                            </List>
                                        )}
                                    </Grid.Column>

                                    <Grid.Column width={5}>Vouchers: feature coming</Grid.Column>
                                </Grid.Row>
                            )}
                        </Grid>

                        <Confirm
                            header="You are about to delete this file"
                            content="This cannot be undone, are you sure?"
                            size="tiny"
                            open={open}
                            onCancel={close}
                            onConfirm={() => deleteFile()}
                            confirmButton="Yes"
                        />
                    </form>
                )}
            </Formik>
        </>
    );
}
