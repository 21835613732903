import React from "react";
import { useDispatch } from "react-redux";
import { Button, Grid, Label } from "semantic-ui-react";
import { addPayment, savePayment } from "../../booking/bookingActions";
import addHours from "date-fns/addHours";
import { Formik } from "formik";
import MyDateInput from "../../../app/common/form/MyDateInput";
import MySelectInput from "../../../app/common/form/MySelectInput";
import MyTextInput from "../../../app/common/form/MyTextInput";
import format from "date-fns/format";

export default function LeadBookingPaymentsForm(props) {
    let type = props.type;
    let payment = [];
    let closeModal = props.closeModal;
    let leadId = props.leadId;
    let date1 = new Date();
    let date2 = new Date();
    const dispatch = useDispatch();
    var parts = window.location.href.split("/");
    var lastSegment = parts.pop() || parts.pop();
    var secondLastSegment = parts[parts.length - 1];
    var thirdLastSegment = parts[parts.length - 2];

    if (props.payment === undefined) {
        let dateNow = new Date();
        let expiry = addHours(dateNow, 48);
        date1 = dateNow;
        date2 = expiry;
        payment = { date: "", link_expiry: "", paymentType: "", status: "", amount: "", description: "" };
    } else {
        payment = props.payment;
        if (payment.date !== undefined && payment.date !== "") {
            date1 = format(payment.date.toDate(), "yyyy-MM-dd");
        }
        if (payment.link_expiry !== undefined && payment.link_expiry !== "") {
            date2 = format(payment.link_expiry.toDate(), "yyyy-MM-dd");
        }
    }

    const paymentTypes = [
        { key: "EFT", text: "EFT", value: "EFT" },
        { key: "PayFast", text: "PayFast", value: "PayFast" },
    ];

    const paymentStatusEFT = [
        { key: "Awaiting Payment", text: "Awaiting Payment", value: "Awaiting Payment" },
        { key: "Payment Received", text: "Payment Received", value: "Payment Received" },
    ];

    const paymentStatusPayFast = [
        { key: "Email Request", text: "Email Request", value: "Email Request" },
        { key: "Link Request", text: "Link Request", value: "Link Request" },
        { key: "Payment Received", text: "Payment Received", value: "Payment Received" },
    ];

    return (
        <>
            <Formik
                initialValues={payment}
                validate={(values) => {
                    const error = {};

                    if(!values.date){
                        values.date = date1;
                    }
                    return error;
                }}
                onSubmit={async (values, { setSubmitting }) => {
                    setTimeout(() => {
                        if (props.type == "Add") {
                            props.lead_booking.id = lastSegment;
                            props.client.id = thirdLastSegment;
                            leadId = secondLastSegment;
                            addPayment(values, props.lead_booking, leadId, props.client);
                        } else {
                            savePayment(values);
                        }

                        setSubmitting(false);
                        dispatch(closeModal());
                    }, 400);
                }}
            >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                    <form onSubmit={handleSubmit} className="ui form">
                        <Grid style={{ padding: "20px 0" }}>
                            <Grid.Row columns={3} verticalAlign="middle">
                                <Grid.Column>
                                    <MyDateInput
                                        labelTop="Date / Time"
                                        name="date"
                                        selected={date1}
                                        dateFormat="yyyy-MM-dd HH:mm"
                                        showYearDropdown={true}
                                        showMonthDropdown={true}
                                        dropdownMode="select"
                                        showTimeSelect={true}
                                    />
                                </Grid.Column>

                                <Grid.Column>
                                    <MySelectInput
                                        labelTop="Payment Type"
                                        name="paymentType"
                                        options={paymentTypes}
                                        value={values.paymentType}
                                        placeholder="Payment Type"
                                        onChange={(field, value) => setFieldValue("paymentType", value.value)}
                                    />
                                </Grid.Column>

                                <Grid.Column>
                                    <MyTextInput labelTop="Amount" name="amount" type="text" />
                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Row style={{ margin: "20px 0" }} columns={3}>
                                <Grid.Column>
                                    <MyDateInput
                                        labelTop="Payment Link Expiry"
                                        name="link_expiry"
                                        selected={date2}
                                        dateFormat="yyyy-MM-dd HH:mm"
                                        showYearDropdown={true}
                                        showMonthDropdown={true}
                                        dropdownMode="select"
                                        showTimeSelect={true}
                                    />
                                </Grid.Column>

                                <Grid.Column>
                                    <MySelectInput
                                        labelTop="Status"
                                        name="status"
                                        options={props.paymentType && props.paymentType === "PayFast" ? paymentStatusPayFast : paymentStatusEFT}
                                        value={values.status}
                                        placeholder="Status"
                                        onChange={(field, value) => setFieldValue("status", value.value)}
                                    />
                                </Grid.Column>

                                <Grid.Column>
                                    <MyTextInput labelTop="Description" name="description" type="text" />
                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Row>
                                <Grid.Column textAlign="center">
                                    <Button disabled={isSubmitting} loading={isSubmitting} size="large" type="submit" color="orange" content={type} />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </form>
                )}
            </Formik>
        </>
    );
}
