import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { Segment, Header, Button, Grid, Table, Divider, Ref, Confirm } from "semantic-ui-react";
import { updateQuotePackage, deleteFlight, saveSortOrderOthers, deleteQuotePackage } from "../quoteActions";
import { openModal } from "../../../app/common/modals/modalReducer";
import format from "date-fns/format";
import addSeconds from "date-fns/addSeconds";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Formik, FieldArray } from "formik";
import MyTextInput from "../../../app/common/form/MyTextInput";
import MySelectInput from "../../../app/common/form/MySelectInput";
import MyCheckboxInput from "../../../app/common/form/MyCheckboxInput";
import MyTextArea from "../../../app/common/form/MyTextArea";
import MyDateInput from "../../../app/common/form/MyDateInput";
import ErrorBoundary from "../../../app/layout/ErrorBoundary";
import MyTinyMCE from "../../../app/common/form/MyTinyMCE";

import firebase from "../../../app/config/firebase";
const firestore = firebase.firestore();

export default function QuoteDetailedPackage({ quote, quotePackage, profile, quotePackages }) {
    const [flights, setFlights] = useState([]);

    const airportsAll = require("airport-codes");
    const dispatch = useDispatch();

    const [openflight, setOpenflight] = useState(false);
    const [flightDel, setFlightDel] = useState([]);
    const [openDelete, setOpenDelete] = useState(false);

    useEffect(() => {
        const unsubscribe = firestore
            .collection("quotes")
            .doc(quote.id)
            .collection("packages")
            .doc(quotePackage.id)
            .collection("flights")
            .onSnapshot((snapshot) => {
                let list = [];

                snapshot.docs.forEach((doc) => {
                    if (doc.exists) {
                        let appObj = { ...doc.data(), ["id"]: `${doc.id}` };
                        list.push(appObj);
                    }
                });

                list.sort((a, b) => (a.departDateTime > b.departDateTime ? 1 : -1));
                setFlights(list);
            });

        return () => {
            unsubscribe();
        };
    }, []);

    // const updateSorted = useCallback((type, dataUpdated, quotePackage) => {
    //     //Sort our data
    //     dataUpdated.sort((a, b) => (a.sort_order > b.sort_order ? 1 : -1));

    //     //Update State
    //     // if (type === "price_breakdown") {
    //     //     setPrice(dataUpdated);
    //     //     quotePackage.price_breakdown = dataUpdated;
    //     // } else if (type === "excludes") {
    //     //     setExcludes(dataUpdated);
    //     //     quotePackage.excludes = dataUpdated;
    //     // } else if (type === "includes") {
    //     //     setIncludes(dataUpdated);
    //     //     quotePackage.includes = dataUpdated;
    //     // }

    //     //Update our package state
    //     // setQuotePackage(quotePackage);
    // }, []);

    let cur_flights = {};

    if (flights && flights.length > 0) {
        // cur_flights = flights["flights_" + quotePackage.id];
        cur_flights = flights;

        Object.keys(cur_flights).forEach(function (key) {
            if (cur_flights[key].airportFrom !== "") {
                //First make it uppercase
                cur_flights[key].airportFrom = cur_flights[key].airportFrom.toUpperCase();
                cur_flights[key].airportTo = cur_flights[key].airportTo.toUpperCase();

                //Now try to find the airport details
                try {
                    cur_flights[key].airportFromName = airportsAll.findWhere({ iata: cur_flights[key].airportFrom }).get("name") + " (" + cur_flights[key].airportFrom + ")";
                } catch (e) {
                    cur_flights[key].airportFromName = cur_flights[key].airportFrom;
                }

                try {
                    cur_flights[key].airportToName = airportsAll.findWhere({ iata: cur_flights[key].airportTo }).get("name") + " (" + cur_flights[key].airportTo + ")";
                } catch (e) {
                    cur_flights[key].airportToName = cur_flights[key].airportTo;
                }
            }
        });
    }

    const getItemStyle = (isDragging, draggableStyle) => ({
        background: isDragging && "lightblue",
        ...draggableStyle,
    });

    const stars = [
        { key: "None", text: "None", value: "None" },
        { key: "1", text: "1", value: "1" },
        { key: "2", text: "2", value: "2" },
        { key: "3", text: "3", value: "3" },
        { key: "4", text: "4", value: "4" },
        { key: "5", text: "5", value: "5" },
    ];

    function onDragEnd(result) {
        const { destination, source, reason } = result;

        //Dropped back in same place
        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        //What table are we sorting?
        let type = "";
        let sorted_data = {};

        if (result.destination.droppableId === "price_breakdown") {
            sorted_data = Object.assign([], quotePackage.price_breakdown);
            type = "price_breakdown";
        } else if (result.destination.droppableId === "excludes") {
            sorted_data = Object.assign([], quotePackage.excludes);
            type = "excludes";
        } else if (result.destination.droppableId === "includes") {
            sorted_data = Object.assign([], quotePackage.includes);
            type = "includes";
        }

        //Change order of the items
        const file = sorted_data[source.index];
        sorted_data.splice(source.index, 1);
        sorted_data.splice(destination.index, 0, file);

        //Save order
        saveSortOrderOthers(sorted_data, type, quotePackage);

        //Update state via callback
        // updateSorted(type, sorted_data, quotePackage);
    }

    function openFlightD(flightDelData) {
        setOpenflight(true);
        setFlightDel(flightDelData);
    }
    function closeFlightD() {
        setOpenflight(false);
        setFlightDel([]);
    }
    function deleteFlightD(flightDel) {
        deleteFlight(flightDel);
        setOpenflight(false);
    }
    function open() {
        setOpenDelete(true);
    }
    function close() {
        setOpenDelete(false);
    }

    async function deleteQuotePackageData(quotePackage) {
        await deleteQuotePackage(quotePackage);
        setOpenDelete(false);
    }

    return (
        <ErrorBoundary>
            <div style={{ marginBottom: 20 }}>
                <Formik
                    enableReinitialize
                    initialValues={quotePackage}
                    validate={(values) => {
                        const error = {};
                        return error;
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                        //Update state
                        // await setIncludes(values.includes);
                        // await setQuotePackage(values);

                        //Update firestore
                        await updateQuotePackage(values, quotePackage);

                        setSubmitting(false);
                    }}
                >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, dirty, setFieldValue }) => (
                        <form onSubmit={handleSubmit} id={"form " + values.package_name} className="ui form">
                            <Segment attached="top">
                                <Grid>
                                    <Grid.Row columns={2} verticalAlign="middle">
                                        <Grid.Column width={8}>
                                            <Header as="h3">{values.package_name}</Header>
                                        </Grid.Column>

                                        <Grid.Column width={8} textAlign="right">
                                            <Button
                                                disabled={isSubmitting}
                                                loading={isSubmitting}
                                                size="medium"
                                                color="orange"
                                                id={"save " + values.package_name}
                                                content={"Save " + values.package_name}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Segment>
                            <Segment attached style={{ paddingTop: 30, paddingBottom: 30 }}>
                                <Grid>
                                    <Grid.Row columns={2}>
                                        <Grid.Column>
                                            <MyTextInput label="Package Name" name="package_name" type="text" />
                                            <MyTextInput label="Holiday" name="holiday_name" type="text" />
                                            <MySelectInput label="Star Rating" name="stars" options={stars} />
                                        </Grid.Column>
                                        <Grid.Column>
                                            <MyTextInput
                                                label="Price"
                                                name="price"
                                                type="text"
                                                onChange={(field) => {
                                                    let price = 0;
                                                    let discount = 0;

                                                    if (field.target.value !== "") {
                                                        price = parseFloat(field.target.value);
                                                    }

                                                    if (values.discount && values.discount !== "" && values.discount !== "NaN") {
                                                        discount = parseFloat(values.discount);
                                                    }

                                                    setFieldValue("price", price);
                                                    setFieldValue("discount", discount);
                                                    setFieldValue("priceAfter", price - discount);
                                                }}
                                            />
                                            <MyTextInput
                                                label="Discount"
                                                name="discount"
                                                type="text"
                                                onChange={(field) => {
                                                    let price = 0;
                                                    let discount = 0;

                                                    if (field.target.value !== "") {
                                                        discount = parseFloat(field.target.value);
                                                    }

                                                    if (values.price && values.price !== "" && values.price !== "NaN") {
                                                        price = parseFloat(values.price);
                                                    }

                                                    setFieldValue("price", price);
                                                    setFieldValue("discount", discount);
                                                    setFieldValue("priceAfter", price - discount);
                                                }}
                                            />
                                            <MyTextInput label="Total Due" name="priceAfter" type="text" disabled={true} />
                                            <br />
                                            <br />
                                            <MyCheckboxInput type="checkbox" label="" labelcb="Pricing on new page" name="pricing_new_pg" />
                                            <MyCheckboxInput type="checkbox" label="" labelcb="Show Discount" name="show_discount" />
                                        </Grid.Column>
                                    </Grid.Row>

                                    <Grid.Row>
                                        <Grid.Column>
                                            <Divider style={{ marginTop: 40, marginBottom: 20 }} />

                                            <FieldArray
                                                name="price_breakdown"
                                                render={(arrayHelpers) => (
                                                    <div>
                                                        <DragDropContext onDragEnd={onDragEnd}>
                                                            <Table striped celled structured>
                                                                <Table.Header>
                                                                    <Table.Row>
                                                                        <Table.HeaderCell colSpan="" textAlign="center">
                                                                            Price Breakdown
                                                                        </Table.HeaderCell>

                                                                        <Table.HeaderCell textAlign="right">
                                                                            <Button size="medium" color="orange" type="button" content="Add" as="a" onClick={() => arrayHelpers.push({})} />
                                                                        </Table.HeaderCell>
                                                                    </Table.Row>

                                                                    {values.price_breakdown && values.price_breakdown.length > 0 && (
                                                                        <Table.Row>
                                                                            <Table.HeaderCell textAlign="center" width="12">
                                                                                Value
                                                                            </Table.HeaderCell>

                                                                            <Table.HeaderCell textAlign="center" width={4}>
                                                                                Action
                                                                            </Table.HeaderCell>
                                                                        </Table.Row>
                                                                    )}
                                                                </Table.Header>

                                                                {values.price_breakdown && values.price_breakdown.length > 0 ? (
                                                                    <Droppable isDropDisabled={dirty} droppableId="price_breakdown">
                                                                        {(provided, snapshot) => (
                                                                            <Ref innerRef={provided.innerRef}>
                                                                                <Table.Body {...provided.droppableProps}>
                                                                                    {values.price_breakdown.map((row, idx) => (
                                                                                        <Draggable
                                                                                            draggableId={"price_breakdown" + idx}
                                                                                            data={"price_breakdown"}
                                                                                            index={idx}
                                                                                            key={"price_breakdown" + idx}
                                                                                            isDragDisabled={dirty}
                                                                                        >
                                                                                            {(provided, snapshot) => (
                                                                                                <Ref innerRef={provided.innerRef}>
                                                                                                    <Table.Row
                                                                                                        key={idx}
                                                                                                        {...provided.draggableProps}
                                                                                                        {...provided.dragHandleProps}
                                                                                                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                                                                                    >
                                                                                                        <Table.Cell>
                                                                                                            <MyTextInput name={`price_breakdown[${idx}].value`} type="text" />
                                                                                                        </Table.Cell>
                                                                                                        <Table.Cell textAlign="right">
                                                                                                            <Button
                                                                                                                size="medium"
                                                                                                                color="red"
                                                                                                                content="Delete"
                                                                                                                as="a"
                                                                                                                onClick={() => arrayHelpers.remove(idx)}
                                                                                                            />
                                                                                                        </Table.Cell>
                                                                                                    </Table.Row>
                                                                                                </Ref>
                                                                                            )}
                                                                                        </Draggable>
                                                                                    ))}
                                                                                    {provided.placeholder}
                                                                                </Table.Body>
                                                                            </Ref>
                                                                        )}
                                                                    </Droppable>
                                                                ) : null}
                                                            </Table>
                                                        </DragDropContext>
                                                    </div>
                                                )}
                                            />
                                            <br />

                                            <FieldArray
                                                name="includes"
                                                render={(arrayHelpers) => (
                                                    <div>
                                                        <DragDropContext onDragEnd={onDragEnd}>
                                                            <Table striped celled structured>
                                                                <Table.Header>
                                                                    <Table.Row>
                                                                        <Table.HeaderCell colSpan="" textAlign="center">
                                                                            Includes
                                                                        </Table.HeaderCell>

                                                                        <Table.HeaderCell textAlign="right">
                                                                            <Button
                                                                                size="medium"
                                                                                color="orange"
                                                                                type="button"
                                                                                content="Add"
                                                                                as="a"
                                                                                onClick={() => arrayHelpers.push({ sort_order: values && values.includes ? parseInt(values.includes.length + 1) : 0 })}
                                                                            />
                                                                        </Table.HeaderCell>
                                                                    </Table.Row>

                                                                    {values.includes && values.includes.length > 0 && (
                                                                        <Table.Row>
                                                                            <Table.HeaderCell textAlign="center" width="12">
                                                                                Value
                                                                            </Table.HeaderCell>

                                                                            <Table.HeaderCell textAlign="center" width={4}>
                                                                                Action
                                                                            </Table.HeaderCell>
                                                                        </Table.Row>
                                                                    )}
                                                                </Table.Header>

                                                                {values.includes && values.includes.length > 0 ? (
                                                                    <Droppable isDropDisabled={dirty} droppableId="includes">
                                                                        {(provided, snapshot) => (
                                                                            <Ref innerRef={provided.innerRef}>
                                                                                <Table.Body {...provided.droppableProps}>
                                                                                    {values.includes.map((row, idx) => (
                                                                                        <Draggable draggableId={"includes" + idx} index={idx} key={"includes" + idx} isDragDisabled={dirty}>
                                                                                            {(provided, snapshot) => (
                                                                                                <Ref innerRef={provided.innerRef}>
                                                                                                    <Table.Row
                                                                                                        key={"inc_sub" + idx}
                                                                                                        {...provided.draggableProps}
                                                                                                        {...provided.dragHandleProps}
                                                                                                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                                                                                    >
                                                                                                        <Table.Cell>
                                                                                                            <MyTextInput title={row.sort_order} name={`includes[${idx}].value`} type="text" />
                                                                                                        </Table.Cell>
                                                                                                        <Table.Cell textAlign="right">
                                                                                                            <Button
                                                                                                                size="medium"
                                                                                                                color="red"
                                                                                                                content="Delete"
                                                                                                                as="a"
                                                                                                                onClick={() => arrayHelpers.remove(idx)}
                                                                                                            />
                                                                                                        </Table.Cell>
                                                                                                    </Table.Row>
                                                                                                </Ref>
                                                                                            )}
                                                                                        </Draggable>
                                                                                    ))}
                                                                                    {provided.placeholder}
                                                                                </Table.Body>
                                                                            </Ref>
                                                                        )}
                                                                    </Droppable>
                                                                ) : null}
                                                            </Table>
                                                        </DragDropContext>
                                                    </div>
                                                )}
                                            />

                                            <FieldArray
                                                name="excludes"
                                                render={(arrayHelpers) => (
                                                    <div>
                                                        <DragDropContext onDragEnd={onDragEnd}>
                                                            <Table striped celled structured className="quoteCell">
                                                                <Table.Header>
                                                                    <Table.Row>
                                                                        <Table.HeaderCell colSpan="" textAlign="center">
                                                                            Excludes
                                                                        </Table.HeaderCell>

                                                                        <Table.HeaderCell textAlign="right">
                                                                            <Button size="medium" color="orange" type="button" content="Add" as="a" onClick={() => arrayHelpers.push({})} />
                                                                        </Table.HeaderCell>
                                                                    </Table.Row>

                                                                    {values.excludes && values.excludes.length > 0 && (
                                                                        <Table.Row>
                                                                            <Table.HeaderCell textAlign="center" width="12">
                                                                                Value
                                                                            </Table.HeaderCell>

                                                                            <Table.HeaderCell textAlign="center" width={4}>
                                                                                Action
                                                                            </Table.HeaderCell>
                                                                        </Table.Row>
                                                                    )}
                                                                </Table.Header>

                                                                {values.excludes && values.excludes.length > 0 ? (
                                                                    <Droppable isDropDisabled={dirty} droppableId="excludes">
                                                                        {(provided, snapshot) => (
                                                                            <Ref innerRef={provided.innerRef}>
                                                                                <Table.Body {...provided.droppableProps}>
                                                                                    {values.excludes.map((row, idx) => (
                                                                                        <Draggable draggableId={"excludes" + idx} index={idx} key={"excludes" + idx} isDragDisabled={dirty}>
                                                                                            {(provided, snapshot) => (
                                                                                                <Ref innerRef={provided.innerRef}>
                                                                                                    <Table.Row
                                                                                                        key={idx}
                                                                                                        {...provided.draggableProps}
                                                                                                        {...provided.dragHandleProps}
                                                                                                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                                                                                    >
                                                                                                        <Table.Cell>
                                                                                                            <MyTextInput name={`excludes[${idx}].value`} type="text" />
                                                                                                        </Table.Cell>
                                                                                                        <Table.Cell textAlign="right">
                                                                                                            <Button
                                                                                                                size="medium"
                                                                                                                color="red"
                                                                                                                content="Delete"
                                                                                                                as="a"
                                                                                                                onClick={() => arrayHelpers.remove(idx)}
                                                                                                            />
                                                                                                        </Table.Cell>
                                                                                                    </Table.Row>
                                                                                                </Ref>
                                                                                            )}
                                                                                        </Draggable>
                                                                                    ))}
                                                                                    {provided.placeholder}
                                                                                </Table.Body>
                                                                            </Ref>
                                                                        )}
                                                                    </Droppable>
                                                                ) : null}
                                                            </Table>
                                                        </DragDropContext>
                                                    </div>
                                                )}
                                            />

                                            <Table striped celled structured>
                                                <Table.Header>
                                                    <Table.Row>
                                                        <Table.HeaderCell colSpan={5} textAlign="center">
                                                            Flights
                                                        </Table.HeaderCell>

                                                        <Table.HeaderCell colSpan={4} textAlign="right">
                                                            <Button
                                                                disabled={isSubmitting}
                                                                loading={isSubmitting}
                                                                size="medium"
                                                                color="orange"
                                                                content="Add a Manual Flight"
                                                                as="a"
                                                                onClick={() => dispatch(openModal({ modalType: "QuoteFlightEditModal", modalProps: { quotePackage } }))}
                                                            />
                                                            <Button
                                                                disabled={isSubmitting}
                                                                loading={isSubmitting}
                                                                size="medium"
                                                                color="orange"
                                                                content="Search for a Flight"
                                                                as="a"
                                                                onClick={() => dispatch(openModal({ modalType: "FlightSearchModal", modalProps: { quotePackage } }))}
                                                            />
                                                        </Table.HeaderCell>
                                                    </Table.Row>

                                                    <Table.Row>
                                                        <Table.HeaderCell textAlign="center" rowSpan={2}>
                                                            Type
                                                        </Table.HeaderCell>

                                                        <Table.HeaderCell textAlign="center" colSpan={2}>
                                                            Depart
                                                        </Table.HeaderCell>
                                                        <Table.HeaderCell textAlign="center" colSpan={2}>
                                                            Arrive
                                                        </Table.HeaderCell>

                                                        <Table.HeaderCell textAlign="center" rowSpan={2}>
                                                            Flight Nr
                                                        </Table.HeaderCell>
                                                        <Table.HeaderCell textAlign="center" rowSpan={2}>
                                                            Class
                                                        </Table.HeaderCell>

                                                        <Table.HeaderCell textAlign="center" rowSpan={2}>
                                                            Action
                                                        </Table.HeaderCell>
                                                    </Table.Row>

                                                    <Table.Row>
                                                        <Table.HeaderCell textAlign="center">Date</Table.HeaderCell>
                                                        <Table.HeaderCell textAlign="center">Airport</Table.HeaderCell>

                                                        <Table.HeaderCell textAlign="center">Date</Table.HeaderCell>
                                                        <Table.HeaderCell textAlign="center">Airport</Table.HeaderCell>
                                                    </Table.Row>
                                                </Table.Header>

                                                <Table.Body>
                                                    {cur_flights &&
                                                        cur_flights.length > 0 &&
                                                        cur_flights.map((flight) => (
                                                            <Table.Row key={flight.id}>
                                                                <Table.Cell>{flight.type}</Table.Cell>
                                                                <Table.Cell>
                                                                    {flight.departDateTime && flight.departDateTime !== "" && format(flight.departDateTime.toDate(), "yyyy-MM-dd HH:mm")}
                                                                </Table.Cell>
                                                                <Table.Cell>{flight.airportFromName}</Table.Cell>
                                                                <Table.Cell>
                                                                    {flight.arriveDateTime && flight.arriveDateTime !== "" && format(flight.arriveDateTime.toDate(), "yyyy-MM-dd HH:mm")}
                                                                </Table.Cell>
                                                                <Table.Cell>{flight.airportToName}</Table.Cell>
                                                                <Table.Cell>{flight.flight_nr}</Table.Cell>
                                                                <Table.Cell>{flight.flight_class}</Table.Cell>

                                                                <Table.Cell textAlign="right" width={3}>
                                                                    <Button
                                                                        size="medium"
                                                                        color="orange"
                                                                        content="Edit"
                                                                        as="a"
                                                                        onClick={() =>
                                                                            dispatch(
                                                                                openModal({
                                                                                    modalType: "QuoteFlightEditModal",
                                                                                    modalProps: { flight: flight, quotePackage: values, quotePackages: quotePackages },
                                                                                })
                                                                            )
                                                                        }
                                                                    />
                                                                    <Button
                                                                        disabled={isSubmitting}
                                                                        loading={isSubmitting}
                                                                        size="medium"
                                                                        color="red"
                                                                        content="Delete"
                                                                        as="a"
                                                                        onClick={() => openFlightD(flight)}
                                                                    />
                                                                </Table.Cell>
                                                            </Table.Row>
                                                        ))}
                                                </Table.Body>
                                            </Table>

                                            {values.flights_text && (
                                                <div>
                                                    <label>Old Flights</label>
                                                    <pre>{values.flights_text}</pre>
                                                </div>
                                            )}

                                            <MyTinyMCE
                                                name="flight_html"
                                                label="Paste Flight Table"
                                                type="text"
                                                onEditorChange={(event, value) => setFieldValue("flight_html", event)}
                                                removeInvalidItems={true}
                                            />

                                            <FieldArray
                                                name="itinerary"
                                                render={(arrayHelpers) => (
                                                    <div>
                                                        <Table striped celled structured>
                                                            <Table.Header>
                                                                <Table.Row>
                                                                    <Table.HeaderCell colSpan={3} textAlign="center">
                                                                        Itinerary
                                                                    </Table.HeaderCell>

                                                                    <Table.HeaderCell textAlign="right">
                                                                        <Button size="medium" color="orange" content="Add Row" as="a" onClick={() => arrayHelpers.push({})} />
                                                                    </Table.HeaderCell>
                                                                </Table.Row>

                                                                <Table.Row>
                                                                    <Table.HeaderCell textAlign="center">Date / Time</Table.HeaderCell>
                                                                    <Table.HeaderCell textAlign="center">Item</Table.HeaderCell>
                                                                    <Table.HeaderCell textAlign="center">Booking ref</Table.HeaderCell>
                                                                    <Table.HeaderCell textAlign="center" width={3}>
                                                                        Action
                                                                    </Table.HeaderCell>
                                                                </Table.Row>
                                                            </Table.Header>

                                                            {values.itinerary && values.itinerary.length > 0 && (
                                                                <Table.Body>
                                                                    {values.itinerary.map((row, index) => (
                                                                        <Table.Row key={index}>
                                                                            <Table.Cell>
                                                                                {values.itinerary[index].date !== undefined && values.itinerary[index].date.seconds ? (
                                                                                    <MyDateInput
                                                                                        label=""
                                                                                        type="date"
                                                                                        selected={format(addSeconds(new Date(0), values.itinerary[index].date.seconds), "yyyy-MM-dd HH:mm")}
                                                                                        name={`itinerary[${index}].date`}
                                                                                        placeholder="Date / Time"
                                                                                        dateFormat="yyyy-MM-dd HH:mm"
                                                                                        showYearDropdown={true}
                                                                                        showMonthDropdown={true}
                                                                                        dropdownMode="select"
                                                                                        showTimeSelect={true}
                                                                                    />
                                                                                ) : (
                                                                                    <>
                                                                                        <MyDateInput
                                                                                            label=""
                                                                                            type="date"
                                                                                            name={`itinerary[${index}].date`}
                                                                                            placeholder="Date / Time"
                                                                                            dateFormat="yyyy-MM-dd HH:mm"
                                                                                            showYearDropdown={true}
                                                                                            showMonthDropdown={true}
                                                                                            dropdownMode="select"
                                                                                            showTimeSelect={true}
                                                                                        />
                                                                                    </>
                                                                                )}
                                                                            </Table.Cell>

                                                                            <Table.Cell>
                                                                                <MyTextInput name={`itinerary[${index}].item`} type="text" />
                                                                            </Table.Cell>

                                                                            <Table.Cell>
                                                                                <MyTextInput name={`itinerary[${index}].booking_ref`} type="text" />
                                                                            </Table.Cell>

                                                                            <Table.Cell textAlign="right">
                                                                                <Button size="medium" color="red" content="Delete" as="a" onClick={() => arrayHelpers.remove(index)} />
                                                                            </Table.Cell>
                                                                        </Table.Row>
                                                                    ))}
                                                                </Table.Body>
                                                            )}
                                                        </Table>
                                                    </div>
                                                )}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>

                                    <Grid.Row columns={1}>
                                        <Grid.Column>
                                            <MyTinyMCE
                                                name="holiday_description"
                                                label="Description"
                                                type="text"
                                                onEditorChange={(event, value) => setFieldValue("holiday_description", event)}
                                                removeInvalidItems={true}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row columns={1}>
                                        <Grid.Column>
                                            <MyTinyMCE
                                                name="recommendation"
                                                label="Why I recommend this package"
                                                type="text"
                                                onEditorChange={(event, value) => setFieldValue("recommendation", event)}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Segment>
                            <Segment attached="bottom">
                                <Button disabled={isSubmitting} loading={isSubmitting} size="medium" color="orange" content={"Save " + values.package_name} />
                                <Button disabled={isSubmitting} loading={isSubmitting} size="medium" color="red" content="Delete" as="a" onClick={open} />
                                <Button
                                    disabled={isSubmitting}
                                    loading={isSubmitting}
                                    size="medium"
                                    color="orange"
                                    content="Save as Package Template"
                                    as="a"
                                    onClick={() => dispatch(openModal({ modalType: "PackageTemplateModal", modalProps: { quotePackage, profile } }))}
                                />
                            </Segment>
                        </form>
                    )}
                </Formik>
                <Confirm
                    header="You are about to delete this package"
                    content="This cannot be undone, are you sure?"
                    size="tiny"
                    open={openDelete}
                    onCancel={close}
                    onConfirm={() => deleteQuotePackageData(quotePackage)}
                    confirmButton="Yes"
                />
                <Confirm
                    header="You are about to delete this flight"
                    content="This cannot be undone, are you sure?"
                    size="tiny"
                    open={openflight}
                    onCancel={closeFlightD}
                    onConfirm={() => deleteFlightD(flightDel)}
                    confirmButton="Yes"
                />
            </div>
        </ErrorBoundary>
    );
}
