import React, { useState, useEffect } from "react";
import { Container, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import firebase from "../../../app/config/firebase";
const firestore = firebase.firestore();

export default function QuoteTemplatesPage(props) {
    const { currentUserProfile } = useSelector((state) => state.profile);

    const [quote_templates, setQuote_templates] = useState([]);

    useEffect(() => {

        const unsubscribe = firestore
            .collection("quote_templates")
            .onSnapshot((snapshot) => {
                let list = [];

                snapshot.docs.forEach((doc) => {
                    if (doc.exists) {
                        let appObj = { ...doc.data(), id: doc.id };
                        list.push(appObj);
                    }
                });

                setQuote_templates(list);
            });

        return () => unsubscribe();
    }, []);

    if (currentUserProfile.isLoaded === true && currentUserProfile.isEmpty === true) {
        props.history.push("/");
    }

    if (currentUserProfile.isSuper === false) {
        props.history.push("/");
    }

    return (
        <>
            <Container>
                <Button as={Link} to={`/admin/quote_template/0`}>
                    Add Template
                </Button>
                <Button as={Link} to={`/admin/landing`}>
                    Back to Admin
                </Button>

                <table className="ui celled table selectable">
                    <thead>
                        <tr>
                            <th>Template</th>
                            <th>Visa</th>
                            <th>Action</th>
                        </tr>
                    </thead>

                    <tbody>
                        {quote_templates &&
                            quote_templates.length > 0 &&
                            quote_templates.map((qt) => (
                                <tr key={qt.id}>
                                    <td data-label="Name">{qt.template_name}</td>
                                    <td data-label="Name">{!qt.visa ? "Empty" : "Filled In"}</td>
                                    <td data-label="Action">
                                        <Button as={Link} to={`/admin/quote_template/${qt.id}`}>
                                            Edit
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </Container>
        </>
    );
}
