import { SIGN_IN_USER, SIGN_OUT_USER } from "./authConstants";
import { APP_LOADED } from "../../app/async/asyncReducer";
import firebase from "../../app/config/firebase";
import { getUserProfile, dataFromSnapshot } from "../../app/firestore/firestoreService";
import { listenToCurrentUserProfile } from "../profiles/profileActions";
import { toast } from "react-toastify";

export function signInUser(user) {
    return {
        type: SIGN_IN_USER,
        payload: user,
    };
}

export function verifyAuth() {
    return function (dispatch) {
        return firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                dispatch(signInUser(user));
                const profileRef = getUserProfile(user.uid);
                profileRef.onSnapshot((snapshot) => {
                    dispatch(listenToCurrentUserProfile(dataFromSnapshot(snapshot)));
                    dispatch({ type: APP_LOADED });
                });
            } else {
                dispatch(signOutUser());
                dispatch({ type: APP_LOADED });
            }
        });
    };
}

export function signOutUser() {
    return {
        type: SIGN_OUT_USER,
    };
}

export async function registerClientUser(values, client) {
    const firestore = firebase.firestore();

    try {
        let clientUser = firebase.app().functions("europe-west2").httpsCallable("registerClientUser");

        await clientUser({ client_email: client.client_email, password: values.password })
            .then(async (result) => {
                console.log(result);

                if (!result.data.uid) {
                    console.log(result.data);
                    toast.error(result.data);
                } else {
                    //Update Client doc
                    await firestore.collection("clients").doc(client.id).update({ appUserUid: result.data.uid, appPassword: values.password });

                    //Add User doc
                    await firestore
                        .collection("users")
                        .doc(result.data.uid)
                        .set({
                            displayName: client.client_name,
                            createdAt: new Date(Date.now()),
                            clientId: client.id,
                            email: client.client_email,
                            id: result.data.uid,
                        });

                    toast.success("User created for client");
                }

                return true;
            })
            .catch((error) => {
                console.log(error);
            });

        // firebase
        //     .auth()
        //     .createUserWithEmailAndPassword(client.client_email, values.password)
        //     .then(async (userCredential) => {
        //         // Signed in

        //         //Update Client doc
        //         await firestore.collection("clients").doc(client.id).update({ appUserUid: userCredential.user.uid, appPassword: values.password });

        //         //Add User doc
        //         await firestore
        //             .collection("users")
        //             .doc(userCredential.user.uid)
        //             .set({
        //                 displayName: client.client_name,
        //                 createdAt: new Date(Date.now()),
        //                 clientId: client.id,
        //                 email: client.client_email,
        //                 id: userCredential.user.uid,
        //             });

        //         toast.success("User created for client");
        //     })
        //     .catch((error) => {
        //         // var errorCode = error.code;
        //         var errorMessage = error.message;

        //         toast.error(errorMessage);
        //     });
    } catch (error) {
        console.log(error);
        toast.error("Something went wrong");
    }
}

export async function resetPassword(creds) {
    try {
        await firebase.auth().sendPasswordResetEmail(creds);

        toast.success("A password reset email has been sent, check your inbox!");
    } catch (e) {
        console.log(e);
    }
}
