import React, { useEffect, useState } from "react";
import { Header, Segment, Button } from "semantic-ui-react";
import LeadDetailedCommentForm from "./LeadDetailedCommentForm";
import format from "date-fns/format";
import { Formik } from "formik";
import MyTextInput from "../../../app/common/form/MyTextInput";
import MyCheckboxInput from "../../../app/common/form/MyCheckboxInput";
import { createComment } from "../leadActions";
import { openModal } from "../../../app/common/modals/modalReducer";
import { useDispatch, useSelector } from "react-redux";

import firebase from "../../../app/config/firebase";
const firestore = firebase.firestore();

export default function LeadDetailedComments(props) {
    const [comments, setComments] = useState([]);
    const dispatch = useDispatch();

    const { currentUserProfile } = useSelector((state) => state.profile);

    useEffect(() => {
        const unsubscribe1 = firestore
            .collection("leads")
            .doc(props.lead.id)
            .collection("comments")
            .onSnapshot((snapshot) => {
                let list = [];

                snapshot.docs.forEach((doc) => {
                    if (doc.exists) {
                        let appObj = { ...doc.data(), ["id"]: `${doc.id}` };
                        list.push(appObj);
                    }
                });

                list.sort((a, b) => (a.createDate < b.createDate ? 1 : -1));
                setComments(list);
            });

        return () => unsubscribe1();
    }, []);

    return (
        <div style={{ marginBottom: 20 }}>
            <Segment attached="top">
                <Header as="h3">Comments</Header>
            </Segment>

            <Segment attached>
                {props.lead.unassigned_comments && props.lead.unassigned_comments !== "" && (
                    <p key="unassignedcomment">
                        <strong>Unassigned Comment</strong>
                        <br />
                        {props.lead.unassigned_comments}
                    </p>
                )}
                {comments &&
                    comments.length > 0 &&
                    comments.map((comment) => (
                        <p key={comment.id}>
                            <strong>
                                {comment.displayName} ({format(comment.createDate.toDate(), "d MMMM yyyy hh:mm:ii")}):
                            </strong>
                            {comment.comment_email && <span> (Email Sent)</span>}
                            <br />
                            {comment.comment}
                            {currentUserProfile.isSuper === true && (
                                <Button color="red" size="tiny" style={{ float: "right" }} onClick={() => dispatch(openModal({ modalType: "CommentEdit", modalProps: { comment: comment } }))}>
                                    Edit
                                </Button>
                            )}
                        </p>
                    ))}
            </Segment>

            <Segment attached="bottom">
                {/* <LeadDetailedCommentForm leadid={props.lead.id} profile={props.profile} /> */}

                <Formik
                    initialValues={{
                        comment: "",
                        leadId: props.lead.id,
                        displayName: props.profile && props.profile.displayName ? props.profile.displayName : "",
                        userUid: props.profile && props.profile.id ? props.profile.id : "",
                    }}
                    validate={(values) => {
                        const error = {};
                        return error;
                    }}
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                        console.log(values);

                        await createComment(values);
                        resetForm();
                        setSubmitting(false);
                    }}
                >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                        <form onSubmit={handleSubmit} className="ui form" style={{ marginBottom: 20 }}>
                            <MyCheckboxInput labelcb="Send Email to Consultant:" name="comment_email" type="checkbox" />
                            <MyTextInput label="" name="comment" placeholder="Comment" />
                            <Button style={{ marginTop: 20 }} disabled={isSubmitting} loading={isSubmitting} color="orange" content="Add Comment" type="submit" />
                        </form>
                    )}
                </Formik>
            </Segment>
        </div>
    );
}
