import firebase from "../../../app/config/firebase";
const firestore = firebase.firestore();

export async function getExportLeads(dateFrom, dateTo, status) {
    const timestamp1 = firebase.firestore.Timestamp.fromDate(new Date(dateFrom));
    const timestamp2 = firebase.firestore.Timestamp.fromDate(new Date(dateTo));
    let leads = await firestore.collection("leads").where("createDate", ">=", timestamp1).get();
    // .where("createDate", ">=", dateFrom).where("createDate", "<=", dateTo).where("status", "==", status)
    return leads;
}
