import { toast } from "react-toastify";
import firebase from "../../app/config/firebase";

export async function updateUserPassword(creds) {
    const firestore = firebase.firestore();

    const user = firebase.auth().currentUser;
    await firestore.collection("users").doc(user.uid).update({passwordResetDate: new Date()});
    console.log(user.uid)

    return user.updatePassword(creds);
}