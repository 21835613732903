import { toastr } from "react-redux-toastr";
import cuid from "cuid";
import { asyncActionError, asyncActionFinish, asyncActionStart } from "../async/asyncActions";
import { reset } from "redux-form";
import { toast } from "react-toastify";
import firebase from "../../app/config/firebase";
import { useDispatch } from "react-redux";
import { firestore } from "firebase";

export async function deleteQuote(quote_id) {
    const firestore = firebase.firestore();

    try {
        let packagesRef = firestore.collection("quotes").doc(quote_id).collection("packages");
        let packagesSnap = await packagesRef.get();

        for (let i = 0; i < packagesSnap.docs.length; i++) {
            let cur_pack_id = packagesSnap.docs[i].id;

            let imagesRef = firestore.collection("quotes").doc(quote_id).collection("packages").doc(cur_pack_id).collection("images");
            let imagesSnap = await imagesRef.get();

            for (let i = 0; i < imagesSnap.docs.length; i++) {
                let cur_img = imagesSnap.docs[i].id;

                await firestore.collection("quotes").doc(quote_id).collection("packages").doc(cur_pack_id).collection("images").doc(cur_img).delete();
            }

            let flightsRef = firestore.collection("quotes").doc(quote_id).collection("packages").doc(cur_pack_id).collection("flights");
            let flightsSnap = await flightsRef.get();

            for (let i = 0; i < flightsSnap.docs.length; i++) {
                let flightId = flightsSnap.docs[i].id;

                await firestore.collection("quotes").doc(quote_id).collection("packages").doc(cur_pack_id).collection("flights").doc(flightId).delete();
            }

            await firestore.collection("quotes").doc(quote_id).collection("packages").doc(cur_pack_id).delete();
        }

        await firestore.collection("quotes").doc(quote_id).delete();

        toast.success("Quote has been deleted");
    } catch (error) {
        console.log(error);
        toast.error("Something went wrong");
    }
}

export async function createQuotePackage(quotePackage, quote) {
    const firestore = firebase.firestore();

    quotePackage.createDate = new Date(Date.now());
    quotePackage.quoteId = quote.id;
    quotePackage.package_status = "Draft";

    try {
        await firestore.collection("quotes").doc(quote.id).collection("packages").add(quotePackage);
        toast.success("Package has been created");
    } catch (error) {
        toast.error("Something went wrong");
    }
}

export async function deleteQuotePackage(quotePackage) {
    const firestore = firebase.firestore();

    try {
        let imagesRef = firestore.collection("quotes").doc(quotePackage.quoteId).collection("packages").doc(quotePackage.id).collection("images");
        let imagesSnap = await imagesRef.get();

        for (let i = 0; i < imagesSnap.docs.length; i++) {
            let cur_img = imagesSnap.docs[i].id;
            await firestore.collection("quotes").doc(quotePackage.quoteId).collection("packages").doc(quotePackage.id).collection("images").doc(cur_img).delete();
        }

        let flightsRef = firestore.collection("quotes").doc(quotePackage.quoteId).collection("packages").doc(quotePackage.id).collection("flights");
        let flightsSnap = await flightsRef.get();

        for (let i = 0; i < flightsSnap.docs.length; i++) {
            let cur_flight = flightsSnap.docs[i].id;
            await firestore.collection("quotes").doc(quotePackage.quoteId).collection("packages").doc(quotePackage.id).collection("flights").doc(cur_flight).delete();
        }

        await firestore.collection("quotes").doc(quotePackage.quoteId).collection("packages").doc(quotePackage.id).delete();
        toast.success("Package has been deleted");
    } catch (error) {
        toast.error("Something went wrong");
    }
}

export async function addHolidays(holidayArr) {
    const firestore = firebase.firestore();

    // Step 1: Get all existing holidays from Firestore
    const existingHolidaysSnapshot = await firestore.collection("holidays").get();
    const existingHolidays = {};
    existingHolidaysSnapshot.forEach((doc) => {
        existingHolidays[doc.data().id] = doc.id;
    });

    // Step 2: Add or update holidays from holidayArr
    for (const key of Object.keys(holidayArr)) {
        const holidayId = holidayArr[key].id;

        if (existingHolidays[holidayId]) {
            // Update existing holiday
            await firestore.collection("holidays").doc(existingHolidays[holidayId]).update(holidayArr[key]);
            // Remove from the existingHolidays object so that it doesn't get deleted later
            delete existingHolidays[holidayId];
        } else {
            // Add new holiday
            await firestore.collection("holidays").add(holidayArr[key]);
        }
    }

    // Step 3: Remove holidays that are no longer in holidayArr
    for (const idToDelete of Object.values(existingHolidays)) {
        await firestore.collection("holidays").doc(idToDelete).delete();
    }

    return true;
}

export async function updateQuotePackage(values, quotePackage) {
    const firestore = firebase.firestore();

    let currQuoteDetailData = firestore.collection("quotes").doc(quotePackage.quoteId).collection("packages").doc(quotePackage.id);
    let currQuoteDetail = [];
    await currQuoteDetailData.get().then((res) => currQuoteDetail.push(res.data()));

    // let prevDiscount = "";

    // if (currQuoteDetail[0].discount === undefined) {
    //     prevDiscount = 0;
    // } else {
    //     prevDiscount = currQuoteDetail[0].discount.replace("R", "");
    // }

    // let prevTotal = values.price;

    // let totalPrice = parseInt(prevTotal) + parseInt(prevDiscount);

    // let discount = "";

    // if (values.discount === undefined) {
    //     discount = 0;
    // } else {
    //     discount = values.discount.replace("R", "");
    // }

    // let newDiscount = parseInt(discount);
    // let newPrice = totalPrice - newDiscount;

    // values.price = newPrice;

    try {
        await firestore.collection("quotes").doc(quotePackage.quoteId).collection("packages").doc(quotePackage.id).set(values, { merge: true });
        toast.success("Package has been updated");
    } catch (error) {
        console.log(error.message);
        toast.error("Something went wrong");
    }
}

export const createFlight = (quotePackage) => {
    return async (dispatch, getState, { getFirestore }) => {
        const firestore = getFirestore();

        let flight = {};
        flight.createDate = new Date(Date.now());
        flight.quoteId = quotePackage.quoteId;
        flight.packageId = quotePackage.id;

        try {
            let flightNew = await firestore.add(`flights`, flight);
            await firestore.set(`flights/${flightNew.id}`, { flightId: flightNew.id }, { merge: true });

            toastr.success("Success!", "Flight Search has been requested");
        } catch (error) {
            console.log(error.message);
            toastr.error("Oops", "Something went wrong");
        }
    };
};

export const uploadImage =
    (file, fileName, quotePackage) =>
    async (dispatch, getState, { getFirebase, getFirestore }) => {
        const imageName = cuid();
        const firebase = getFirebase();
        const firestore = getFirestore();

        const path = `${quotePackage.quoteId}/images`;
        const options = {
            name: imageName,
        };

        try {
            dispatch(asyncActionStart());

            // upload the file to firebase storage
            let downloadURL = await firebase.uploadFile(path, file, null, options).then((ret) => ret.uploadTaskSnapshot.ref.getDownloadURL());

            await firestore.add(
                {
                    collection: "quotes",
                    doc: quotePackage.quoteId,
                    subcollections: [
                        {
                            collection: "packages",
                            doc: quotePackage.id,
                            subcollections: [
                                {
                                    collection: "images",
                                },
                            ],
                        },
                    ],
                },
                {
                    creadeDate: new Date(Date.now()),
                    downloadURL: downloadURL,
                    fileName: fileName,
                }
            );

            await firestore.set(
                {
                    collection: "quotes",
                    doc: quotePackage.quoteId,
                    subcollections: [{ collection: "packages", doc: quotePackage.id }],
                },
                {
                    photo: downloadURL,
                },
                { merge: true }
            );

            dispatch(asyncActionFinish());

            toastr.success("Success!", "Image uploaded!");
        } catch (e) {
            console.log(e);
            dispatch(asyncActionError());
            throw new Error("Problem uploading image");
        }
    };

export const setPackagePhoto =
    (img, quotePackage) =>
    async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();

        try {
            dispatch(asyncActionStart());

            await firestore.set(
                {
                    collection: "quotes",
                    doc: quotePackage.quoteId,
                    subcollections: [{ collection: "packages", doc: quotePackage.id }],
                },
                {
                    photo: img.downloadURL,
                },
                { merge: true }
            );

            dispatch(asyncActionFinish());

            toastr.success("Success!", "Image set!");
        } catch (e) {
            console.log(e);
            dispatch(asyncActionError());
            throw new Error("Problem uploading image");
        }
    };

export async function createPackageTemplate(values, quotePackage, profile) {
    const firestore = firebase.firestore();

    // try {
    //Consultant details
    quotePackage.displayName = profile.displayName;
    quotePackage.userUid = profile.id;

    //Remove things unique to a quote
    if (quotePackage.flights_import) {
        delete quotePackage.flights_import;
    }

    let old_cur_pack_id = quotePackage.id;
    delete quotePackage.id;

    quotePackage.template_name = values.template_name;

    let newTemplate = await firestore.collection("package_templates").add(quotePackage);

    //Flights
    let flightsRef = firestore.collection("quotes").doc(quotePackage.quoteId).collection("packages").doc(old_cur_pack_id).collection("flights");
    let flightsSnap = await flightsRef.get();

    for (let i = 0; i < flightsSnap.docs.length; i++) {
        let cur_flight = flightsSnap.docs[i].data();
        delete cur_flight.id;

        cur_flight.packageId = newTemplate.id;
        cur_flight.quoteId = quotePackage.quoteId;

        await firestore.collection("package_templates").doc(newTemplate.id).collection("flights").add(cur_flight);
    }

    toast.success("Package Template has been created");
    // } catch (error) {
    //     console.log(error);
    //     toast.error("Something went wrong");
    // }
}

export async function createRevision(quote) {
    const firestore = firebase.firestore();

    try {
        let old_quote_id = quote.id;
        delete quote.id;

        //Update revision to use current date
        delete quote.createDate;
        quote.createDate = new Date(Date.now());

        if (!quote.logo_division || (quote.logo_division !== "Leisure" && quote.logo_division !== "Non-Leisure")) {
            quote.logo_division = "Leisure";
        }

        let quotesRef = firestore.collection("quotes");
        let quoteQuery = quotesRef.where("leadId", "==", quote.leadId).where("quote_nr", "==", quote.quote_nr);
        let quoteQuerySnap = await quoteQuery.get();

        let max = 0;
        for (let i = 0; i < quoteQuerySnap.docs.length; i++) {
            if (quoteQuerySnap.docs[i].data().quote_rev > max) {
                max = quoteQuerySnap.docs[i].data().quote_rev;
            }
        }

        quote.quote_rev = max + 1;

        let quote_added = await firestore.collection("quotes").add(quote);

        // console.log(old_quote_id)

        let packagesRef = firestore.collection("quotes").doc(old_quote_id).collection("packages");
        let packagesSnap = await packagesRef.get();

        for (let i = 0; i < packagesSnap.docs.length; i++) {
            let cur_pack = packagesSnap.docs[i].data();
            delete cur_pack.id;

            let old_cur_pack_id = packagesSnap.docs[i].id;
            cur_pack.quoteId = quote_added.id;

            let cur_added_pack = await firestore.collection("quotes").doc(quote_added.id).collection("packages").add(cur_pack);

            let imagesRef = firestore.collection("quotes").doc(old_quote_id).collection("packages").doc(old_cur_pack_id).collection("images");
            let imagesSnap = await imagesRef.get();

            for (let i = 0; i < imagesSnap.docs.length; i++) {
                let cur_img = imagesSnap.docs[i].data();
                delete cur_img.id;

                await firestore.collection("quotes").doc(quote_added.id).collection("packages").doc(cur_added_pack.id).collection("images").add(cur_img);
            }

            //Flights
            let flightsRef = firestore.collection("quotes").doc(old_quote_id).collection("packages").doc(old_cur_pack_id).collection("flights");
            let flightsSnap = await flightsRef.get();

            for (let i = 0; i < flightsSnap.docs.length; i++) {
                let cur_flight = flightsSnap.docs[i].data();
                delete cur_flight.id;

                cur_flight.packageId = cur_added_pack.id;
                cur_flight.quoteId = quote_added.id;

                await firestore.collection("quotes").doc(quote_added.id).collection("packages").doc(cur_added_pack.id).collection("flights").add(cur_flight);
            }
        }

        toast.success("Quote Revision has been created");

        return quote_added.id;
    } catch (error) {
        console.log(error.message);
        toast.error("Something went wrong");
    }
}

export async function clonePackage(values, quote) {
    const firestore = firebase.firestore();

    try {
        let packagesRef = firestore.collection("quotes").doc(quote.id).collection("packages").doc(values.package_id);
        let packagesSnap = await packagesRef.get();

        let newPackage = packagesSnap.data();
        newPackage.createDate = new Date(Date.now());
        newPackage.package_name = values.new_package_name;
        delete newPackage.id;

        let cur_added_pack = await firestore.collection("quotes").doc(quote.id).collection("packages").add(newPackage);

        let flightsRef = firestore.collection("quotes").doc(quote.id).collection("packages").doc(values.package_id).collection("flights");
        let flightsSnap = await flightsRef.get();

        for (let i = 0; i < flightsSnap.docs.length; i++) {
            let cur_flight = flightsSnap.docs[i].data();

            cur_flight.packageId = cur_added_pack.id;

            await firestore.collection("quotes").doc(quote.id).collection("packages").doc(cur_added_pack.id).collection("flights").add(cur_flight);
        }

        toast.success("Quote Package cloned");
    } catch (error) {
        console.log(error.message);
        toast.error("Something went wrong");
    }
}

export async function clonePackageFromTemplate(data, quote) {
    const firestore = firebase.firestore();

    try {
        let packagesRef = firestore.collection("package_templates").doc(data.package_template_id);
        let packagesSnap = await packagesRef.get();

        let newPackage = packagesSnap.data();

        newPackage.createDate = new Date(Date.now());
        newPackage.package_name = data.new_package_name;
        newPackage.quoteId = quote.id;

        delete newPackage.id;
        delete newPackage.template_name;

        //TODO add package id? same to above?

        let cur_added_pack = await firestore.collection("quotes").doc(quote.id).collection("packages").add(newPackage);

        //Flights
        let flightsRef = firestore.collection("package_templates").doc(data.package_template_id).collection("flights");
        let flightsSnap = await flightsRef.get();

        for (let i = 0; i < flightsSnap.docs.length; i++) {
            let cur_flight = flightsSnap.docs[i].data();
            delete cur_flight.id;

            cur_flight.packageId = cur_added_pack.id;
            cur_flight.quoteId = quote.id;
            cur_flight.createDate = new Date(Date.now());

            await firestore.collection("quotes").doc(quote.id).collection("packages").doc(cur_added_pack.id).collection("flights").add(cur_flight);
        }

        toast.success("Quote Package cloned");
    } catch (error) {
        console.log(error.message);
        toast.error("Something went wrong");
    }
}

export async function deletePackageTemplate(values) {
    const firestore = firebase.firestore();

    try {
        let flightsRef = firestore.collection("package_templates").doc(values.id).collection("flights");
        let flightsSnap = await flightsRef.get();

        for (let i = 0; i < flightsSnap.docs.length; i++) {
            let cur_flight = flightsSnap.docs[i].id;

            await firestore.collection("package_templates").doc(values.id).collection("flights").doc(cur_flight).delete();
        }

        await firestore.collection("package_templates").doc(values.id).delete();

        toast.success("Package Template deleted");
    } catch (error) {
        console.log(error.message);
        toast.error("Something went wrong");
    }
}

export async function updateQuote(values, quote) {
    const firestore = firebase.firestore();

    try {
        if (values.uploadFile) {
            const file = values.uploadFile;
            delete values.uploadFile;

            const path = `quotes/${quote.id}`;
            const options = {
                name: cuid() + "-" + file.name,
            };

            let uploadedFile = await firebase.uploadFile(path, file, null, options).then((ret) => ret.uploadTaskSnapshot.ref.getDownloadURL());

            values.fileName = file.name;
            values.fileUrl = uploadedFile;
            values.documentNameRand = options.name;
        }
        await firestore.collection("quotes").doc(quote.id).update(values);

        toast.success("Quote has been updated");
    } catch (error) {
        console.log(error.message);
        toast.error("Something went wrong");
    }
}

export async function addManualFlight(values) {
    const firestore = firebase.firestore();

    // console.log("in", values);

    await firestore.collection("quotes").doc(values.quoteId).collection("packages").doc(values.packageId).collection("flights").add(values);

    toast.success("Flight has been added");
}

export async function saveManualFlight(values) {
    const firestore = firebase.firestore();

    let flightId = values.id;
    delete values.id;

    if (values.departDateTime !== undefined) {
        values.departDateTime = new Date(values.departDateTime);
    }

    if (values.arriveDateTime !== undefined) {
        values.arriveDateTime = new Date(values.arriveDateTime);
    }

    try {
        await firestore.collection("quotes").doc(values.quoteId).collection("packages").doc(values.packageId).collection("flights").doc(flightId).update(values);

        toast.success("Flight has been saved");
    } catch (error) {
        console.log(error.message);
        toast.error("Something went wrong");
    }
}

export async function deleteFlight(flight) {
    const firestore = firebase.firestore();

    try {
        await firestore.collection("quotes").doc(flight.quoteId).collection("packages").doc(flight.packageId).collection("flights").doc(flight.id).delete();

        toast.success("Flight has been deleted");
    } catch (error) {
        toast.error("Something went wrong");
    }
}

export async function deleteFlightTravelport(flightId) {
    const firestore = firebase.firestore();

    try {
        //Get flight result data
        let flightsRef = firestore.collection("flights").doc(flightId).collection("results");
        let flightsSnap = await flightsRef.get();

        //If we find result data loop through and delete
        if (flightsSnap.docs && flightsSnap.docs.length > 0) {
            for (let i = 0; i < flightsSnap.docs.length; i++) {
                let resultId = flightsSnap.docs[i].id;

                await firestore.collection("flights").doc(flightId).collection("results").doc(resultId).delete();
            }
        }

        //Then delete flight
        await firestore.collection("flights").doc(flightId).delete();

        toast.success("Flight has been deleted");
    } catch (error) {
        console.log(error);
        toast.error("Something went wrong");
    }
}

export async function loadHoliday(holidayData) {
    const firestore = firebase.firestore();

    try {
        await firestore.collection("quotes").doc(holidayData.quoteId).collection("packages").add(holidayData);
        toast.success("Website Holiday has been loaded");
    } catch (error) {
        console.log(error.message);
        toast.error("Something went wrong");
    }
}

export async function createFlightSearch(values) {
    const firestore = firebase.firestore();

    try {
        values.status = "Pending";
        values.createdDate = new Date(Date.now());
        await firestore.collection("flights").add(values);

        toast.success("Flight Search has been started");
    } catch (error) {
        console.log(error.message);
        toast.error("Something went wrong");
    }
}

export const resetSearch = (flightId) => {
    return async (dispatch, getState, { getFirestore }) => {
        const firestore = getFirestore();

        try {
            //Get flight result data
            let flightsRef = firestore.collection("flights").doc(flightId).collection("results");
            let flightsSnap = await flightsRef.get();

            //If we find result data loop through and delete
            if (flightsSnap.docs && flightsSnap.docs.length > 0) {
                for (let i = 0; i < flightsSnap.docs.length; i++) {
                    let resultId = flightsSnap.docs[i].id;

                    await firestore.delete(`flights/${flightId}/results/${resultId}`);
                }
            }

            //Then update flight status
            await firestore.update(`flights/${flightId}`, { status: "Searching" });

            toastr.success("Success!", "Flight refresh has started");
        } catch (error) {
            console.log(error);
            toastr.error("Oops", "Something went wrong");
        }
    };
};

export async function getPackageFlights(quote_id) {
    const firestore = firebase.firestore();

    try {
        let packagesSnap = await firestore.collection("quotes").doc(quote_id).collection("packages").get();

        let flights = [];

        if (packagesSnap.docs && packagesSnap.docs.length > 0) {
            for (let i = 0; i < packagesSnap.docs.length; i++) {
                let flightsSnap = await firestore.collection("quotes").doc(quote_id).collection("packages").doc(packagesSnap.docs[i].id).collection("flights").get();

                if (flightsSnap.docs && flightsSnap.docs.length > 0) {
                    for (let x = 0; x < flightsSnap.docs.length; x++) {
                        let flight = flightsSnap.docs[x].data();
                        flights.push(flight);
                    }
                }
            }
        }

        return flights;
    } catch (error) {
        console.log(error);
        toast.error("Something went wrong");
    }
}

export async function getPackageHTMLFlights(quote_id) {
    const firestore = firebase.firestore();

    try {
        let packagesSnap = await firestore.collection("quotes").doc(quote_id).collection("packages").get();

        // console.log(packagesSnap.docs);

        let flights = [];

        if (packagesSnap.docs && packagesSnap.docs.length > 0) {
            for (let i = 0; i < packagesSnap.docs.length; i++) {
                if (packagesSnap.docs[i].data().flight_html !== undefined) {
                    flights.push(packagesSnap.docs[i].data().flight_html);
                }
            }
        }

        return flights;
    } catch (error) {
        console.log(error);
        toast.error("Something went wrong");
    }
}

export async function deleteHeaderImage(quote) {
    const firestore = firebase.firestore();

    //Try to remove file
    try {
        await firebase
            .deleteFile(`quotes/${quote.id}/${quote.documentNameRand}`)
            .then(function () {
                console.log("file del went ok");
            })
            .catch(function (error) {
                console.log(error);
            });
    } catch (e) {
        console.log(e);
    }

    try {
        let quoteRef = firestore.collection("quotes").doc(quote.id);

        await quoteRef.update({
            fileName: firebase.firestore.FieldValue.delete(),
            documentNameRand: firebase.firestore.FieldValue.delete(),
            fileUrl: firebase.firestore.FieldValue.delete(),
        });

        console.log("image removed");
    } catch (error) {
        console.log(error.message);
        toast.error("Something went wrong");
    }
}

export async function getQuotes(quoteId) {
    const firestore = firebase.firestore();
    let quoteIdData = quoteId ? quoteId : "None";
    return firestore.collection("quotes").doc(quoteIdData).get();
}
export async function userConsultants() {
    const firestore = firebase.firestore();
    return firestore.collection("users").where("isConsultant", "==", true).get();
}
export async function packageTemplates(userId) {
    const firestore = firebase.firestore();
    return firestore.collection("package_templates").where("userUid", "==", userId).get();
}
export async function getFlights(quoteId, id) {
    const firestore = firebase.firestore();
    return firestore.collection("quotes").doc(quoteId).collection("packages").doc(id).collection("flights").get();
}
export async function getAllFlights(packageId) {
    const firestore = firebase.firestore();
    let id = packageId ? packageId : "None";
    return firestore.collection("flights").where("packageId", "==", id).get();
}
export async function getQuotePackages(quoteId) {
    const firestore = firebase.firestore();
    return firestore.collection("quotes").doc(quoteId).collection("packages").get();
}
export async function getQuoteTemplates() {
    const firestore = firebase.firestore();
    return firestore.collection("quote_templates").get();
}
export async function getUsers(userId) {
    const firestore = firebase.firestore();
    return firestore.collection("users").doc(userId).get();
}
export async function getLeadsData(leadId) {
    const firestore = firebase.firestore();
    return firestore.collection("leads").doc(leadId).get();
}
export const getAuthUser = () => {
    const auth = firebase.auth().currentUser;
    let userData = auth.uid;
    const firestore = firebase.firestore();
    return firestore.collection("users").doc(userData).get();
};
export async function getFlightsResults(id, sort) {
    const firestore = firebase.firestore();
    return firestore.collection("flights").doc(id).collection("results").orderBy("totalPrice", sort).get();
}

export async function saveSortOrderOthers(values, type, quote) {
    const firestore = firebase.firestore();

    try {
        let bookItinDocRef = firestore.collection("quotes").doc(quote.quoteId).collection("packages").doc(quote.id);

        // console.log(type);

        if (type == "price_breakdown") {
            bookItinDocRef.update({ price_breakdown: firebase.firestore.FieldValue.delete() });
        } else if (type == "excludes") {
            bookItinDocRef.update({ excludes: firebase.firestore.FieldValue.delete() });
        } else if (type == "includes") {
            bookItinDocRef.update({ includes: firebase.firestore.FieldValue.delete() });
        }

        let batch = firestore.batch();

        let sort_order = 0;
        Object.keys(values).forEach(function (key) {
            sort_order++;
            values[key].sort_order = sort_order;

            let bookItinDocRef = firestore.collection("quotes").doc(quote.quoteId).collection("packages").doc(quote.id);
            let updatedData = {};

            if (type == "price_breakdown") {
                updatedData = { price_breakdown: firebase.firestore.FieldValue.arrayUnion(values[key]) };
            } else if (type == "excludes") {
                updatedData = { excludes: firebase.firestore.FieldValue.arrayUnion(values[key]) };
            } else if (type == "includes") {
                updatedData = { includes: firebase.firestore.FieldValue.arrayUnion(values[key]) };
            }

            batch.update(bookItinDocRef, updatedData);
        });

        await batch.commit();

        await firestore.collection("quotes").doc(quote.quoteId).update({ updatedAt: new Date() });
    } catch (error) {
        console.log(error.message);
        toast.error("Something went wrong");
    }
}

export async function getQuotePackageInfo(quoteId, PackageId) {
    const firestore = firebase.firestore();
    return firestore.collection("quote_templates").get();
}
